import React from "react";
import { Row, Col } from "antd";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ContatoComponent from "../components/ContatoComponent";

const jsonLdData = {
	'@context': 'http://schema.org',
	'@type': 'Product',
	name: "Unico Feculario e Farinheira",
	image: "https://www.unimake.com.br/static/media/unico-erp.8b1d04b3.webp",
	description: "Controle de seu estoque até seu faturamento com o software integrado de gestão empresarial focado em seu negócio.",
	brand: {
		'@type': 'Brand',
		name: "Unimake",
	},
	"review": {
		"@type": "Review",
		"reviewRating": {
		  "@type": "Rating",
		  "ratingValue": "5"
		},
		"author": {
		  "@type": "Person",
		  "name": "Unimake"
		}
	}
};

class UnicoFeculariaPage extends React.Component<any, any> {
	state = {
		logoFecularia: require("../../../assets/img/products/webp/topo/unico-erp.webp"),
	};

	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Conheça o Único ERP – Fecularias | Unimake Software</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/produtos/unico-fecularia`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="Selecionamos as principais informações sobre o Único ERP, Fecularias, um software integrado de gestão para segmentos de fecularias e farinheiras." />
				</Helmet>

				<div className="topo-border-rounded color-materiais-construcao">
					<Row align="middle" justify="space-around">
						<Col
							lg={{ order: 1, span: 10, offset: 2 }}
							xs={{ order: 2, span: 20 }}
							xxl={{ order: 1, span: 7, offset: 5 }}>
							<h1 className="hidden-xs hidden-sm hidden-md">Único ERP</h1>
							<h2>Fecularias e Farinheiras</h2>

							<p className="subtitulo">
								O Software de gestão líder do mercado que vai dar um <br />
								salto de desempenho na gestão da sua agroindústria <br />
								de farinha e fécula.
							</p>
						</Col>
						<Col lg={{ order: 2, span: 10 }} xs={{ order: 1 }}>
							<img className="topo-produto" src={this.state.logoFecularia} alt="Unimake Unico Feculária"></img>
						</Col>
					</Row>
				</div>

				<section>
					<div className="unimake-container">
						<div className="content-area-product">
							<div className="pre-description-center">
								<br />
								<p style={{ textAlign: "justify" }}>
									O Único ERP é o mais sofisticado sistema integrado de gestão para fecularias e
									farinheiras que processam raiz de mandioca no país.
								</p>
								<p style={{ textAlign: "justify" }}>
									A Unimake Software é líder no mercado de ERP para processamento de mandioca ajudando
									as maiores e melhores empresas desse setor a obterem o máximo em desempenho e
									lucratividade.
								</p>
								<p style={{ textAlign: "justify" }}>
									Conheça as principais funcionalidades do Único que farão sua empresa dar um salto em
									eficiência:
								</p>
							</div>

							<div>
								<LazyLoad>
									<img
										className="imageMiddle-fec"
										src={require("../../../assets/img/products/fecularia/fecularias.jpg")}
										alt="Unimake Unico Feculária"
									/>
								</LazyLoad>
							</div>

							<h2 className="headerFeaturesa-fec">
								Funcionalidades especiais que tornam o Único ERP a ferramenta essencial da sua empresa:
							</h2>

							<p className="FeaturesAboveImage-fec">
								<span
									style={{ color: "white", backgroundColor: "orange" }}
									className="featureTitleIndicator">
									1
								</span>
								<span className="featureTitle-fec">PRODUTORES</span>
								<br />
								<p className="featuresContent-fec">
									Controle e planejamento da colheita / Controle de fila de caminhões. <br />
								</p>
								<span
									style={{ color: "white", backgroundColor: "grey" }}
									className="featureTitleIndicator">
									2
								</span>
								<span className="featureTitle-fec">BALANÇA</span>
								<br />
								<p className="featuresContent-fec">
									Integração com a balança / Controle de previsão de rendimento. <br />
								</p>
								<span
									style={{ color: "white", backgroundColor: "green" }}
									className="featureTitleIndicator">
									3
								</span>
								<span className="featureTitle-fec">FÁBRICA</span>
								<br />
								<p className="featuresContent-fec">
									Ordem de produção / Planejamento de produção / Controle de lotes / Rendimento da
									indústria. <br />
								</p>
								<span
									style={{ color: "white", backgroundColor: "blue" }}
									className="featureTitleIndicator">
									4
								</span>
								<span className="featureTitle-fec">LABORATÓRIO</span>
								<br />
								<p className="featuresContent-fec">
									Controle de análises laboratoriais / Impressão de laudos / Pesquisa das análises
									laboratoriais <br />
								</p>
								<span
									style={{ color: "white", backgroundColor: "purple" }}
									className="featureTitleIndicator">
									5
								</span>
								<span className="featureTitle-fec">ADMINISTRAÇÃO</span>
								<br />
								<p className="featuresContent-fec">
									Nota Fiscal Eletrônica / Uma única NF-e para várias pesagens / Controle dos custos
									de produção / Controle de adiantamentos e pagamentos ao produtor / Envio de arquivos
									de cobrança / Baixa automatizada de boletos bancários / Fluxo de caixa / Controle de
									adiantamento de viagens para motoristas / Integração com Serasa / Entrada automática
									ded NF-e via XML / Pedidos de venda / Análise de lucratividade / Romaneios de cargas
									para logística de entrega / Contabilidade, Escrita Fiscal e SPED totalmente
									integrados. <br />
								</p>
								<span
									style={{ color: "white", backgroundColor: "red" }}
									className="featureTitleIndicator">
									6
								</span>
								<span className="featureTitle-fec">ALMOXARIFADO</span>
								<br />
								<p className="featuresContent-fec">
									Controle total do estoque de matérias-primas e insumos / Gestão de Compras /
									Controle de estoques <br />
								</p>
							</p>

							<span style={{ fontSize: 21, fontWeight: "bolder" }}>
								* Converse com nossa equipe para turbinar a gestão da sua agroindústria acrescentando o
								módulo de <Link to="/produtos/bi">Business Intelligence (BI)</Link> da Unimake. <br />
							</span>

							<div>{/* Depoimentos */}</div>

							<div className="footerImageShape">
								<div className="contactBox"></div>
							</div>
						</div>
					</div>
				</section>

				<div style={{ paddingTop: 5, paddingBottom: 20 }}>
					<h3 style={{ fontSize: 38 }} className="headerFeaturesa-fec">
						Conheça todos os módulos:
					</h3>

					<div style={{ backgroundColor: "white", marginLeft: 30, marginRight: 30 }}>
						<LazyLoad>
							<img
								className="imagemodulos-fec"
								src={require("../../../assets/img/products/fecularia/modulos.png")}
								alt="Unimake Unico Feculária"
							/>
						</LazyLoad>
					</div>
				</div>

				<div className="autoPecasBanner color-ferragens">
					<div className="autoPecasBanner-container">
						<Row align="middle" justify="space-between">
							<Col md={11} sm={11}>
								<LazyLoad>
									<img
										className="imageLeft-fec"
										src={require("../../../assets/img/products/fecularia/middle.jpg")}
										alt="Unimake Unico Feculária"
									/>
								</LazyLoad>
							</Col>

							<Col sm={12} md={12}>
								<p className="imageLeft-Text-fec">
									<span style={{ textTransform: "uppercase" }}>O agronegócio evoluiu!</span>
									<br />
									Evolua você também com o que há de melhor em tecnologia de gestão para sua
									fecularia.
								</p>
							</Col>

							<Col sm={1}></Col>
						</Row>
					</div>
				</div>

				<div style={{ paddingLeft: 10, paddingRight: 10 }}>
					<p style={{ fontSize: 18, fontWeight: 250, alignItems: "center", color: "#545151" }}>
						Se interessou pelo Único ERP para Fecularias e Farinheiras, mas necessita de uma visualização de todos os atributos e funções citadas acima? Sem problemas,
						<a href="https://inside.unimake.com.br/unicoagro" target="_blank" style={{ color: "blue" }}> solicite uma demonstração </a> 
						com a equipe da <b>Unimake Software</b> e tenha a certeza que esse software era o que faltava para organizar e modernizar seu negócio.
					</p>
				</div>

				{/* <div>
					<Row>
						<Col sm={24} md={24}>
							<h1 style={{ fontSize: 32, fontWeight: 300, textAlign: "center" }}>
								O que os melhores gestores do setor estão falando sobre nós:
							</h1>

							<div style={{ height: 200 }}>
								<UmkSliderContainer />
							</div>
						</Col>
					</Row>
				</div> */}

				<ContatoComponent />

				<script type="application/ld+json">
					{JSON.stringify(jsonLdData, null, 2)}
				</script>
			</>
		);
	}
}
export default UnicoFeculariaPage;
