import React from "react";
import { Row, Col } from "antd";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ContatoComponent from "../components/ContatoComponent";

const jsonLdData = {
	'@context': 'http://schema.org',
	'@type': 'Product',
	name: "Unico Lojas de Ferragens",
	image: "https://www.unimake.com.br/static/media/unico-ferragens.13a6331c.webp",
	description: "Controle de seu estoque até seu faturamento com o software integrado de gestão empresarial focado em seu negócio.",
	brand: {
		'@type': 'Brand',
		name: "Unimake",
	},
	"review": {
		"@type": "Review",
		"reviewRating": {
		  "@type": "Rating",
		  "ratingValue": "5"
		},
		"author": {
		  "@type": "Person",
		  "name": "Unimake"
		}
	}
};

class UnicoFerragensPage extends React.Component<any, any> {
	state = {
		logoFerragens: require("../../../assets/img/products/webp/topo/unico-ferragens.webp"),
	};

	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Conheça o Único ERP – Ferragens | Unimake Software</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/produtos/unico-ferragens`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="O Único ERP possui funcionalidades especiais para o segmento de varejo e atacado de ferragens, com diferenciais que fazem diferença! Veja mais." />
				</Helmet>

				<div className="topo-border-rounded color-ferragens">
					<Row align="middle" justify="space-around">
						<Col
							lg={{ order: 1, span: 10, offset: 2 }}
							xs={{ order: 2, span: 20 }}
							xxl={{ order: 1, span: 7, offset: 5 }}>
							<h1 className="hidden-xs hidden-sm hidden-md">Único ERP</h1>
							<h2 className="hidden-xs hidden-sm hidden-md">Lojas de ferragens</h2>

							<p className="subtitulo">O toque de gestão para sua empresa crescer continuamente</p>
						</Col>
						<Col lg={{ order: 2, span: 10 }} xs={{ order: 1 }}>
							<img className="topo-produto" src={this.state.logoFerragens} alt="Unimake Unico Ferragens"></img>
						</Col>
					</Row>
				</div>

				<section>
					<div className="unimake-container">
						<div className="content-area-product">
							<div className="pre-description-center">
								<p style={{ fontSize: 20, fontWeight: 350 }}>Ter o controle por completo de sua loja ou indústria de ferragens faz toda diferença para que seu empreendimento possa se destacar no segmento ferragista, e mais do que isso, para que ele possa agregar valor ao seu cliente. Deste modo, conhecer o <a href="https://www.unimake.com.br/produtos/unico-ferragens" target="_blank" style={{ color: "blue" }}>Único ERP para Ferragens</a>,
									um <b>software de gestão integrada do seu negócio</b> desde a produção e estoque até financeiro, fiscal e vendas é o que falta para satisfazer clientes enquanto gera lucro. Confira mais passeando pela página!</p>
								<h2 style={{ fontSize: 30, fontWeight: 600 }}>Tenha o controle completo sobre seu negócio - os benefícios são muitos!</h2>
							</div>

							<br />

							<div className="featuresBox">
								<p style={{ fontSize: 20, fontWeight: 300 }}>O Único Software ERP para Ferragens é, de fato, favorável para que a gestão negocial de sua ferragista seja singular e, por isso, os benefícios são muitos.</p>
								<p style={{ fontSize: 20, fontWeight: 300 }}>Ao optar pela integração de todo o seu negócio por meio de um software feito a partir da mais alta e confiável técnicas da tecnologia da informação e dotado de inteligência artificial será possível notar:</p>
								<ul>
									<li>redução real de custos;</li>
									<li>diminuição do risco de falhas e desperdícios;</li>
									<li>rotinas completas, pela integração de setores;</li>
									<li>controle da atividade empresarial por de relatórios e fechamentos com dados precisos;</li>
									<li>maior controle fiscal de sua atuação empresarial – e para fomentá-lo, que tal conhecer também o <a href="https://unimake.com.br/uninfe/" target="_blank" style={{ color: "#00D139"}}>UniNFe </a>e o <a href="https://www.unimake.com.br/produtos/unidanfe" target="_blank" style={{ color: "blue"}}>UniDANFE</a>?</li>
								</ul>

								<h2 style={{ fontSize: 32, fontWeight: 500 }}>Funcionalidades do Único ERP para Ferragens</h2>
								<p style={{ fontSize: 20, fontWeight: 300 }}>É importante citar que as funcionalidades de nosso software de gestão para o varejo e atacado do segmento de ferragens possui diversos comandos que farão com que o seu negócio se destaque na multidão de lojas e indústrias de ferragens.</p>
								<p style={{ fontSize: 20, fontWeight: 300 }}>Estas são algumas de suas particularidades:</p>
								<ul>
									<li><b>Cadastro de códigos alternativos:</b> o que possibilita que vários códigos de fabricantes e fornecedores possam ser vinculados um mesmo produto, facilitando sua localização;</li>
									<li><b>Simulação do preço de venda (Alt+7) em tempo real</b>: o que possibilita a visualização da lucratividade dos itens do pedido e verificação de qual faixa de preço – negociação – pode praticar;</li>
									<li><b>Venda rápida</b>: os itens são lançados no sistema em acordo com as requisições do cliente – feitas no e-commerce – e informa as condições de pagamento no fechamento;</li>
									<li><b>Controle de comissões</b>:
										<ul>
											<li>1) Por vendedor: parametriza as condições de comissão por funcionário;</li>
											<li>2) Por produto: parametriza condições especiais para um determinado produto;</li>
											<li>3) Por faixa de descontos: parametriza comissão conforme o desconto concedido;</li>
										</ul>
									</li>
									<li><b>Manuseio de produtos por código de barras</b>: o leitor de código de barras (fixo ou pistola) é integrado ao ERP para Ferragens, o que permite melhor organização e controle de entradas e saídas de produtos;</li>
									<li><b>Endereçamento de produtos no estoque</b>: ao fazer a consulta de um produto no software o usuário é informado do corredor e prateleira em que o produto se encontra dentro do depósito;</li>
									<li><b>Controle de estoque mínimo/máximo</b>: controla o estoque mínimo de um produto para pedido enviando aviso (CI) para o gestor de compras; o sistema avisa em caso de excesso de estoque de um determinado produto;</li>
									<li><b>Análise de crédito e bloqueio automático</b>: o software faz análise constante do histórico de crédito dos clientes liberando ou bloqueando crédito conforme parâmetros determinados pelo financeiro;</li>
									<li><b>Controle de cheques pré-datados</b>: controla origem e destino dos cheques recebidos permitindo vincular cheques de terceiros ao cliente pagador e prazos de depósito;</li>
									<li><b>Controle de acesso de usuário</b>: permite parametrizar as permissões que cada usuário – no caso, os colaboradores da empresa que adquire o software – terá dentro do software;</li>
									<li><b>Controle de acesso de grupos usuários</b>: parametriza as permissões gerais para um grupo de usuários dentro do software;</li>
									<li><b>Controle total de caixa e contas-correntes</b>:
										<ul>
											<li>1) Geração de ficha diária para conferência de caixa;</li>
											<li>2) Conciliação de contas bancárias;</li>
											<li>3) Controle de adiantamentos e pagamento a colaboradores;</li>
										</ul>
									</li>
									<li><b>Emissão automática de boletos bancários</b>;</li>
									<li><b>Baixa automáticas dos boletos gerados e recebidos pela instituição financeira</b>: integração entre o software e bancos via troca de arquivos digitais de remessa e retorno;</li>
									<li><b>Agrupamento de documentos</b>: agrupa várias vendas num só documento onde podem ser definidas as condições de pagamento com o cliente;</li>
									<li><b>Controle de venda/entrega futura</b>: permite fechar uma venda com vários itens e liberá-los em várias entregas controlando o saldo disponível;</li>
									<li><b>Relatórios de análise e lucratividade</b>: o Único ERP para Ferragens conta com vários relatórios que permitem uma ampla análise das operações tais como: estoques, contas a pagar e receber, lucratividade, comissões, extrato de movimentação por cliente e/ou vendedor, curva ABC entre outros.</li>
								</ul>
								<p style={{ fontSize: 20, fontWeight: 300 }}>Se interessou pelo Único ERP para Ferragens, mas necessita de uma visualização de todos os atributos e funções citadas acima? Sem problemas, 
								<a href="https://inside.unimake.com.br/unicoerp-casadeferragens" target="_blank" style={{ color: "blue"}}> solicite uma demonstração</a> com a equipe da <b>Unimake Software</b> e tenha a certeza que esse software era o que faltava para organizar e modernizar seu negócio.</p>
							</div>

							<br />

							<div className="imageMiddle-unferragens">
								<LazyLoad>
									<img
										style={{
											maxWidth: "70%",
											marginRight: "auto",
											marginLeft: "auto",
											display: "block",
										}}
										src={require("../../../assets/img/products/auto/notebook-danfe.png")}
										alt="Unimake Unico Ferragens"
									/>
								</LazyLoad>
							</div>

							<br />
						</div>
					</div>
				</section>

				<div className="autoPecasBanner">
					<div className="autoPecasBanner-container">
						<Row align="middle">
							<Col xs={24} sm={24} md={{ span: 10, offset: 2 }}>
								<LazyLoad>
									<img
										className="imageLeft-fec"
										src={require("../../../assets/img/products/ferragens/middle.png")}
										alt="Unimake Unico Ferragens"
									/>
								</LazyLoad>
							</Col>

							<Col xs={24} sm={24} md={10} lg={10}>
								<p className="imageLeft-Text-fec" style={{ color: "#238883" }}>
									Exerça a gestão total da sua autopeças com o Único ERP.
								</p>
								<p className="imageLeft-Text-fec" style={{ color: "#238883" }}>
									Sua equipe, seus clientes e seus lucros vão agradecer.
								</p>
							</Col>
							<Col className="hidden-sm hidden-xs" md={2} lg={2}></Col>
						</Row>
					</div>
				</div>

				<div>{/* Depoimentos */}</div>

				{/* <div>
					<Row>
						<Col sm={24} md={24}>
							<h1 style={{ fontSize: 32, fontWeight: 300, textAlign: "center" }}>
								Alguns casos de sucesso
							</h1>

							<div style={{ height: 200 }}>
								<UmkSliderContainer />
							</div>
						</Col>
					</Row>
				</div> */}

				<ContatoComponent />

				<script type="application/ld+json">
					{JSON.stringify(jsonLdData, null, 2)}
				</script>
			</>
		);
	}
}
export default UnicoFerragensPage;
