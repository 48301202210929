import React from "react";
import "./FaleConosco.scss";

class FaleConosco extends React.Component<any, any> {
    render() {
        return (
            <div>
                <div className="fale-conosco-container">
                    <div className="fale-conosco-descricao">
                        <h1>
                            Venha ser Unimaker!
                        </h1>
                        <br />
                        <p>
                            Venha fazer parte de nossa história! Torne-se um cliente, parceiro ou colaborador
                            da Unimake Software e participe desta evolução. Basta acessar o botão abaixo
                            e entrar em contato conosco.
                            <strong><br />Esperamos por você!</strong>
                        </p>
                        
                        <div className="fale-conosco-button">
                            <button>
                                <a target="_blank" rel="noopener noreferrer" href="https://inside.unimake.com.br/fale-conosco">
                                    Quero me tornar um Unimaker!
                                </a>
                            </button>
                        </div>
                    </div>

                    <div className="fale-conosco-image-container">
                        <div className="fale-conosco-image">
                            <img
                                src={require("../../assets/img/home/faleconosco.png")}
                                alt="Fale Conosco"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FaleConosco;