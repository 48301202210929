import React, { Component } from "react";
import { Button, Drawer } from "antd";

import "./UmkMainNav.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import LeftMenu from "../menu/UmkLeftNav";
import RightMenu from "../menu/UmkRightNav";

class UmkMainNav extends Component<any, any> {
	private customCloseIcon = <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 15, marginTop: 7, color: "white"}}/>;

	static defaultProps = {
		Mode: "horizontal",
	};

	constructor(props: any) {
		super(props);
	}

	state = {
		current: "mail",
		visible: false,
		collapsed: true,
	};

	showDrawer = () => {
		this.setState({
			visible: true,
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	toggleCollapsed = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	render() {
		return (
			<>
				<div className="hidden-lg">
					<Button className="barsMenu hidden-lg" type="primary" onClick={this.showDrawer}>
						<FontAwesomeIcon className="bar-menu" icon={faBars} />
					</Button>

					<div className="logo-sm">
						<img className="logo-image" src={process.env.NODE_ENV === 'production' ? require("../../assets/img/unimakelogo.png") : ""} alt="logo unimake" />
					</div>

					<Drawer
						className="drawer-unimake"
						title="Menu"
						placement="left"
						closable={true}
						closeIcon={this.customCloseIcon}
						onClose={this.onClose}
						visible={this.state.visible}>
						<LeftMenu Mode="vertical-left" onCloseDrawer={this.onClose}/>
						<RightMenu Mode="vertical-left" onCloseDrawer={this.onClose}/>
					</Drawer>
				</div>

				<div className="hidden-xs hidden-sm hidden-md">
					<LeftMenu onCloseDrawer={this.onClose}/>
					<RightMenu onCloseDrawer={this.onClose}/>
				</div>
			</>
		);
	}
}
export default UmkMainNav;
