import React from "react";
import { Row, Col, Button } from "antd";
import ContatoComponent from "../components/ContatoComponent";
import {Helmet} from "react-helmet";

const jsonLdData = {
	'@context': 'http://schema.org',
	'@type': 'Product',
	name: "BI",
	image: "https://www.unimake.com.br/static/media/forca-vendas.1dddbb5b.webp",
	description: "Tenha todo o poder e agilidade das vendas na palma da sua mão.",
	brand: {
		'@type': 'Brand',
		name: "Unimake",
	},
	"review": {
		"@type": "Review",
		"reviewRating": {
		  "@type": "Rating",
		  "ratingValue": "5"
		},
		"author": {
		  "@type": "Person",
		  "name": "Unimake"
		}
	}
};

class ForcaVendasPage extends React.Component<any, any> {
	state = {
		logoForcaVendas: require("../../../assets/img/products/webp/topo/forca-vendas.webp"),
	};

	constructor(props: any) {
		super(props);
	}


	render() {
		return (
			<>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Unimake Software - Força de Vendas</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/produtos/forcavendas`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="A Unimake Software é uma empresa especializada no ramo de soluções para ERP, DANFE, Nota Fiscal Eletrônica (NF-e), Nota Fiscal de Serviço Eletrônica (NFS-e), Conhecimento de Transporte Eletrônico (CT-e) com os produtos Unico ERP, UniDANFE, DANFE View, UniNF-e (NF-e), Consult-e (NF-e, CT-e), UniCT-e (CT-e), UniNFS-e (NFS-e), acesse e conheça nossos produtos." />
				</Helmet>

				<div className="topo-border-rounded color-forca-vendas">
					<Row align="middle" justify="space-around">
						<Col
							lg={{ order: 1, span: 10, offset: 2 }}
							xs={{ order: 2, span: 20 }}
							xxl={{ order: 1, span: 7, offset: 5 }}>
							<h1 className="hidden-xs hidden-sm hidden-md">Força de Vendas Mobile</h1>

							<p className="subtitulo hidden-xs hidden-sm hidden-md">
								Agilidade para sua empresa <br />
								Liberdade para sua equipe
							</p>
						</Col>
						<Col lg={{ order: 2, span: 10 }} xs={{ order: 1 }}>
							<img className="topo-produto" src={this.state.logoForcaVendas} alt="Unimake Força de Vendas"></img>
						</Col>
					</Row>
				</div>

				<section>
					<div className="unimake-container">
						<div className="content-area-product">
							<div className="pre-description-center">
								<p style={{ fontWeight: 500 }}>
									O aplicativo de força de vendas mobile Unimake proporciona autonomia e agilidade
									para o seu time externo vender mais
								</p>

								<br />
							</div>

							<div className="featuresBox">
								<Row justify="space-between">
									<Col sm={11} md={7}>
										<b>Mobilidade</b>

										<p style={{ marginTop: 5 }}>
											O vendedor externo pode acessar as informações para novas vendas rapidamente
											pelo celular onde estiver
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Agilidade</b>

										<p style={{ marginTop: 5 }}>
											Consulte as informações e gerar pedidos leva poucos segundos
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Autonomia</b>

										<p style={{ marginTop: 5 }}>
											O vendedor não fica na dependência de de ligações para a empresa, esperas e
											outros entraves para fechar vendas.
										</p>
									</Col>
									<Col sm={11} md={7}>
										<b>Satisfação do time de vendas</b>

										<p style={{ marginTop: 5 }}>
											A ferramenta de força de vendas melhora o desempenho e gera mais satisfação
											para o time de vendas continuar crescendo
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Satisfação dos clientes</b>

										<p style={{ marginTop: 5 }}>
											O vendedor e sua empresa mostam profissionalismo ao atender as demandas do
											cliente no menor tempo possível, evitando desgastes e informações
											desencontradas
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Faciliade de Integração</b>

										<p style={{ marginTop: 5 }}>
											O aplicativo e totalmente integrado ao sistema ERP e ambos trocam
											informações em tempo real.
										</p>
									</Col>
								</Row>
							</div>

							<br />
						</div>
					</div>
				</section>

				<div className="autoPecasBanner color-black-transparent">
					<div className="autoPecasBanner-container">
						<Row justify="space-between">
							<Col md={11} sm={11}>
								<div style={{ marginLeft: 40, marginTop: 50 }}>
									<span style={{ color: "white", fontSize: 28, fontWeight: 400 }}>
										Com o aplicativo de força de vendas mobile Unimake os vendedores externos
										interagem pelo smartphone ou tablet com as informaçoes do sistema de gestão.
									</span>

									<br />
									<br />

									<p style={{ color: "white", fontSize: 28, fontWeight: 400 }}>
										Podem fazer consultas e realizar vendas remotamente de forma rápida e sem
										necessidade de contato com o time interno.
									</p>
								</div>
							</Col>

							<Col md={12} sm={12}>
								<img
									style={{ maxWidth: "40%" }}
									src={require("../../../assets/img/products/iphone12_fv.png")}
									alt="Unimake Força de Vendas"
								/>
								<img
									style={{ maxWidth: "40%" }}
									src={require("../../../assets/img/products/iphone12_fv-02.png")}
									alt="Unimake Força de Vendas"
								/>
							</Col>
						</Row>

						<br />
						<br />

						<Row justify="space-between">
							<Col md={12} sm={12}>
								<img
									style={{ maxWidth: "99%" }}
									src={require("../../../assets/img/products/banner_fv.png")}
									alt="Unimake Força de Vendas"
								/>
							</Col>

							<Col md={11} sm={11}>
								<ul style={{ fontSize: 26, marginTop: "12vh" }}>
									<li>Mais velocidade no fechamento de negócios</li>
									<li>Mais organização entre os times interno e externo</li>
									<li>Mais vendas</li>
									<li>Mais lucros</li>
								</ul>
							</Col>
						</Row>
					</div>
				</div>

				{/* TODO: Criar componente */}
				{/* <div className="successCases-fv">
                <Row>
                    <Col sm={24} md={24}>
                        <h1 style={{ fontSize: 32, fontWeight: 300, textAlign: 'center' }}>
                            Veja os clientes que revolucionaram suas vendas tem a dizer:
                        </h1>


                        <div style={{ height: 200 }}>                            
                        </div>
                    </Col>
                </Row>
            </div> */}

				<div className="footerImageShape">
					<Row justify="space-around">
						<Col
							xs={22}
							style={{
								fontSize: 36,
								fontWeight: 400,
								color: "#000",
								paddingTop: 10,
							}}>
							Fale com nosso time de especialistas
						</Col>
					</Row>

					<Row align="middle" justify="space-around">
						<Col xs={22} sm={22} md={22} lg={10}>
							<img
								style={{ maxWidth: "20vw", marginLeft: "15vw", marginTop: "5vh" }}
								src={require("../../../assets/img/products/s20fv.png")}
								alt="Unimake Força de Vendas"
							/>
						</Col>
						<Col xs={22} sm={22} md={22} lg={10}>
							<ContatoComponent allWidth={false} />
						</Col>
					</Row>
				</div>

				<script type="application/ld+json">
					{JSON.stringify(jsonLdData, null, 2)}
				</script>
			</>
		);
	}
}
export default ForcaVendasPage;
