
import UnimakeApiService from '../UnimakeApiService';


export default class RamoAtividadeService {
    private unimakeApi: UnimakeApiService;
    private path: string = '/free/Loja/RamoAtividade';

    constructor() {
        this.unimakeApi = new UnimakeApiService();
    }

    public async GetAll(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.get(`${this.path}/GetAll`, {});

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
}
