import React from "react";
import { Form, Input, Button, Card, Row, Col } from "antd";
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput";

class UmkEndereco extends React.Component<any, any> {
	static defaultProps = {
		editing: false,
	};

	render() {
		return (
			<div>
				<Card title="Endereço">
					<Row justify="space-between">
						<Col sm={12} md={8} xs={12}>
							<Form.Item
								name="cep"
								rules={[
									{ required: true, message: "Informe o CEP!" },
									{ len: 9, message: "Informe um CEP válido!" },
									{ max: 9, message: "Máximo 9 caracteres" },
								]}
								label="CEP">
								<MaskedInput mask="00000-000" />
							</Form.Item>
						</Col>

						<Col style={{ marginTop: 30 }} sm={12} md={8} xs={12}>
							<Button
								target="_blank"
								shape="round"
								href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm"
								type="primary">
								Não sei meu CEP
							</Button>
						</Col>

						<Col md={7} className="hidden-xs hidden-sm"></Col>
					</Row>

					<Row justify="space-between">
						<Col sm={8} md={8}>
							<Form.Item
								name="endereco"
								rules={[
									{ required: true, message: "Informe o Logradouro!" },
									{ max: 100, message: "Máximo 100 caracteres!" },
								]}
								label="Logradouro">
								<Input maxLength={100} />
							</Form.Item>
						</Col>

						<Col sm={4} md={4}>
							<Form.Item
								name="numero"
								rules={[
									{ required: true, message: "Informe o Número!" },
									{ max: 30, message: "Máximo 30 caracteres!" },
								]}
								label="Número">
								<Input maxLength={30} />
							</Form.Item>
						</Col>

						<Col sm={10} md={11}>
							<Form.Item
								name="complemento"
								label="Complemento"
								rules={[{ max: 100, message: "Máximo 100 caracteres!" }]}>
								<Input maxLength={100} />
							</Form.Item>
						</Col>
					</Row>

					<Row justify="space-between">
						<Col sm={8} md={10}>
							<Form.Item
								name="bairro"
								rules={[
									{ required: true, message: "Informe o Bairro!" },
									{ max: 40, message: "Máximo 40 caracteres!" },
								]}
								label="Bairro">
								<Input maxLength={40} />
							</Form.Item>
						</Col>

						<Col sm={6} md={9}>
							<Form.Item
								name="cidade"
								rules={[
									{ required: true, message: "Informe a Cidade!" },
									{ max: 50, message: "Máximo 50 caracteres!" },
								]}
								label="Cidade">
								<Input maxLength={50}  />
							</Form.Item>
						</Col>

						<Col sm={4} md={4}>
							<Form.Item
								name="estado"
								rules={[
									{ required: true, message: "Informe o Estado!" },
									{ len: 2, message: "Informe UF com 2 caracteres!" },
								]}
								label="Estado">
								<Input maxLength={2} autoComplete="off" />
							</Form.Item>
						</Col>
					</Row>
				</Card>
			</div>
		);
	}
}

export default UmkEndereco;
