import React from 'react';
import { Card, Col, Empty, Input, Row, Select, Table, Form, Button } from 'antd';
import LoadingOverlay from 'react-loading-overlay';
import ModalUltil from '../../utils/ModalUtil';
import ClienteService from '../../services/ClienteService';
import StorageService from '../../services/StorageService';
import CommonUtil from '../../utils/CommonUtil';
import { ILicenca } from '../../contract/ILicenca';
import IApiResponse from '../../contract/IApiResponse';
import { ILicencaFilter } from '../../contract/cliente/ILicencaFilter';
import { IUsuario } from '../../contract/IUsuario';
import { ILicencaGrid } from '../../contract/ILicencaGrid';
import { FormInstance } from 'antd/lib/form';
import { Link } from "react-router-dom";

const { Option } = Select;

class UmkClienteLicencas extends React.Component<any, any> {
    formRef = React.createRef<FormInstance>();

    private ModalUltil: ModalUltil;
    private ClienteService: ClienteService;
    private storage: StorageService;
    private CommonUtil: CommonUtil;

    private filter: ILicencaFilter;

    PedidoFormatter = (licenca: ILicenca) => {
        let anuidadeString = licenca.anuidades == 1 ? 'ano' : 'anos';

        return (
            <div style={{ 'textAlign': 'center' }}>
                {licenca.idpedido} - {licenca.data_compra} <br />
                {licenca.nome} - {licenca.anuidades} {anuidadeString}
                {!licenca.data_baixa ? (
                    <div>
                        <span style={{ 'color': 'red' }}>Não baixado</span>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        );
    };

    ChaveFormatter = (chave: string) => {
        return (<span style={{ 'textAlign': 'center' }}>{chave}</span>);
    }

    ClienteFormatter = (licenca: ILicenca) => {
        return (
            <div>
                {licenca.cnpj} <br />
                {licenca.razao_social}
            </div>
        );
    }

    VigenciaFormatter = (licenca: ILicenca) => {
        let renovar = licenca.vigencia_renovar && licenca.vigencia_desativar;
        return (
            <div style={{ 'textAlign': 'center' }}>
                {licenca.termino_vigencia} <br />
                {
                    renovar ? (
                        <div>
                            <Link to={`${process.env.PUBLIC_URL}/cliente/licenca/renovar?rendnl=${licenca.vigencia_renovar_id}&id=${licenca.vigencia_renovar}`}>Renovar</Link>
                            &nbsp;|&nbsp;
                            <Link to={`${process.env.PUBLIC_URL}/cliente/licenca/desativar?idlicenca=${licenca.idlicenca}&idproduto=${licenca.idproduto}`}>Desativar</Link>
                        </div>
                    ) : (
                        <div></div>
                    )
                }
                {
                    licenca.vigencia_reativar ? (
                        <div>
                            <Link to={`${process.env.PUBLIC_URL}/cliente/licenca/reativar?idlicenca=${licenca.idlicenca}&idproduto=${licenca.idproduto}`}>Reativar</Link>
                        </div>
                    ) : (
                        <div></div>
                    )
                }
            </div>
        );
    }

    OpcoesFormatter = (licenca: ILicenca) => {
        let editar = licenca.data_geracao_licenca == null || licenca.data_geracao_licenca === '';

        return (
            <div style={{ 'textAlign': 'center' }}>
                {licenca.data_geracao_licenca} <br />
                {
                    editar ? (
                        <div>
                            <Link to={`${process.env.PUBLIC_URL}/cliente/licenca/editar?idlicenca=${licenca.idlicenca}`}>Editar</Link>
                        </div>
                    ) : (
                        <div>
                            {
                                licenca.link_download === 'Aguardando' ? (
                                    <span>Aguardando upload...</span>
                                ) : (
                                    <div>
                                        <a target='_blank' rel='noopener noreferrer' href={licenca.link_download}></a>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        );
    }

    state = {
        datasource: [],
        loading: false,
    }

    private columns = [
        {
            title: 'Pedido/ Produto',
            dataIndex: 'licenca',
            key: 'pedido',
            render: this.PedidoFormatter,
            width: 250
        },
        {
            title: 'Chave',
            dataIndex: 'chave',
            key: 'chave',
            render: this.ChaveFormatter,
            width: 140
        },
        {
            title: 'Gerenciado para',
            dataIndex: 'licenca',
            key: 'para',
            render: this.ClienteFormatter,
        },
        {
            title: 'Validade',
            dataIndex: 'licenca',
            key: 'validade',
            render: this.VigenciaFormatter,
            with: 100,
        },
        {
            title: 'Opções',
            dataIndex: 'licenca',
            key: 'opcoes',
            render: this.OpcoesFormatter,
        },
    ]

    constructor(props: any) {
        super(props);

        this.filter = {
            tipo: "Pendentes"
        }

        this.ModalUltil = new ModalUltil(props);
        this.ClienteService = new ClienteService();
        this.storage = new StorageService();
        this.CommonUtil = new CommonUtil();

        this.load = this.load.bind(this);
        this.handleChangeSituacao = this.handleChangeSituacao.bind(this);
        this.handleChangeProdutos = this.handleChangeProdutos.bind(this);
    }

    componentDidMount() {
        this.formRef.current.setFieldsValue({
            situacao: this.filter.tipo,
            produtos: '0'
        });
        this.load();
    }

    async load() {
        try {
            this.setState({ loading: true });

            let usuario: IUsuario = JSON.parse(this.storage.Get("Usuario"));

            this.filter.idcliente = usuario.idcliente;

            let result = await this.ClienteService.GetLicencas(this.filter);

            this.setState({ loading: false });

            let response: IApiResponse<Array<ILicenca>> = result.data;
            let valid = this.ClienteService.ValidateLoginFromResponse(response);

            if (valid) {
                let result = this.resolveEntities(response.data);
                this.setState({
                    datasource: result,
                });
            }

            this.setState({ loading: false });
        } catch (error) {
            this.setState({ loading: false });
            this.ModalUltil.Exception(error);
        }
    }

    resolveEntities(entities: Array<ILicenca>): Array<ILicencaGrid> {
        let gridEntities: Array<ILicencaGrid> = [];

        for (let item of entities) {
            let entity: ILicencaGrid = {
                licenca: item,
                chave: item.chave,
                validade: '',
            }

            gridEntities.push(entity);
            // if (item.data) item.data = format(new Date(item.data), "dd/MM/yyyy");
        }

        return gridEntities;
    }

    handleChangeSituacao(value: any) {
        this.formRef.current.setFieldsValue({ situacao: value })
        this.filter.tipo = value;

        this.load();
    }

    handleChangeProdutos(value: any) {
        this.formRef.current.setFieldsValue({ produtos: value })
        this.filter.produtos = value;

        this.load();
    }

    handleSubmit = (values: any) => {
        this.filter = {
            tipo: values.situacao,
            produtos: values.produtos,
            cnpj: values.cnpj,
            idlicenca: values.numeroLicenca
        };

        this.load();
    }

    render() {
        return (
            <div>
                <Card title="Filtros">
                    <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
                        <Row>
                            <Col sm={5} md={5} lg={5}>
                                <Form.Item
                                    label="Situação"
                                    name="situacao"
                                    className="form-item-umk">
                                    <Select onChange={this.handleChangeSituacao}>
                                        <Option key="Pendentes" value="Pendentes">Pendentes</Option>
                                        <Option key="Geradas" value="Geradas">Geradas vigentes</Option>
                                        <Option key="PenGeradasVdentes" value="GeradasV">Geradas vencidas</Option>
                                        <Option key="Desativadas" value="Desativadas">Desativadas</Option>
                                        <Option key="Todas" value="Todas">Todas (exceto desativadas)</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col style={{ 'marginLeft': 10 }} sm={5} md={5} lg={5}>
                                <Form.Item
                                    label="Produtos"
                                    name="produtos"
                                    className="form-item-umk">
                                    <Select onChange={this.handleChangeProdutos}>
                                        <Option value="0">Todos</Option>
                                        <Option value="15">DANFE View Express</Option>
                                        <Option value="14">DANFE View Office</Option>
                                        <Option value="2">DANFE View Plus</Option>
                                        <Option value="3">UniDANFE v.3 Plus</Option>
                                        <Option value="1">UniDANFE v.2 Plus</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col style={{ 'marginLeft': 10 }} sm={5} md={5} lg={5}>
                                <Form.Item
                                    label="CNPJ/CPF Licenciado"
                                    name="cnpj"
                                    className="form-item-umk">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col style={{ 'marginLeft': 10 }} sm={3} md={3} lg={3}>
                                <Form.Item
                                    label="Nº da Licença"
                                    name="numeroLicenca"
                                    className="form-item-umk">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col style={{ marginTop: 40, marginLeft: 10 }} sm={3} md={3} xs={3}>
                                <Form.Item>
                                    <Button type="primary" block htmlType="submit" className="login-form-button">
                                        Buscar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                    <Row>
                        <div hidden={this.filter.tipo != 'Pendentes'}>
                            <p style={{ color: "grey" }}>
                                <b>1)</b> Clique em "Editar" para informar o CNPJ/CPF e Razão Social/Nome de cada licença. <br />
                                <b>2)</b> As licenças podem ficar pendentes o tempo que for preciso. Elas não expiram enquanto estiverem nessa situação.
                            </p>
                        </div>

                        <div>
                            <p style={{ color: "grey" }}>
                                <b>NOVO</b>: Faça o download da licença dentro do aplicativo, acessando a tela "Gerenciamento de Licenças", botão "Download", e digitando a chave da licença.
                                Se a compra não foi paga, será baixada uma licença temporária com validade de 07 dias para você utilizar o sistema enquanto o processo de pagamento é concluído.
                                É preciso instalar a última versão do sistema para acessar essa funcionalidade. <a target='_blank' rel='noopener noreferrer' href="https://wiki.unimake.com.br/index.php/Manuais:UniDANFE/LicencasTemporarias"><b>Conheça as regras das licenças temporárias</b></a>.
                            </p>
                        </div>
                    </Row>
                </Card>

                <br />

                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Carregando dados...'
                >
                    {
                        (this.state.datasource.length > 0) ? (
                            <>
                                <Table
                                    bordered={true}
                                    dataSource={this.state.datasource}
                                    columns={this.columns}
                                    pagination={{ pageSize: 20}} />
                            </>
                        ) : (
                            <Empty description="Nenhum registro encontrado" />
                        )
                    }

                </LoadingOverlay>
            </div>
        );
    }
}

export default UmkClienteLicencas;