import {maskJs} from 'mask-js';
import {cpf, cnpj} from 'cpf-cnpj-validator';
import ICliente from './../contract/cliente/ICliente';

import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';
import parse from 'date-fns/parse';

const ie = require("inscricaoestadual");
const md5 = require('md5');
const currencyFormatter = require('currency-formatter');


export default class CommonUtil {

    /**
     * Formata um valor de data em string para um novo formato.
     * ex: yyyy-MM-dd para dd/MM/yyyy
     * @param value valor a ser formatado
     * @param oldFormat antigo formato
     * @param newFormat novo formato
     */
    public formatDate(value: string, oldFormat: string, newFormat: string): string {
        try {
            let data = parse(value, oldFormat, new Date(), {locale: ptBR});
            return format(data, newFormat, {locale: ptBR})
        } catch (err) {
            console.log(`erro ao formatar data: ${value}, formato: ${newFormat}`)
            return '';
        }
    }

    public formatDateSimple(value: any, dateFormat: string) {
        try {
            return format(new Date(value), dateFormat);
        } catch (err) {
            console.log(`erro ao formatar data: ${value}, formato: ${dateFormat}`)
            return '';
        }
    }

    public FormatCPF(cpf: any): string {
        cpf = cpf.toString();
        cpf = maskJs('999.999.999-99', cpf)

        return cpf;
    }

    public FormatCNPJ(cnpj: any): string {
        cnpj = cnpj.toString();
        cnpj = maskJs('99.999.999/9999-99', cnpj)
        return cnpj;
    }

    public FormatCurrency(valor: any): string {
        valor = parseFloat(valor);
        valor = valor.toFixed(2);

        return valor;
    }

    public FormatBrl(valor: any): string {
        return currencyFormatter.format(valor, {code: 'BRL'});
    }

    public IsLink(link: string): boolean {
        return (link.includes('http://') || link.includes('https://'));
    }

    public IsCPFCNPJValid(cpfcnpj: any) {
        if (!cpfcnpj)
            return false;

        if (cpfcnpj.length > 11) {
            return cnpj.isValid(cpfcnpj);
        } else {
            return cpf.isValid(cpfcnpj);
        }
    }

    public IEIsValid(inscricao, uf) {
        return ie(inscricao, uf);
    }

    public IsJuridica(tipopessoa: string) {
        return tipopessoa == 'J';
    }

    public ValidateCliente(cliente: ICliente) {
        let cnpj = cliente.cnpj.replaceAll('-', '').replaceAll('.', '');
        let erros = "";
        let tipoPessoa = cnpj.length == 11 ? 'F' : 'J';

        if (this.IsJuridica(tipoPessoa) && !cliente.razao_social) {
            erros += 'Campo "Nome de Fantasia" precisa ser informado \n';
        }

        if (this.IsJuridica(tipoPessoa) && !cliente.nome_fantasia) {
            erros += 'Campo "Nome de Fantasia" precisa ser informado \n';
        }

        if (!cliente.email) {
            erros += 'Campo "E-mail" precisa ser informado \n';
        }

        if (!cliente.endereco) {
            erros += 'Campo "Endereço" precisa ser informado \n';
        }

        if (!cliente.numero) {
            erros += 'Campo "Número" precisa ser informado \n';
        }

        if (!cliente.bairro) {
            erros += 'Campo "Bairro" precisa ser informado \n';
        }

        if (!cliente.cep) {
            erros += 'Campo "CEP" precisa ser informado \n';
        }

        if (!cliente.cidade) {
            erros += 'Campo "Cidade" precisa ser informado \n';
        }

        if (!cliente.estado) {
            erros += 'Campo "Estado" precisa ser informado \n';
        }

        if (!cliente.ddd) {
            erros += 'Campo "DDD" precisa ser informado \n';
        }

        if (!cliente.telefone) {
            erros += 'Campo "Telefone" precisa ser informado \n';
        }

        if (this.IsJuridica(tipoPessoa)) {
            if (!this.IEIsValid(cliente.ie, cliente.estado)) {
                erros += `Inscricao Estadual invalida para o Estado "${cliente.estado}" \n`;
            }
        } else {
            if (!cliente.ie) {
                erros += 'Campo "R.G" precisa ser informado \n';
            }
        }

        return erros;
    }

    public toMD5(value: any) {
        return md5(value);
    }

    public capitalizeFirstLetter(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

}
