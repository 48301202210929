import axios from 'axios';

export default class ApiRequestService {        
    private resolveUrl(url: string): string {
        return url.replace(/([^:]\/)\/+/g, "$1");
    }

    /**
     * Executa o Método GET
     * @param URL URL     
     */
    public async Get(URL: string, headers: any = null): Promise<any> {
        URL = this.resolveUrl(URL);

        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'get',
                url: URL,
                headers: headers
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }

 
    /**
     * Realiza o Método POST
     * @param URL URL
     * @param Data Dados/Body
     */
    public async Post(URL: string, Data?: any, headers?: any): Promise<any> {
        URL = this.resolveUrl(URL);

        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'post',
                url: URL,
                data: Data,
                headers: headers
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    /**
     * Realiza o Método Put
     * @param URL URL
     * @param Data Dados/Body
     */
    public async Put(URL: string, Data?: any, headers?: any): Promise<any> {
        URL = this.resolveUrl(URL);

        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'put',
                url: URL,
                data: Data,
                headers: headers
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    /**
     * Realiza o Método DELETE
     * @param URL URL
     * @param Data Dados/Body
     */
    public async Delete(URL: string, Data?: any, headers?: any): Promise<any> {
        URL = this.resolveUrl(URL);
        
        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'delete',
                url: URL,
                data: Data,
                headers: headers
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }
}