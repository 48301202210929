"use strict";

import React from "react";
import "./HomePage.scss";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sobre from "../Sobre/Sobre";
import Clientes from "../Clientes/Clientes";
import FaleConosco from "../FaleConosco/FaleConosco";
import Produtos from "../Produtos/Home/ProdutosHome";

class HomePage extends React.Component<any, any> {
	render() {
		return (
			<div>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Sistema de Gestão Integrada – ERP | Unimake Software</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="A Unimake Software é uma empresa especializada no ramo de soluções para ERP, DANFE, Nota Fiscal Eletrônica (NF-e), Nota Fiscal de Serviço Eletrônica (NFS-e), Conhecimento de Transporte Eletrônico (CT-e) com os produtos Unico ERP, UniDANFE, DANFE View, UniNF-e (NF-e), Consult-e (NF-e, CT-e), UniCT-e (CT-e), UniNFS-e (NFS-e), acesse e conheça nossos produtos." />
				</Helmet>

				<div className="home-container">
					<div className="top-container">
						<div className="image-home">
							<img
								src={require("../../assets/img/home/webp/unimake_solucoes.webp")}
								alt="logo unimake"
							/>
						</div>

						<div className="desc-home">
							<h1>Potencializando Negócios Digitais com inovação e Criatividade</h1>
							<p>Na Unimake Software, oferecemos soluções que vão além do convencional,
								permitindo que você foque no que realmente importa: o crescimento do seu negócio.
							</p>
							<Link to="/produtos"><button>Vamos Transformar Juntos!</button></Link>
						</div>
					</div>

					<Produtos />

					<div id="sobre">
						<Sobre />
					</div>

					<Clientes />

					<FaleConosco />

					<div className="msg-container">
						<p>Unimake Software, transformando ideias em tecnologia.</p>
						<button>Ao Infinito e Além!</button>
					</div>
				</div>
			</div>
		);
	}
}
export default HomePage;
