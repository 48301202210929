import React, { Component } from "react";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import UmkSocialBar from "../unimake-socialbar/UmkSocialBar";

class UmkFooter extends Component {
	render() {
		return (
			<div>
				<footer className="footer">
					<div className="footer-container">
						<Row style={{ paddingBottom: "80px" }}>
							<Col md={1} sm={1} />

							<Col xs={28} md={6} sm={6}>
								<div className="footer-container-image">
									<img
										className="unimake-logo-footer"
										src={require("../../../assets/img/unimakelogo.png")}
										alt="logo unimake"
									/>
									<UmkSocialBar />
									<img
										className="apae-selo-footer"
										src={require("../../../assets/img/apaeselo.png")}
										alt="Empresa amiga APAE"
									/>
								</div>
							</Col>

							<Col md={4} sm={4}>
								<p>
									<span className="umk-footer-titulo">Sobre</span>
								</p>
								<ul className="page-footer-list-v3">

									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<HashLink smooth to="/#sobre">Missão, Visão e Valores</HashLink>
									</li>

									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/fale-conosco" target="_blank"> Seja um Unimaker</a>
									</li>
								</ul>
							</Col>

							<Col md={4} sm={4}>
								<p>
									<span className="umk-footer-titulo">Soluções</span>
								</p>

								<ul className="page-footer-list-v3">
									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/geminibackup"> Gemini Backup</a>
									</li>
									
									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/unidanfe"> UniDANFE</a>
									</li>

									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/danfe-view" target="_blank"> DANFE View</a>
									</li>

									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/uninfe" target="_blank"> UniNFe</a>
									</li>

									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/e-bank"> E-Bank</a>
									</li>

									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/certificados"> Certificados Digitais</a>
									</li>
									
									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/bi"> Business Intelligence</a>
									</li>

									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/wandreydescomplica"> Wandrey Descomplica</a>
									</li>
								</ul>
							</Col>

							<Col md={4} sm={4}>
								<p>
									<span className="umk-footer-titulo">Nossos ERPS</span>
								</p>

								<ul className="page-footer-list-v3">
									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/mia"> MIA</a>
									</li>
									
									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/unico-fecularia"> Agro</a>
									</li>

									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/unico-auto"> Auto-Peças</a>
									</li>

									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/unico-ferragens"> Ferragens</a>
									</li>

									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/unico-material-eletrico">Materiais Elétricos</a>
									</li>

									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/unico-material-construcao">Materiais de Construção</a>
									</li>
								</ul>
							</Col>

							<Col md={4} sm={4}>
								<p>
									<span className="umk-footer-titulo">LGPD</span>
								</p>

								<ul className="page-footer-list-v3">
									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<a href="https://inside.unimake.com.br/fale-conosco"> Fale conosco</a>
									</li>
									<li>
										<FontAwesomeIcon color="#FFFF" icon={faAngleRight} />
										<Link to={`${process.env.PUBLIC_URL}/lgpd?s=avisoprivacidade`}> Aviso de privacidade</Link>
									</li>
								</ul>
							</Col>
						</Row>
						<hr />
						<Row>
							<p
								style={{
									color: "#ccc",
									textAlign: "center",
									margin: "auto",
									marginRight: "auto",
									display: "block",
								}}>
								&copy; Copyright
								<script>document.write((new Date()).getFullYear())</script> -
								Unimake Software Todos os direitos reservados.
							</p>
						</Row>
					</div>
				</footer>
			</div>
		);
	}
}

export default UmkFooter;