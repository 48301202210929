import React from "react";
import { Col, Row } from "antd";
import "./LGPDPage.scss";
import { Helmet } from "react-helmet";

class LGPDPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.getSection = this.getSection.bind(this);
        this.sectionSroll = this.sectionSroll.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.getSection();
        }, 200);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        this.getSection();
    }

    /**
     * Extrai a seção da URL e faz o scroll
     */
    getSection(): void {
        let url_string = window.location.href;
        let url = new URL(url_string);

        let section = url.searchParams.get("s");
        if (section) {
            this.sectionSroll(section);
        }
    }

    /**
     * Realiza o scroll para a seção
     * @param section 'faleconosco' | 'avisoprivacidade'
     */
    sectionSroll(section: string): void {
        let yOffset = -35;
        let element = document.getElementById(section);


        let y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Unimake Software - LGPD</title>
                    <link rel="canonical" href={`${process.env.PUBLIC_URL}/lgpd`} />
                    <meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
                    <meta name="description" content="A Unimake Software é uma empresa especializada no ramo de soluções para ERP, DANFE, Nota Fiscal Eletrônica (NF-e), Nota Fiscal de Serviço Eletrônica (NFS-e), Conhecimento de Transporte Eletrônico (CT-e) com os produtos Unico ERP, UniDANFE, DANFE View, UniNF-e (NF-e), Consult-e (NF-e, CT-e), UniCT-e (CT-e), UniNFS-e (NFS-e), acesse e conheça nossos produtos." />
                </Helmet>

                <section className="unimake-area-v3">
                    <div className="unimake-container">
                        <div className="lgpd-container">
                            <Row>
                                <Col sm={24} md={24}>
                                    <h1 className="ant-typography">LGPD - Lei Geral de Proteção de Dados Pessoais</h1>
                                </Col>
                            </Row>
                            <Row id="faleconosco">
                                <Col sm={24} md={24}>
                                    <h2 style={{ fontWeight: 'bold' }}>Fale conosco</h2>

                                    <br />

                                    <div>
                                        <p>
                                            Se você tiver dúvidas, sugestões ou preocupações sobre o uso de seus Dados Pessoais, entre em contato conosco, através do seguinte canal:
                                        </p>

                                        <span>E-mail: <a href="mailto:lgpd@unimake.com.br">lgpd@unimake.com.br</a> </span>

                                        <p>Sua mensagem será analisada durante o horário comercial.</p>
                                    </div>
                                </Col>
                            </Row>


                            <Row id="avisoprivacidade">
                                <Col sm={24} md={24}>
                                    <h2 style={{ fontWeight: 'bold' }}>Aviso de Privacidade</h2>

                                    <p style={{ marginTop: 10, textAlign: 'justify' }}>
                                        A Unimake Software está comprometida em proteger a privacidade e a segurança das informações pessoais dos usuários de seus serviços.
                                        Este Aviso de Privacidade explica como coletamos, usamos e protegemos seus Dados Pessoais. Também apresentamos as medidas de segurança
                                        implementadas para proteger esses dados contra acessos não autorizados, perda ou vazamento, em conformidade com a LGPD.
                                        <br />
                                        <br />
                                        Recomendamos que você leia este aviso por completo para garantir que esteja totalmente informado sobre o tratamento  de seus dados.
                                    </p>

                                    <h3 style={{ fontWeight: 'bold' }}>1. Dados pessoais que coletamos</h3>
                                    <p style={{ marginTop: 5, textAlign: 'justify' }}>
                                        Coletamos apenas os dados mínimos e necessários para efetivar o objetivo de entrar em contato via e-mail ou telefone para apresentar os 
                                        produtos e serviços oferecidos, além de atender às obrigações legais relacionadas à venda de produtos/serviços. Os dados coletados incluem: 
                                        <br />
                                        <br />
                                        <ul>
                                        <li>Nome Completo;</li>
                                        <li>Endereço de E-mail;</li>
                                        <li>Número de telefone.</li>
                                        </ul>
                                        <br />
                                        Além disso, para atender às exigências legais de emissão de notas fiscais, poderemos coletar dados adicionais, como:
                                        <br />
                                        <br />
                                        <ul>
                                        <li>CPF/CNPJ;</li>
                                        <li>Endereço Completo;</li>
                                        <li>Inscrição Estadual;</li>
                                        <li>Outros dados, não discriminados aqui, mas, que são necessários para atender a emissão da nota fiscal.</li>
                                        </ul>
                                        <br />
                                        O fornecimentos desses dados é voluntário, mas necessário para o contato, oferta de produtos e serviços, e cumprimento das obrigações legais.
                                    </p>

                                    <h3 style={{ fontWeight: 'bold' }}>2. Como utilizamos os seus dados</h3>
                                    <p style={{ marginTop: 5, textAlign: 'justify' }}>
                                        Os dados fornecidos são utilizados exclusivamente para os seguintes fins, baseados em seu consentimento, no cumprimento de obrigações legais,
                                        execução de contratos ou nosso legítimo interesse:
                                        <br />
                                        <br />
                                        <ul>
                                            <li>Envio de e-mails para oferta de produtos e serviços;</li>
                                            <li>Envio de e-mails para oferecer conteúdo exclusivo;</li>
                                            <li>Cumprimento das obrigações legais relacionadas à emissão de notas fiscais e outras questões legais decorrentes da venda de produtos/serviços.</li>
                                        </ul>
                                    </p>

                                    <h3 style={{ fontWeight: 'bold' }}>3. Segurança dos Dados </h3>
                                    <p style={{ marginTop: 5, textAlign: 'justify' }}>
                                        Adotamos medidas de segurança, técnicas e administrativas adequadas para proteger seus dados pessoais contra perda, acesso não autorizado, alteração e divulgação indevida.
                                        <br />
                                    </p>

                                    <h3 style={{ fontWeight: 'bold' }}>4. Transferência Internacional de Dados</h3>
                                    <p style={{ marginTop: 5, textAlign: 'justify' }}>
                                        Se os dados pessoais são transferidos para fora do Brasil, garantimos que as mesmas medidas de proteção de dados aplicadas localmente sejam seguidas, conforme exigido pela LGPD.
                                        <br />
                                    </p>

                                    <h3 style={{ fontWeight: 'bold' }}>5. Direitos do Titular dos Dados</h3>
                                    <p style={{ marginTop: 5, textAlign: 'justify' }}>
                                        De acordo com a LGPD, você tem direito a:
                                        <ul>
                                            <li>Confirmação da existência do tratamento de dados pessoais;</li>
                                            <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
                                            <li>Anonimização, bloqueio ou eliminação de dados desnecessários ou excessivos;</li>
                                            <li>Portabilidade dos dados a outro fornecedor de serviço, mediante requisição expressa;</li>
                                            <li>Informações sobre entidades públicas ou privadas com as quais compartilhamos seus dados;</li>
                                            <li>Revogação do consentimento, quando aplicável;</li>
                                            <li>Exclusão dos seus dados, quando aplicável.</li>
                                        </ul>
                                        <br />
                                    </p>

                                    <h3 style={{ fontWeight: 'bold' }}>6. Procedimentos para Exercício dos Direitos</h3>
                                    <p style={{ marginTop: 5, textAlign: 'justify' }}>
                                        O titular dos dados pode exercer seus direitos entrando em contato conosco através do e-mail lgpd@unimake.com.br, com uma requisição formal.
                                        Nós responderemos conforme as diretrizes da LGPD, durante o horário comercial.
                                    </p>

                                    <h3 style={{ fontWeight: 'bold' }}>7. Alterações no Aviso de Privacidade</h3>
                                    <p style={{ marginTop: 5, textAlign: 'justify' }}>
                                        Este Aviso de Privacidade pode ser atualizado periodicamente para refletir as mudanças nas nossas práticas de privacidade ou 
                                        nas leis aplicáveis, com efeito imediato após a publicação da versão atualizada em nosso website.
                                    </p>

                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default LGPDPage;
