import React from "react";

import {Form, Row, Col, Input, Select, Button, Spin, Card, Divider, Typography, Checkbox} from "antd";
import IResponse from "../../contract/IResponse";
import NotificationUtil from "../../utils/NotificationUtil";
import ModalUltil from "../../utils/ModalUtil";
import {FormInstance} from "antd/lib/form";
import ClienteService from "../../services/ClienteService";
import StorageService from "../../services/StorageService";
import RamoAtividadeService from "../../services/Loja/RamoAtividadeService";

import IClienteCreate from "../../contract/cliente/IClienteCreate";
import IClienteCreateForm from "../../contract/form/IClienteCreateForm";
import {Redirect} from "react-router-dom";
import CommonUtil from "../../utils/CommonUtil";
import CookieUtil from "../../utils/CookieUtil";
import {MaskedInput} from "antd-mask-input";
import {cnpj} from "cpf-cnpj-validator";
import ICliente from "./../../contract/cliente/ICliente";
import UmkEndereco from "../v3/unimake-endereco/UmkEndereco";
import {updateNameClient} from "../../actions/CartActions";
import IClienteUpdateForm from "../../contract/form/IClienteUpdateForm";
import IClienteUpdate from "../../contract/cliente/IClienteUpdate";
import DynamicPhone from "../../hooks/DynamicPhone";

const {Option} = Select;
const {Title, Paragraph, Text, Link} = Typography;

class UmkClienteProfile extends React.Component<any, any> {
    formRef = React.createRef<FormInstance>();

    state = {
        loading: false,
        redirect: false,
        redirectPage: "",
        ramosAtividade: [],
        tipoPessoa: 0,
        current: 0,
        cnpj: '',
    };

    private Commoon: CommonUtil;
    private NotificationService: NotificationUtil;
    private ModalUltil: ModalUltil;
    private ClienteService: ClienteService;
    private storage: StorageService;
    private CookieService: CookieUtil;
    private RamoAtividadeService: RamoAtividadeService;

    constructor(props: any) {
        super(props);

        this.Commoon = new CommonUtil();
        this.ClienteService = new ClienteService();
        this.NotificationService = new NotificationUtil();
        this.ModalUltil = new ModalUltil(props);
        this.storage = new StorageService();
        this.CookieService = new CookieUtil();
        this.RamoAtividadeService = new RamoAtividadeService();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadCliente = this.loadCliente.bind(this);
        this.handleChangeComoConheceu = this.handleChangeComoConheceu.bind(this);
        this.handleChangeRamoAtividade = this.handleChangeRamoAtividade.bind(this);
        this.getRamosAtividade = this.getRamosAtividade.bind(this);
    }

    async componentDidMount() {
        await this.getRamosAtividade();
        await this.loadCliente();
    }

    async loadCliente(): Promise<void> {
        try {
            this.setState({loading: true});

            let result = await this.ClienteService.GetCliente();
            let response: IResponse = result.data;

            if (response.success) {
                let cliente: ICliente = response.data;

                if (cliente) {
                    this.storage.Set("Usuario", JSON.stringify(cliente));
                    updateNameClient(cliente.nome_fantasia);

                    this.setState({
                        cnpj: cliente.cnpj,
                        tipoPessoa: cliente.cnpj.length > 14 ? 1 : 0,
                    });

                    let telefone = `${cliente.ddd}${cliente.telefone}`;
                    telefone = telefone
                                        .replace(/\D/g, '');

                    setTimeout(() => {
                        this.formRef.current.setFieldsValue({
                            cnpj: cliente.cnpj,
                            ie: cliente.ie,
                            razao_social: cliente.razao_social,
                            nome_fantasia: cliente.nome_fantasia,
                            ramo: cliente.idramo,
                            email: cliente.email,
                            pessoa_contato: cliente.pessoa_contato,
                            site: cliente.site,
                            como_conheceu: cliente.como_conheceu,
                            ramoatividade: cliente.idramo,
                            email2: cliente.email2,
                            email_fat: cliente.email_fat,
                            telefone: telefone,
                            cep: cliente.cep,
                            endereco: cliente.endereco,
                            numero: cliente.numero,
                            complemento: cliente.complemento,
                            bairro: cliente.bairro,
                            cidade: cliente.cidade,
                            estado: cliente.estado,
                            autorizo_promocao_renovacao_app: cliente.autorizo_promocao_renovacao_app == "1" ? true : false,
                            autorizo_email_renovacao_licencas: cliente.autorizo_email_renovacao_licencas == "1" ? true : false,
                            autorizo_autorenovacao_licencas: cliente.autorizo_autorenovacao_licencas == "1" ? true : false,
                        });
                    }, 400);


                }

            } else {
                throw Error(response.message);
            }

            this.setState({loading: false});
        } catch (error) {
            this.setState({loading: false});
            this.NotificationService.Exception(error);
        }
    }

    async getRamosAtividade() {
        try {
            this.setState({loading: true});

            let result = await this.RamoAtividadeService.GetAll();
            let response: IResponse = result.data;

            if (response.success) {
                this.setState({
                    ramosAtividade: response.data,
                });
            } else {
                this.NotificationService.Error(response.message);
            }

            this.setState({loading: false});
        } catch (error) {
            this.setState({loading: false});
            this.NotificationService.Exception(error);
        }
    }

    handleChangeRamoAtividade(value: any) {
        this.formRef.current.setFieldsValue({ramoatividade: value});
    }

    handleChangeComoConheceu(value: any) {
        this.formRef.current.setFieldsValue({como_conheceu: value});
    }

    handleSubmit = async (values: IClienteUpdateForm) => {
        try {
            let telefone = values.telefone.replace(/\D/g, '').trim();
            let dd = telefone.substring(0, 2);
            telefone = telefone.substring(2, telefone.length);

            let tipoPessoa = values.cnpj.replace(/\D+/g, '').length === 11 ? 0 : 1;
            let cliente: IClienteUpdate = {
                ie: values.ie,
                razao_social: values.razao_social,
                nome_fantasia: tipoPessoa == 0 ? values.razao_social : values.nome_fantasia,
                email: values.email,
                endereco: values.endereco,
                numero: values.numero,
                complemento: values.complemento,
                bairro: values.bairro,
                cep: values.cep,
                cidade: values.cidade,
                uf: values.estado,
                dd: dd,
                telefone: telefone,
                email2: values.email2,
                email_fat: values.email_fat,
                id_ramo: parseInt(values.ramoatividade),
                pessoa_contato: values.pessoa_contato,
                site: values.site,
                como_conheceu: values.como_conheceu,
                autorizo_autorenovacao_licencas: values.autorizo_autorenovacao_licencas,
                autorizo_email_renovacao_licencas: values.autorizo_email_renovacao_licencas,
                autorizo_promocao_renovacao_app: values.autorizo_promocao_renovacao_app,
            };

            this.setState({ loading: true });

            let result = await this.ClienteService.Update(cliente);

            this.setState({ loading: false });

            let response: IResponse = result.data;

            if (response.success) {
                this.NotificationService.Success("Dados Atualizados");
                this.loadCliente();
            } else {
                throw Error(response.message);
            }

        } catch (error) {
            this.ModalUltil.Exception(error);
            this.setState({
                loading: false,
            });
        }
    };

    render() {
        if (this.state.redirect) {
            return <Redirect push to={this.state.redirectPage}/>;
        }

        return (
            <div>
                <div className="bellow-line">
                    <Title level={4}>Meus Dados</Title>
                </div>

                <Spin tip="Aguarde..." spinning={this.state.loading}>
                    <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit} className="perfil-form">
                        <Card title="Dados Básicos">
                            <Row>
                                <Col sm={4} md={4}>
                                    <Form.Item
                                        name="cnpj"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    this.state.tipoPessoa == 0 ? "Informe o CPF" : "Informe o CNPJ",
                                            },
                                        ]}
                                        label={this.state.tipoPessoa == 0 ? "CPF" : "CNPJ"}>
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>

                                <Col sm={1} md={1}/>

                                <Col md={4} sm={4}>
                                    <Form.Item
                                        help={this.state.tipoPessoa == 1 ? "Caso não tenha, digite no campo a palavra ISENTO" : null}
                                        name="ie"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    this.state.tipoPessoa == 0
                                                        ? "Informe o RG!"
                                                        : "Informe a inscrição estadual ou, caso não tenha, digite no campo a palavra ISENTO",
                                            },
                                            {
                                                max: 16,
                                                message:
                                                    this.state.tipoPessoa == 0
                                                        ? "Informe RG válido!"
                                                        : "Informe Inscrição Estadual válida!",
                                            },
                                        ]}
                                        label={this.state.tipoPessoa == 0 ? "RG" : "Inscrição Estadual"}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={11} md={11}>
                                    <Form.Item
                                        name="razao_social"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    this.state.tipoPessoa == 0
                                                        ? "Informe o Nome Completo!"
                                                        : "Informe a Razão Social!",
                                            },
                                        ]}
                                        label={this.state.tipoPessoa == 0 ? "Nome Completo" : "Razão Social"}>
                                        <Input/>
                                    </Form.Item>
                                </Col>

                                <Col sm={1} md={1}/>

                                <Col sm={11} md={11}>
                                    <Form.Item
                                        hidden={this.state.tipoPessoa == 0}
                                        name="nome_fantasia"
                                        rules={[{required: true, message: "Informe o Nome Fantasia!"}]}
                                        label="Nome Fantasia">
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={11} md={11}>
                                    {/* Mudar para auto complete */}
                                    <Form.Item
                                        name="ramoatividade"
                                        rules={[
                                            {required: true, message: "O campo Ramo de Atividade é obrigatório!"},
                                        ]}
                                        label="Ramo de Atividade">
                                        <Select
                                            optionFilterProp="children"
                                            showSearch={true}
                                            onChange={this.handleChangeRamoAtividade}
                                            placeholder="Selecione">
                                            {this.state.ramosAtividade.map((item) => (
                                                <Option key={item.idramo} value={item.idramo}>
                                                    {item.descricao}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <br/>
                        <Card title="Contato">
                            <Row>
                                <Col sm={7} md={7}>
                                    <Form.Item
                                        name="pessoa_contato"
                                        rules={[
                                            {required: true, message: "Informe a Pessoa para contato!"},
                                            {max: 50, message: "Máximo 50 caracteres"},
                                        ]}
                                        label="Pessoa para contato">
                                        <Input/>
                                    </Form.Item>
                                </Col>

                                <Col sm={1} md={1}/>

                                <Col sm={7} md={7}>
                                    <Form.Item name="site" label="Website"
                                               rules={[
                                                   {max: 200, message: "Máximo 200 caracteres"},
                                               ]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>

                                <Col sm={1} md={1}/>

                                <Col sm={7} md={7}>
                                    <Form.Item
                                        name="como_conheceu"
                                        rules={[{required: true, message: "O campo Como Conheceu é obrigatório!"}]}
                                        label="Como conheceu">
                                        <Select onChange={this.handleChangeComoConheceu} placeholder="Selecione">
                                            <Option value="10">Amigos</Option>
                                            <Option value="15">Contador</Option>
                                            <Option value="18">Contadores.cnt.br</Option>
                                            <Option value="9">Email</Option>
                                            <Option value="5">Folder</Option>
                                            <Option value="11">Funcionários Unimake</Option>
                                            <Option value="20">Google - Anúncio</Option>
                                            <Option value="1">Google - Pesquisa</Option>
                                            <Option value="14">Meu Fornecedor</Option>
                                            <Option value="19">Momento de Reflexão</Option>
                                            <Option value="16">Outros Buscadores</Option>
                                            <Option value="13">Redes Sociais / Foruns</Option>
                                            <Option value="8">Super Downloads</Option>
                                            <Option value="2">Twitter</Option>
                                            <Option value="17">Youtube</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Row>
                                <Col sm={7} md={7}>
                                    <Form.Item
                                        name="email"
                                        rules={[{required: true, message: "Informe o Email!"}]}
                                        label="Email">
                                        <Input type={"email"}/>
                                    </Form.Item>
                                </Col>

                                <Col sm={1} md={1}/>

                                <Col sm={7} md={7}>
                                    <Form.Item name="email2" label="Email Secundário">
                                        <Input/>
                                    </Form.Item>
                                </Col>

                                <Col sm={1} md={1}/>

                                <Col sm={7} md={7}>
                                    <Form.Item
                                        name="email_fat"
                                        rules={[{required: true, message: "Informe o Email para Faturamento"}]}
                                        label="Email p/ Faturamento (p/ envio de NF-e)">
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={7} md={7}>
                                    <Form.Item
                                        name="telefone"
                                        rules={[{required: true, message: "Informe o Telefone!"}]}
                                        label="DDD e Telefone">
                                        <DynamicPhone />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <br/>
                        <UmkEndereco editing={true}/>
                        <br/>
                        <Card>
                            <Form.Item name="autorizo_autorenovacao_licencas" valuePropName="checked">
                                <Checkbox>
                                    Autorizo a Unimake renovar automaticamente minhas licenças próximo ao vencimento
                                </Checkbox>
                            </Form.Item>

                            <Form.Item name="autorizo_email_renovacao_licencas" valuePropName="checked">
                                <Checkbox>
                                    Autorizo a Unimake me enviar e-mails informando licenças que necessitam ser
                                    renovadas
                                </Checkbox>
                            </Form.Item>

                            <Form.Item name="autorizo_promocao_renovacao_app" valuePropName="checked">
                                <Checkbox>
                                    Autorizo a Unimake oferecer renovação de licenças dentro do aplicativo
                                    para CNPJs/CPFs diferentes de {this.state.cnpj}
                                </Checkbox>
                            </Form.Item>
                        </Card>
                        <br/>
                        <Form.Item>
                            <Button block type="primary" htmlType="submit" className="perfil-form-button">
                                Atualizar
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
        );
    }
}

export default UmkClienteProfile;
