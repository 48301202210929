import React from "react";
import { Form, Input, Button, Card, Row, Col, Divider, Spin, Select, Radio, Steps, Typography } from "antd";
import MaskedInput from "antd-mask-input";
import { Redirect } from "react-router-dom";

import ClienteService from "../../../services/ClienteService";
import StorageService from "../../../services/StorageService";

import IResponse from "../../../contract/IResponse";
import RamoAtividadeService from ".././../../services/Loja/RamoAtividadeService";
import CommonUtil from "./../../../utils/CommonUtil";
import NotificationUtil from "./../../../utils/NotificationUtil";
import CookieUtil from "./../../../utils/CookieUtil";
import ModalUltil from "./../../../utils/ModalUtil";
import IClienteCreate from "./../../../contract/cliente/IClienteCreate";
import IClienteCreateForm from "../../../contract/form/IClienteCreateForm";
import UmkEndereco from "./../../v3/unimake-endereco/UmkEndereco";
import { FormInstance } from "antd/lib/form";

import { cpf, cnpj } from "cpf-cnpj-validator";
import DynamicPhone from "../../../hooks/DynamicPhone";

const { Option } = Select;
const { Title } = Typography;


class UmkClienteCadastro extends React.Component<any, any> {
	formRef = React.createRef<FormInstance>();

	state = {
		loading: false,
		redirect: false,
		redirectPage: "",
		ramosAtividade: [],
		tipoPessoa: 1,
		current: 0,
	};

	private Commoon: CommonUtil;
	private RamoAtividadeService: RamoAtividadeService;
	private NotificationService: NotificationUtil;
	private ModalUltil: ModalUltil;
	private ClienteService: ClienteService;
	private storage: StorageService;
	private CookieService: CookieUtil;

	constructor(props: any) {
		super(props);

		this.Commoon = new CommonUtil();
		this.ClienteService = new ClienteService();
		this.NotificationService = new NotificationUtil();
		this.ModalUltil = new ModalUltil(props);
		this.storage = new StorageService();
		this.CookieService = new CookieUtil();
		this.RamoAtividadeService = new RamoAtividadeService();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChangeComoConheceu = this.handleChangeComoConheceu.bind(this);
		this.handleChangeRamoAtividade = this.handleChangeRamoAtividade.bind(this);
		this.tipoPessoaChange = this.tipoPessoaChange.bind(this);
		this.getRamosAtividade = this.getRamosAtividade.bind(this);
	}

	componentDidMount() {
		this.getRamosAtividade();
	}

	async getRamosAtividade() {
		try {
			this.setState({ loading: true });

			let result = await this.RamoAtividadeService.GetAll();
			let response: IResponse = result.data;

			if (response.success) {
				this.setState({
					ramosAtividade: response.data,
				});
			} else {
				this.NotificationService.Error(response.message);
			}

			this.setState({ loading: false });
		} catch (error) {
			this.setState({ loading: false });
			this.NotificationService.Exception(error);
		}
	}

	handleChangeRamoAtividade(value: any) {
		this.formRef.current.setFieldsValue({ ramoatividade: value });
	}

	tipoPessoaChange(e: any) {
		this.setState({
			tipoPessoa: e.target.value,
		});
	}

	handleChangeComoConheceu(value: any) {
		this.formRef.current.setFieldsValue({ como_conheceu: value });
	}

	handleSubmit = async (values: IClienteCreateForm) => {
		try {
			if (values.confsenha != values.senha) {
				this.NotificationService.Error("As senhas não conferem!");
				return;
			}

			let telefone = values.telefone.replace(/\D/g, '').trim();
			let dd = telefone.substring(0, 2);
			telefone = telefone.substring(2, telefone.length);

			let cliente: IClienteCreate = {
				tipo_pessoa: this.state.tipoPessoa == 0 ? "Física" : "Jurídica",
				cnpj: values.cnpj,
				ie: values.ie,
				razao_social: values.razao_social,
				nome_fantasia: this.state.tipoPessoa == 0 ? values.razao_social : values.nome_fantasia,
				email: values.email,
				senha: values.senha,
				endereco: values.endereco,
				numero: values.numero,
				complemento: values.complemento,
				bairro: values.bairro,
				cep: values.cep,
				cidade: values.cidade,
				estado: values.estado,
				pais: "Brasil", //TODO: Precisa melhorar isso
				dd: dd,
				telefone: telefone,
				email2: values.email2,
				email_fat: values.email_fat,
				id_ramo: parseInt(values.ramoatividade),
				pessoa_contato: values.pessoa_contato,
				site: values.site,
				como_conheceu: values.como_conheceu
			};

			this.setState({ loading: true });

			try {
				let result = await this.ClienteService.Create(cliente);

				this.setState({ loading: false });

				let response: IResponse = result.data;

				if (response.success) {
					this.NotificationService.Success("Cadastro Realizado");

					this.setState({
						redirect: true,
						redirectPage: `${process.env.PUBLIC_URL}/cliente/login`,
					});
				} else {
					throw Error(response.message);
				}
			} catch (error) {
				if (error.message.includes("23505") && error.message.includes("loja_cliente_cnpj")) {
					this.NotificationService.Error("CPF/CNPJ já cadastrado.");
				} else {
					this.ModalUltil.Exception(error);
				}
			}
		} catch (error) {
			this.ModalUltil.Exception(error);
			this.setState({
				loading: false,
			});
		}
	};

	render() {
		if (this.state.redirect) {
			return <Redirect push to={this.state.redirectPage} />;
		}

		return (
			<div>
				<div className="bellow-line">
					<Title level={3}>Cadastro de Cliente</Title>
				</div>

				<Spin tip="Aguarde..." spinning={this.state.loading}>
					<Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit} className="login-form">
						<Card title="Dados básicos">
							<Row>
								<Form.Item label="Tipo de Pessoa">
									<Radio.Group onChange={this.tipoPessoaChange} value={this.state.tipoPessoa}>
										<Radio value={1}>Pessoa Jurídica</Radio>
										<Radio value={0}>Pessoa Física</Radio>
									</Radio.Group>
								</Form.Item>
							</Row>

							{/* CPF/CNPJ */}
							<Row>
								{this.state.tipoPessoa == 0 ? (
									<Col sm={4} md={4}>
										<Form.Item
											name="cnpj"
											rules={[
												{ required: true, message: "Informe o CPF!" },
												{ len: 14, message: "Informe um CPF válido!" },
												({ getFieldValue }) => ({
													validator(_, value) {
														if (value === "") {
															return Promise.resolve();
														}

														if (cpf.isValid(value)) {
															return Promise.resolve();
														} else {
															return Promise.reject(new Error("Informe um CPF válido"));
														}
													},
												}),
											]}
											label="CPF">
											<MaskedInput mask="000.000.000-00" />
										</Form.Item>
									</Col>
								) : (
									<Col sm={4} md={4}>
										<Form.Item
											name="cnpj"
											rules={[
												{ required: true, message: "Informe o CNPJ!" },
												{ len: 18, message: "Informe um CNPJ válido!" },
												({ getFieldValue }) => ({
													validator(_, value) {
														if (value === "") {
															return Promise.resolve();
														}

														if (cnpj.isValid(value)) {
															return Promise.resolve();
														} else {
															return Promise.reject(new Error("Informe um CNPJ válido"));
														}
													},
												}),
											]}
											label="CNPJ">
											<MaskedInput mask="00.000.000/0000-00" />
										</Form.Item>
									</Col>
								)}

								<Col sm={1} md={1} />

								<Col md={5} sm={5}>
									<Form.Item
										help={this.state.tipoPessoa == 1 ? "Caso não tenha, digite no campo a palavra ISENTO" : null}
										name="ie"
										rules={[
											{
												required: true,
												message:
													this.state.tipoPessoa == 0
														? "Informe o RG!"
														: "Informe a inscrição estadual ou, caso não tenha, digite no campo a palavra ISENTO",
											},
											{
												max: 16,
												message:
													this.state.tipoPessoa == 0
														? "Informe RG válido!"
														: "Informe Inscrição Estadual válida!",
											},
										]}
										label={this.state.tipoPessoa == 0 ? "RG" : "Inscrição Estadual"}>
										<Input />
									</Form.Item>
								</Col>

								<Col sm={15} md={18} />
							</Row>

							{/* Nome */}
							<Row>
								<Col sm={11} md={11}>
									<Form.Item
										name="razao_social"
										rules={[
											{
												required: true,
												message:
													this.state.tipoPessoa == 0
														? "Informe o Nome Completo!"
														: "Informe a Razão Social!",
											},
											{ max: 100, message: "Máximo 100 caracteres" },
										]}
										label={this.state.tipoPessoa == 0 ? "Nome Completo" : "Razão Social"}>
										<Input />
									</Form.Item>
								</Col>

								<Col sm={1} md={1} />

								<Col sm={11} md={11}>
									<Form.Item
										hidden={this.state.tipoPessoa == 0}
										name="nome_fantasia"
										rules={[
											{
												required: this.state.tipoPessoa == 0 ? false : true,
												message: "Informe o Nome Fantasia!",
											},
											{ max: 100, message: "Máximo 100 caracteres" },
										]}
										label="Nome Fantasia">
										<Input />
									</Form.Item>
								</Col>
							</Row>

							<Row>
								<Col sm={11} md={11}>
									<Form.Item
										name="ramoatividade"
										rules={[
											{ required: true, message: "O campo Ramo de Atividade é obrigatório!" },
										]}
										label="Ramo de Atividade">
										<Select
											optionFilterProp="children"
											showSearch={true}
											onChange={this.handleChangeRamoAtividade}
											placeholder="Selecione">
											{this.state.ramosAtividade.map((item) => (
												<Option value={item.idramo}>{item.descricao}</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
						</Card>

						<br />

						<Card title="Contato">
							<Row>
								<Col sm={7} md={7}>
									<Form.Item
										name="pessoa_contato"
										rules={[
											{ required: true, message: "Informe a Pessoa para contato!" },
											{ max: 50, message: "Máximo 50 caracteres" },
										]}
										label="Pessoa para contato">
										<Input />
									</Form.Item>
								</Col>

								<Col sm={1} md={1} />

								<Col sm={7} md={7}>
									<Form.Item name="site" label="Website"
										rules={[
											{ max: 200, message: "Máximo 200 caracteres" },
										]}>
										<Input />
									</Form.Item>
								</Col>

								<Col sm={1} md={1} />

								<Col sm={7} md={7}>
									<Form.Item
										name="como_conheceu"
										rules={[{ required: true, message: "O campo Como Conheceu é obrigatório!" }]}
										label="Como conheceu">
										<Select onChange={this.handleChangeComoConheceu} placeholder="Selecione">
											<Option value="10">Amigos</Option>
											<Option value="15">Contador</Option>
											<Option value="18">Contadores.cnt.br</Option>
											<Option value="9">Email</Option>
											<Option value="5">Folder</Option>
											<Option value="11">Funcionários Unimake</Option>
											<Option value="20">Google - Anúncio</Option>
											<Option value="1">Google - Pesquisa</Option>
											<Option value="14">Meu Fornecedor</Option>
											<Option value="19">Momento de Reflexão</Option>
											<Option value="16">Outros Buscadores</Option>
											<Option value="13">Redes Sociais / Foruns</Option>
											<Option value="8">Super Downloads</Option>
											<Option value="2">Twitter</Option>
											<Option value="17">Youtube</Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>

							{/* Email */}
							<Row>
								<Col sm={7} md={7}>
									<Form.Item
										name="email"
										rules={[
											{ required: true, message: "Informe o Email!" },
											{ max: 70, message: "Máximo 70 caracteres" },
											{ type: "email", message: "Informe um e-mail válido!" },
										]}
										label="Email">
										<Input />
									</Form.Item>
								</Col>

								<Col sm={1} md={1} />

								<Col sm={7} md={7}>
									<Form.Item
										name="email2"
										label="Email Secundário"
										rules={[
											{ max: 70, message: "Máximo 70 caracteres" },
											{ type: "email", message: "Informe um e-mail válido!" },
										]}>
										<Input />
									</Form.Item>
								</Col>

								<Col sm={1} md={1} />

								<Col sm={7} md={7}>
									<Form.Item
										name="email_fat"
										rules={[
											{ required: true, message: "Informe o Email para Faturamento" },
											{ max: 70, message: "Máximo 70 caracteres" },
											{ type: "email", message: "Informe um e-mail válido!" },
										]}
										label="Email para Faturamento">
										<Input />
									</Form.Item>
								</Col>
							</Row>

							<Row>
								<Col sm={7} md={7}>
									<Form.Item
										name="telefone"
										rules={[
											{ required: true, message: "Informe o Telefone!" },
											{ max: 16, message: "Informe um telefone válido" },
											{ min: 14, message: "Informe um telefone válido" },
										]}
										label="DDD e Telefone">
										<DynamicPhone />
									</Form.Item>
								</Col>
							</Row>
						</Card>
						<br />

						<UmkEndereco />

						<br />
						<Card title="Dados de Acesso">
							<Row>
								<Col sm={8} md={8}>
									<Form.Item
										name="senha"
										rules={[
											{ required: true, message: "O campo Senha é obrigatório!" },
											{ max: 50, message: "Informe uma senha com até 50 caracteres" },
										]}
										label="Senha">
										<Input type="password" autoComplete="new-password" />
									</Form.Item>
								</Col>

								<Col sm={1} md={1} />

								<Col sm={8} md={8}>
									<Form.Item
										name="confsenha"
										rules={[
											{ required: true, message: "O campo Confirmar Senha é obrigatório!" },
											{ max: 50, message: "Informe uma senha com até 50 caracteres" },
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue("senha") === value) {
														return Promise.resolve();
													}
													return Promise.reject(new Error("As duas senhas não são iguais!"));
												},
											}),
										]}
										dependencies={["senha"]}
										label="Confirmar Senha">
										<Input type="password" autoComplete="new-password" />
									</Form.Item>
								</Col>
							</Row>
						</Card>

						<Divider />

						<Form.Item>
							<Row>
								<Col sm={24} md={12} lg={6}>
									<Button
										shape="round"
										block
										type="primary"
										htmlType="submit"
										className="login-form-button">
										Cadastrar
									</Button>
								</Col>
							</Row>
						</Form.Item>
					</Form>
				</Spin>
			</div>
		);
	}
}
export default UmkClienteCadastro;
