export default class CookieUtil {
    public Set(key: string, value: any) {
        document.cookie = `${key}= ${value}`;
    }

    public async Get(key: string): Promise<any> {
        return new Promise(async (resolve) => {
            let name = key + "=";
            let decodedCookie = await decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
    
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    resolve(c.substring(name.length, c.length));
                }
            }
    
            resolve("");
        });       
    }

    public Delete(key: string) {
        document.cookie = window.name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}