import React from "react";
import { Card, Col, Divider, Radio, Row, Spin, Typography } from "antd";
import TrabalheConoscoService from "./../../services/TrabalheConoscoService";
import NotificationUtil from "../../utils/NotificationUtil";
import ModalUltil from "./../../utils/ModalUtil";
import CommonUtil from "./../../utils/CommonUtil";
import IResponse from "../../contract/IResponse";
import UmkVagasList from "../../components/trabalhe-conosco/UmkVagasList";
import UmkCurriculoLogin from "./../../components/trabalhe-conosco/UmkCurriculoLogin";
import {Helmet} from "react-helmet";

const { Title, Paragraph, Text, Link } = Typography;

class TrabalheConoscoPage extends React.Component<any, any> {
	state = {
		loading: false,
		vagas: [],
	};

	private Common: CommonUtil;
	private NotificationService: NotificationUtil;
	private ModalUltil: ModalUltil;
	private TrabalheConoscoService: TrabalheConoscoService;

	constructor(props: any) {
		super(props);

		this.Common = new CommonUtil();
		this.TrabalheConoscoService = new TrabalheConoscoService();
		this.NotificationService = new NotificationUtil();
		this.ModalUltil = new ModalUltil(props);

		this.getVagas = this.getVagas.bind(this);
	}

	componentDidMount() {
		this.getVagas();
	}

	async getVagas() {
		try {
			this.setState({ loading: true });

			let result = await this.TrabalheConoscoService.GetVagas();

			this.setState({ loading: false });

			let response: IResponse = result.data;

			if (response.success) {
				this.setState({
					vagas: response.data,
				});
			} else {
				this.ModalUltil.Exception(response.message);
			}
		} catch (error) {
			this.setState({ loading: false });
			this.ModalUltil.Exception(error);
		}
	}

	render() {
		return (
			<div>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Unimake Software - Trabalhe Conosco</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/trabalhe-conosco`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="A Unimake Software é uma empresa especializada no ramo de soluções para ERP, DANFE, Nota Fiscal Eletrônica (NF-e), Nota Fiscal de Serviço Eletrônica (NFS-e), Conhecimento de Transporte Eletrônico (CT-e) com os produtos Unico ERP, UniDANFE, DANFE View, UniNF-e (NF-e), Consult-e (NF-e, CT-e), UniCT-e (CT-e), UniNFS-e (NFS-e), acesse e conheça nossos produtos." />
				</Helmet>

				<section className="unimake-container">
					<Row justify="space-between">
						<Col sm={11} md={11} xs={24}>
							<Spin tip="Aguarde..." spinning={this.state.loading}>
								<UmkVagasList Vagas={this.state.vagas} />
							</Spin>
						</Col>
						<Col sm={11} md={11} xs={24}>
							{/*
							<h1>Login</h1>
							<h2>Cadastre seu currículo ou atualize seus dados</h2>
							<Divider />
							<UmkCurriculoLogin />
							*/}
							<h1>Possui interesse em fazer parte de nossa equipe?</h1>
							<h1>Envie seu currículo para o e-mail:</h1>
							<h2>rh@unimake.com.br</h2>
						</Col>
					</Row>
				</section>
			</div>
		);
	}
}
export default TrabalheConoscoPage;
