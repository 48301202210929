import ApiRequestService from './ApiRequestService';
import UnimakeApiService from './UnimakeApiService';

export default class DownloadService {
    private unimakeApi: UnimakeApiService;
    private path: string = '/free/Download';

    constructor() {
        this.unimakeApi = new UnimakeApiService();
    }

    public async ProdutoDownload(categoria: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.get(`${this.path}/ProdutoDownload?categoria=${categoria}`, {});

                resolve(response);
            }
            catch(error) {                
                reject(error);
            }
        });
    }

    public async GetDownload(id: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.get(`${this.path}/GetDownload?id=${id}`, {});

                resolve(response);
            }
            catch(error) {
                reject(error);
            }
        });
    }


    public async GetServers(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.get(`${this.path}/GetServers`, {});

                resolve(response);
            }
            catch(error) {
                reject(error);
            }
        });
    }

    public async FinalDownload(server: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.get(`${this.path}/GetFinalDownload?idserver=${server}`, {});

                resolve(response);
            }
            catch(error) {
                reject(error);
            }
        });
    }

    public async UpdateCounter(id: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.post(`${this.path}/UpdateCounter`, { id: id });

                resolve(response);
            }
            catch(error) {
                reject(error);
            }
        });
    }
}