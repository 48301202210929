import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { comprarProduto } from '../../utils/CarrinhoUtil';

const AdicionarAoCarrinho: React.FC = (props) => {
  const { id, quantidade } = useParams<{ id: string, quantidade: string }>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const adicionarAoCarrinho = async () => {
      try {
        const idProd = parseInt(id, 10);
        const qtde = parseInt(quantidade, 10);
        const clienteId = null;

        await comprarProduto(
          idProd,
          qtde,
          clienteId,
          setLoading,
          (message) => alert(message),
          (message) => alert(message),
          props
        );

        window.location.href = '/loja/carrinho';
      } catch (error) {
        console.error(error);
      }
    };

    adicionarAoCarrinho();
  }, [id, quantidade, props]);

  return null;
};

export default AdicionarAoCarrinho;
