import React, { Component } from 'react';
import "./Clientes.scss";
import Image1 from '../../assets/img/clientes/webp/jandiraalimentos.webp';
import Image2 from '../../assets/img/clientes/webp/mariamacia.webp';
import Image3 from '../../assets/img/clientes/webp/fadamadrinha.webp';
import Image4 from '../../assets/img/clientes/webp/flordelotus.webp';
import Image5 from '../../assets/img/clientes/webp/citriagro.webp';
import Image6 from '../../assets/img/clientes/webp/batentesparanavai.webp';
import Image7 from '../../assets/img/clientes/webp/capitaltecno.webp';
import Image8 from '../../assets/img/clientes/webp/capel.webp';
import Image9 from '../../assets/img/clientes/webp/paranaenseamidos.webp';
import Image10 from '../../assets/img/clientes/webp/paranasupermercados.webp';
import Image11 from '../../assets/img/clientes/webp/rudolfsizing.webp';
import Image12 from '../../assets/img/clientes/webp/moveisfelber.webp';
import Image13 from '../../assets/img/clientes/webp/marinapark.webp';
import Image14 from '../../assets/img/clientes/webp/dk.webp';
import Image15 from '../../assets/img/clientes/webp/dhlvaltra.webp';
import Image16 from '../../assets/img/clientes/webp/redieselpecas.webp';

const images = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12,
    Image13,
    Image14,
    Image15,
    Image16,
];

class Clientes extends React.Component<any, any> {
    componentDidMount() {
        // Obtém a referência para o elemento com a classe "img-container"
        const container = document.querySelector('.img-container');

        // Verifica se o container existe antes de continuar
        if (container) {
            // Itera sobre o array de imagens
            images.forEach((imagePath) => {
                // Cria um elemento de imagem
                const imgElement = document.createElement('img');

                // Define o atributo src com o caminho da imagem
                imgElement.src = imagePath;

                // Adiciona o elemento de imagem ao contêiner
                container.appendChild(imgElement);
            });
        }
    }

    render() {
        return (
            <div>
                <div className="clientes-container">
                    <h1>Conheça Alguns de Nossos Clientes</h1>
                    <div className="img-container">
                        {/* {images.map((imageUrl, index) => (
                            <img key={index} src={imageUrl} alt={`Imagem ${index + 1}`} />
                        ))} */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Clientes;