import React from "react";
import { Button, Row, Col, Typography, Divider } from "antd";
import UmkClienteLogin from "../../components/forms/unimake-cliente-login/UmkClienteLogin";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

const { Title, Paragraph } = Typography;

class LoginClientePage extends React.Component<any, any> {
	render() {
		return (
			<div>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Unimake Software - Login</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/cliente/login`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="A Unimake Software é uma empresa especializada no ramo de soluções para ERP, DANFE, Nota Fiscal Eletrônica (NF-e), Nota Fiscal de Serviço Eletrônica (NFS-e), Conhecimento de Transporte Eletrônico (CT-e) com os produtos Unico ERP, UniDANFE, DANFE View, UniNF-e (NF-e), Consult-e (NF-e, CT-e), UniCT-e (CT-e), UniNFS-e (NFS-e), acesse e conheça nossos produtos." />
				</Helmet>

				<section className="unimake-container unimake-content">
					<Row justify="space-between">
						<Col sm={11} md={11} xs={24}>
							<Title level={4}>Ainda não tenho cadastro</Title>
							<Divider />
							<Paragraph>
								Nesta área você pode: <br />
								<br />
								<ul>
									<li>Comprar, consultar e renovar licenças.</li>
									<li>Baixar as NF e consultar detalhes de suas compras.</li>
									<li>Alterar dados cadastrais, entre outras.</li>
								</ul>
								<br />
							</Paragraph>
							<Link to={`${process.env.PUBLIC_URL}/cliente/cadastro`}>
								<Button block shape="round" type="primary">
									Cadastrar
								</Button>
							</Link>
						</Col>
						<Col sm={11} md={11} xs={24}>
							<Title level={4}>Acesso a área do cliente</Title>
							<Divider />
							<UmkClienteLogin />
						</Col>
					</Row>
				</section>
			</div>
		);
	}
}
export default LoginClientePage;
