import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './Cookies.scss';

const CookieBanner: React.FC = () => {
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(false);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      setIsBannerVisible(true);
    }
  }, []);

  const acceptCookies = (): void => {
    Cookies.set('cookieConsent', 'true', { expires: 365 });
    setIsBannerVisible(false);
  };

  if (!isBannerVisible) return null;

  return (
    <div className="banner">
      <p className="title">
        Consentimento de Cookies
      </p>
      <p className="text">
        Este site utiliza cookies para melhorar sua experiência. Ao continuar navegando, você aceita nossa{' '}
        <a href="/lgpd" className="link">Política de Privacidade</a>.
      </p>
      <button onClick={acceptCookies} className="button">
        Prosseguir
      </button>
    </div>
  );
};

export default CookieBanner;
