import React from "react";
import CookieUtil from "./../../utils/CookieUtil";
import { Redirect } from "react-router-dom";
import UmkClienteProfile from "./../../components/cliente/UmkClienteProfile";
import {Helmet} from "react-helmet";

class ProfileClientePage extends React.Component<any, any> {
	private CookieUtil: CookieUtil;

	state = {
		redirect: false,
		redirectPage: "",
	};

	constructor(props: any) {
		super(props);

		this.CookieUtil = new CookieUtil();
		this.checkLogin = this.checkLogin.bind(this);
	}

	componentDidMount() {
		this.checkLogin();
	}

	async checkLogin() {
		let token = await this.CookieUtil.Get("TokenUsuario");

		if (!token || token === "undefined") {
			localStorage.setItem("redirect", "true");
			localStorage.setItem("redirectPath", window.location.pathname);

			this.setState({
				redirect: true,
				redirectPage: `${process.env.PUBLIC_URL}/cliente/login`,
			});
		}
	}

	render() {
		if (this.state.redirect) {
			return <Redirect push to={this.state.redirectPage} />;
		}

		return (
			<>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Unimake Software - Perfil</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="A Unimake Software é uma empresa especializada no ramo de soluções para ERP, DANFE, Nota Fiscal Eletrônica (NF-e), Nota Fiscal de Serviço Eletrônica (NFS-e), Conhecimento de Transporte Eletrônico (CT-e) com os produtos Unico ERP, UniDANFE, DANFE View, UniNF-e (NF-e), Consult-e (NF-e, CT-e), UniCT-e (CT-e), UniNFS-e (NFS-e), acesse e conheça nossos produtos." />
				</Helmet>

				<section className="unimake-container">
					<UmkClienteProfile />
				</section>
			</>
		);
	}
}
export default ProfileClientePage;
