import IResponse from "../contract/IResponse";
import PedidoService from "../services/Loja/PedidoService";
import CookieUtil from "./CookieUtil";
import { updateQuantity } from "../actions/CartActions";
import ModalUltil from "./ModalUtil";

export const comprarProduto = async (
    produtoId: number,
    quantidade: number,
    clienteId: number | null,
    setLoading: (loading: boolean) => void,
    showSuccess: (message: string) => void,
    showError: (message: string) => void,
    props: any
) => {
    const pedidoService = new PedidoService();
    const modalUtil = new ModalUltil(props);
    const cookieUtil = new CookieUtil();

    try {
        setLoading(true);
        const response = await pedidoService.Comprar(produtoId, quantidade, clienteId);
        const result: IResponse = response.data;

        setLoading(false);
        if (result.success) {
            const idPedido = result.data.IDPedido;
            cookieUtil.Set("OrderID", idPedido);
            localStorage.setItem("OrderID", idPedido);

            updateQuantity(quantidade);
        } else {
            modalUtil.Exception(result.message ? response.message : "Houve um erro ao adicionar o produto");
        }
    } catch (err) {
        setLoading(false);
		modalUtil.Exception(err);
    }
};
