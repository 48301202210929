export default class ProdutoUtil {
    getIcon(id) {
        switch (id) {
            case 3:
                return require("../assets/img/products/webp/danfe_icon.webp");

            case 2:
            case 14:
                return require("../assets/img/products/webp/danfeview_icon.webp");

            default:
                break;
        }
    }

    //TODO: Alterar diretorio do contrato como configuração
    getContratoHtml(id) {
        switch (id) {
            case 14:
                return '<a target="_blank" style="color: #0F8BD3;" href="http://www.unimake.com.br/loja/contratos/licenca_danfeview.pdf"><b>(Por instalação)</b></a>';

            case 3:
                return '<a target="_blank" style="color: #0F8BD3;" href="http://www.unimake.com.br/loja/contratos/licenca_unidanfe.pdf"><b>(Por CNPJ)</b></a>';

            case 2:
                return '<a target="_blank" style="color: #0F8BD3;" href="http://www.unimake.com.br/loja/contratos/licenca_danfeview.pdf"><b>(Por CNPJ)</b></a>';

            default:
                return "";
        }
    }
}
