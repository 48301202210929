import React from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import UmkAlterarSenha from '../../components/unimake-alterarsenha/UmkAlterarSenha';
import {Helmet} from "react-helmet";

const { Title } = Typography;

class AlterarSenhaClientePage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Unimake Software - Cliente - Alterar Senha</title>
                    <link rel="canonical" href={`${process.env.PUBLIC_URL}/cliente/alterar-senha`} />
                    <meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
                    <meta name="description" content="A Unimake Software é uma empresa especializada no ramo de soluções para ERP, DANFE, Nota Fiscal Eletrônica (NF-e), Nota Fiscal de Serviço Eletrônica (NFS-e), Conhecimento de Transporte Eletrônico (CT-e) com os produtos Unico ERP, UniDANFE, DANFE View, UniNF-e (NF-e), Consult-e (NF-e, CT-e), UniCT-e (CT-e), UniNFS-e (NFS-e), acesse e conheça nossos produtos." />
                </Helmet>

                <section className="unimake-container">
                    <div className="content-area">
                        <Row>
                            <Col sm={24} md={24} xs={24}>
                                <div className="content-area">
                                    <Title level={4}>Acesso do Cliente - Nova Senha</Title>
                                    <Title level={5}>Informe sua nova senha</Title>
                                    <Divider />
                                    <UmkAlterarSenha tipo="cliente" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        );
    }
}
export default AlterarSenhaClientePage;
