import React from "react";
import LoadingOverlay from "react-loading-overlay";
import NotificationUtil from "../../../utils/NotificationUtil";
import LicencaService from '../../../services/LicencaService';
import ModalUltil from "../../../utils/ModalUtil";
import {Redirect} from "react-router-dom";
import {ILicencaValidate} from '../../../contract/licenca/ILicencaValidate';
import IApiResponse from "../../../contract/IApiResponse";
import {IUsuario} from "../../../contract/IUsuario";
import StorageService from "../../../services/StorageService";
import {Button, Card, Col, Divider, Form, Input, Radio, Row, Typography} from "antd";
import {FormInstance} from 'antd/lib/form';
import {UserOutlined} from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import IResponse from "../../../contract/IResponse";
import CpfCnpj from "../../../hooks/CpfCnpj";
import MaskedInput from "antd-mask-input";

const {Title, Paragraph, Text} = Typography;

//TODO: Melhorar o campo CPF/CNPJ, incluir validações no backend e frontendt e Analisar a validação da licença antes de carregar a tela licenda/licenca_editar.php
class UmkLicencaEditar extends React.Component<any, any> {
    formRef = React.createRef<FormInstance>();

    private NotificationUtil: NotificationUtil;
    private ModalUltil: ModalUltil;
    private LicencaService: LicencaService;
    private storage: StorageService;

    state = {
        loading: false,
        redirect: false,
        redirectPage: '',
        licencaDetails: null,
        idlicenca: null,
        cpfcnpj: '',
        razaosocial: '',
        email: '',
        modalConfirmar: false,
        tipoPessoa: 1,
        tipoMascara: undefined
    };

    constructor(props: any) {
        super(props);

        this.NotificationUtil = new NotificationUtil();
        this.ModalUltil = new ModalUltil(props);
        this.LicencaService = new LicencaService();
        this.storage = new StorageService();

        this.initialize = this.initialize.bind(this);
        this.getParameters = this.getParameters.bind(this);
        this.validateLicense = this.validateLicense.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.editarLicenca = this.editarLicenca.bind(this);
        this.cancelar = this.cancelar.bind(this);
        this.tipoPessoaChange = this.tipoPessoaChange.bind(this);
    }

    componentDidMount() {
        this.initialize();
    }

    initialize(): void {
        this.getParameters();
    }

    async validateLicense(licenca: number) {
        try {
            this.setState({loading: true});
            //TODO: Este storage deve ser alterado para cookie, pelo menos para o gerenciamento de dados do usuario
            let usuario: IUsuario = JSON.parse(this.storage.Get("Usuario"));

            //TODO: Alterar no backend para carregar da autenticação
            let result = await this.LicencaService.validateLicenca({
                idcliente: usuario.idcliente,
                idlicenca: licenca
            });

            this.setState({loading: false});

            let response: IApiResponse<ILicencaValidate> = result.data;

            if (!response.success) {
                throw new Error(response.message);
            }

            this.formRef.current.setFieldsValue({
                cpfcnpj: response.data.cnpj,
                confcpfcnpj: response.data.cnpj,
                razaosocial: response.data.razao_social,
                email: response.data.email
            });

            this.setState({
                idlicenca: licenca,
                licencaDetails: response.data,
            });
        } catch (error) {
            this.setState({loading: false});
            this.ModalUltil.Error(error.message);
            this.setState({
                redirect: true,
                redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`,
            });
        }
    }

    getParameters(): void {
        try {
            let url_string = window.location.href;
            let url = new URL(url_string);

            let lic = url.searchParams.get("idlicenca");

            if (!lic || lic == '0')
                throw new Error("Licença inválida");

            this.validateLicense(parseInt(lic));
        } catch (error) {
            this.ModalUltil.Error(error.message);
            this.setState({
                redirect: true,
                redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`,
            });
        }
    }

    handleSubmit = (values) => {
        this.setState({
            cpfcnpj: values.cpfcnpj,
            razaosocial: values.razaosocial,
            email: values.email,
            modalConfirmar: true
        });
    };

    async editarLicenca(): Promise<void> {
        try {
            this.setState({loading: true});

            let result = await this.LicencaService.editarLicenca({
                idlicenca: this.state.idlicenca,
                cpfcnpj: this.state.cpfcnpj,
                razaosocial: this.state.razaosocial,
                email: this.state.email
            });
            let response: IResponse = result.data;

            if (response.success) {
                this.NotificationUtil.Success("Licença alterada com sucesso!");

                this.setState({
                    redirect: true,
                    redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`,
                });
            } else {
                throw new Error(response.message);
            }

            this.setState({loading: false});
        } catch (error) {
            this.setState({loading: false});
            this.ModalUltil.Error(error.message);
        }
    }

    cancelar(): void {
        this.setState({ modalConfirmar: false });
    }

    tipoPessoaChange(e: any) {
        this.setState({
            tipoPessoa: e.target.value,
            tipoMascara: e.target.value == 1 ? "cnpj" : "cpf"
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={this.state.redirectPage}/>;
        }

        let licenca: ILicencaValidate = this.state.licencaDetails;
        let idlicenca = this.state.idlicenca;
        let title = `Editar Licença » ${idlicenca} - ${licenca?.nome_produto}`;
        let captionCpfCnpj = this.state.tipoPessoa == 1 ? "CNPJ" : "CPF";
        let tipoMascara = this.state.tipoMascara;

        return (
            <div>
                <Modal
                    className="modal-faturamento"
                    visible={this.state.modalConfirmar}
                    title="Confirmação para salvar os dados da licença"
                    onOk={this.editarLicenca}
                    onCancel={this.cancelar}
                    centered
                    width="40rem"
                    footer={[
                        <Button key="back" onClick={this.cancelar}>
                            Cancelar
                        </Button>,
                        <Button
                            key="submit"
                            className="unimake-green-button"
                            type="primary"
                            loading={this.state.loading}
                            onClick={this.editarLicenca}>
                            Salvar
                        </Button>,
                    ]}>
                    <Card>
                        <Paragraph style={{fontSize: "medium"}}>
                            <span>CNPJ: <span style={{fontWeight: 'bold'}}>{this.state.cpfcnpj}</span></span>
                            <br/>
                            <span>Razão Social: <span
                                style={{fontWeight: 'bold'}}>{this.state.razaosocial}</span></span> <br/>

                            <div hidden={!this.state.email}>
                                <span >Email: <span
                                    style={{fontWeight: 'bold'}}>{this.state.email}</span></span>
                                <br/>
                            </div>

                            Se os dados estiverem corretos clique em Salvar.<br/>
                            Para corrigir clique em Cancelar.
                        </Paragraph>
                    </Card>
                </Modal>

                <LoadingOverlay active={this.state.loading} spinner text="Carregando dados...">
                    <Card title={title}>
                        <Paragraph>Informe os dados da empresa/pessoa para a qual a licença será gerada.</Paragraph>
                        <Divider/>
                        <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
                            <Row>
                                <Form.Item label="Tipo de Pessoa">
                                    <Radio.Group onChange={this.tipoPessoaChange} value={this.state.tipoPessoa}>
                                        <Radio value={1}>Pessoa Jurídica</Radio>
                                        <Radio value={0}>Pessoa Física</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Row>

                            <Row>
                                <Col xs={12} sm={12} md={12}>
                                    <Form.Item className="form-item-umk" label={`${captionCpfCnpj} Licenciado:`} name="cpfcnpj"
                                               rules={[
                                                   {required: true, message: `Informe o ${captionCpfCnpj}!`},
                                               ]}>
                                        {this.state.tipoPessoa == 0 ? ( <MaskedInput mask="000.000.000-00" /> ) : ( <MaskedInput mask="00.000.000/0000-00" />)}
                                    </Form.Item>
                                    <Form.Item className="form-item-umk" label={`Repita o ${captionCpfCnpj}:`}
                                               name="confcpfcnpj"
                                               rules={[
                                                   {required: true, message: `Repita o ${captionCpfCnpj}!`},
                                                   ({getFieldValue}) => ({
                                                       validator(_, value) {
                                                           if (!value || getFieldValue("cpfcnpj") === value) {
                                                               return Promise.resolve();
                                                           }
                                                           return Promise.reject(new Error(`O ${captionCpfCnpj} não são iguais!`));
                                                       },
                                                   }),
                                               ]}
                                               dependencies={["cpfcnpj"]}
                                    >
                                        {this.state.tipoPessoa == 0 ? ( <MaskedInput mask="000.000.000-00" /> ) : ( <MaskedInput mask="00.000.000/0000-00" />)}
                                    </Form.Item>

                                    <Form.Item className="form-item-umk" label="Razão Social:" name="razaosocial"
                                               rules={[
                                                   {required: true, message: 'Informe a Razão Social!'},
                                                   { min: 3, message: "Mínimo 5 caracteres" },
                                                   { max: 100, message: "Máximo 100 caracteres" },
                                               ]}>
                                        <Input type="text"/>
                                    </Form.Item>

                                    <Form.Item className="form-item-umk" label="E-mail adicional para o envio da Licença:" name="email">
                                        <Input type="email" placeholder="Digite um e-mail diferente dos informados em seu cadastro ou deixe em branco"/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/*TODO: Campo checkbox necssario confirmacao. licenca_editar.php ln: 338*/}
                            <br/>

                            <Row>
                                <Col sm={5} md={5}>
                                    <Form.Item>
                                        <Button type="primary" block htmlType="submit" className="login-form-button">
                                            Salvar
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col sm={1} md={1}/>

                                <Col sm={5} md={5}>
                                    <Form.Item>
                                        <Button type="primary" block className="login-form-button" onClick={() => {this.formRef.current.resetFields()}}>
                                            Limpar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>

                        <Paragraph>
                            <span style={{color: 'red'}}>NOTAS:</span> <br/>

                            a) <span style={{color: 'red'}}>As licenças são intransferíveis</span>. Assim sendo, após
                            geradas não é possível promover qualquer alteração nelas. Por essa razão confira o CNPJ
                            digitado antes de clicar em "salvar".<br/>

                            b) Nosso servidor gera os arquivos com as licenças à cada meia hora. A licença informada
                            nessa tela pode ser alterada ou removida até que seja gerada e enviada pelo nosso servidor.
                            Após essa geração não será possível qualquer operação com ela.<br/>

                            c) Para remover uma licença cadastrada anteriormente e não gerada ainda, basta limpar os
                            campos cnpj e razão social da mesma.<br/>

                            d) Ao gerar o arquivo com a licença, um e-mail será expedido para os endereços informados em
                            seu cadastro com a licença em anexo, que também estará disponível para download nesse
                            site.<br/>

                            e) Autorize <span style={{color: 'red'}}>imediatamente</span> o recebimento de e-mails
                            provindos do domínio unimake.com.br para evitar que o e-mail com a licença vá parar em
                            caixas de lixo eletrônico ou spam.
                        </Paragraph>
                    </Card>
                </LoadingOverlay>
            </div>
        );
    }
}

export default UmkLicencaEditar;
