import React from 'react';
import './UmkGoTop.scss';
import { BackTop } from 'antd';

class UmkGoTop extends React.Component<any, any> {
    render() {
        return (
            <div className="go-top">
                <BackTop />
            </div>
        );
    }
}
export default UmkGoTop;