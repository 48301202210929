import dispatcher from '../dispatcher/Dispatcher';

export const CART_ACTIONS = {
    CHANGE_QUANTITY: 'cartActions.ChangeQuantity',
    UPDATE_QUANTITY: 'cartActions.UpdateQuantity',
    UPDATE_NAME: 'cartActions.UpdateName'
};

export function changeQuantity(newQuantity) {
    dispatcher.dispatch({
        type: CART_ACTIONS.CHANGE_QUANTITY,
        value: newQuantity
    });
}
export function updateQuantity(appendQuantity) {
    dispatcher.dispatch({
        type: CART_ACTIONS.UPDATE_QUANTITY,
        value: appendQuantity
    });
}

export function updateNameClient(name) {
    dispatcher.dispatch({
        type: CART_ACTIONS.UPDATE_NAME,
        value: name
    });
}