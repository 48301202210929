import UnimakeApiService from "./UnimakeApiService";

export default class NFeService {
    private unimakeApi: UnimakeApiService;
    private path: string = '/Loja/NFe';

    constructor() {
        this.unimakeApi = new UnimakeApiService();
    }

    public downloadLink(chaveacesso: string, extensao: string): string {
        let token = localStorage.getItem('TokenUsuario');
        let endpoint = this.unimakeApi.endpoint;
        return `${endpoint}${this.path}/Download?chaveacesso=${chaveacesso}&extensao=${extensao}&token=${token}`;
    }
}
