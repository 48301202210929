import UnimakeApiService from '../UnimakeApiService';
import ApiRequestService from './../ApiRequestService';

export default class ProdutoService {
    private unimakeApi: UnimakeApiService;
    private path: string = '/free/Loja/Produto';

    constructor() {
        this.unimakeApi = new UnimakeApiService();
    }

    /**
     * Retorna o preço do produto
     * @param ProdutoId ID do Produto
     */
    public async GetPreco(ProdutoId: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {                
                let response = await this.unimakeApi.get(`${this.path}/GetPreco?idproduto=${ProdutoId}`, {});

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
}