import React from 'react';
import {
    Form, Input, Button, Checkbox, Spin, Row, Col,
} from 'antd';
import IResponse from '../../contract/IResponse';

import { Redirect } from 'react-router-dom';

import NotificationUtil from '../../utils/NotificationUtil';
import { UserOutlined } from '@ant-design/icons';
import ModalUltil from '../../utils/ModalUtil';
import TrabalheConoscoService from '../../services/TrabalheConoscoService';
import ClienteService from '../../services/ClienteService';
import CookieUtil from '../../utils/CookieUtil';
import AuthenticationService from '../../services/AuthenticationService';

class UmkAlterarSenha extends React.Component<any, any> {
    static defaultProps = {
        tipo: 'cliente'
    }

    state = {
        loading: false,
        redirect: false,
        redirectPage: '',
    }

    private NotificationService: NotificationUtil;
    private ModalUltil: ModalUltil;
    private TrabalheConoscoService: TrabalheConoscoService;
    private ClienteService: ClienteService;
    private CookieUtil: CookieUtil;
    private AuthenticationService: AuthenticationService;

    constructor(props: any) {
        super(props);

        this.TrabalheConoscoService = new TrabalheConoscoService();
        this.ClienteService = new ClienteService();
        this.NotificationService = new NotificationUtil();
        this.ModalUltil = new ModalUltil(props);
        this.AuthenticationService = new AuthenticationService();
        this.CookieUtil = new CookieUtil();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkLogin = this.checkLogin.bind(this);
        this.validateToken = this.validateToken.bind(this);
    }

    componentDidMount() {
        this.checkLogin();
    }

    async checkLogin() {
        if (this.props.tipo != "cliente-auth") return;

        let token = await this.CookieUtil.Get("TokenUsuario");

        if (!token || token === "undefined") {
            localStorage.setItem("redirect", "true");
            localStorage.setItem("redirectPath", window.location.pathname);

            this.setState({
                redirect: true,
                redirectPage: `${process.env.PUBLIC_URL}/cliente/login`,
            });
        }

        this.validateToken();
    }

    async validateToken() {
        try {
            this.setState({ loading: true });

            let result = await this.AuthenticationService.ValidateToken();

            this.setState({ loading: false });

            let response: IResponse = result.data;

            if (!response.success) {
                this.NotificationService.Error('Acesso Expirado');
                this.setState({
                    redirect: true,
                    redirectPage: `${process.env.PUBLIC_URL}/cliente/login`,
                });
            }
        } catch (error) {
            this.setState({ loading: false })
            this.ModalUltil.Exception(error);
        }
    }

    async handleSubmit(values: any) {
        try {
            let url_string = window.location.href;
            let url = new URL(url_string);

            let result = undefined;

            this.setState({ loading: true });

            if (this.props.tipo == 'trabalhe') {
                let id = url.searchParams.get("id");
                let iddt = url.searchParams.get("iddt");

                let body = {
                    senha: values.senha,
                    id: id,
                    iddt: iddt
                }

                result = await this.TrabalheConoscoService.ChangePassword(body);

                this.setState({
                    redirectPage: `${process.env.PUBLIC_URL}/trabalhe-conosco`
                });
            } else if (this.props.tipo == 'cliente') {
                let c = url.searchParams.get("c");
                let body = {
                    senha: values.senha,
                    codigo: c
                }

                result = await this.ClienteService.ChangePassword(body);

                this.setState({
                    redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`
                });
            } else if (this.props.tipo == 'cliente-auth') {
                let body = {
                    senha: values.senha,
                }

                result = await this.ClienteService.ChangePasswordAuth(body);

                this.setState({
                    redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`
                });
            }

            this.setState({ loading: false });

            let response: IResponse = result?.data;

            if (response.success) {
                this.NotificationService.Success('Senha Atualizada');
                this.setState({
                    redirect: true
                });
            } else {
                this.ModalUltil.Exception(response.message);
            }

        } catch (error) {
            this.setState({ loading: false })
            this.ModalUltil.Exception(error);
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={this.state.redirectPage} />;
        }

        return (
            <div>
                <div>
                    <Spin tip="Aguarde..." spinning={this.state.loading}>
                        <Form
                            name="control-ref"
                            onFinish={this.handleSubmit}
                            className="login-form" layout="vertical">

                            <Row>
                                <Col style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: 10, marginBottom: 10 }} sm={16} md={16} xs={24}>
                                    <Form.Item className="form-item-umk" label="Nova Senha" name="senha" rules={[
                                        { required: true, message: 'Informe sua Nova Senha!' },
                                        { max: 50, message: "Informe uma senha com até 50 caracteres" },
                                        { min: 5, message: "Informe uma senha com no mínimo 5 caracteres" },
                                    ]}>
                                        <Input prefix={<UserOutlined />} type="password" placeholder="Informe sua Nova Senha" />
                                    </Form.Item>
                                    <Form.Item className="form-item-umk" label="Confirmar Senha" name="confsenha"
                                        rules={[
                                            { required: true, message: "O campo Confirmar Senha é obrigatório!" },
                                            { max: 50, message: "Informe uma senha com até 50 caracteres" },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("senha") === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error("As duas senhas não são iguais!"));
                                                },
                                            }),
                                        ]}
                                        dependencies={["senha"]}
                                    >
                                        <Input prefix={<UserOutlined />} type="password" placeholder="Confirme a Nova Senha" />
                                    </Form.Item>
                                    <Button type="primary" block htmlType="submit" className="login-form-button">
                                        Enviar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </div >
            </div>
        );


    }
}

export default UmkAlterarSenha;