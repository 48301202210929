
import UnimakeApiService from '../UnimakeApiService';
import ApiRequestService from './../ApiRequestService';

export default class PedidoService {
    private unimakeApi: UnimakeApiService;
    private path: string = '/free/Loja/Pedido';
    
    constructor() {
        this.unimakeApi = new UnimakeApiService();
    }

    /**     
     * Adiciona um item ao carrinho/cria o pedido
     * @param IdProduto Id do produto
     * @param IdCliente Id do cliente
     * @param Quantidade Quantidade a ser adicionada
     */
    public async Comprar(IdProduto: number, Quantidade: number, IdCliente?: number): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {            
                let idPedido = localStorage.getItem('OrderID');
                let body = {
                    'idpedido': idPedido,
                    'idproduto': IdProduto,
                    'idcliente': IdCliente,
                    'quantidade': Quantidade
                };

                let response = await this.unimakeApi.post(`${this.path}/CreateOrder`, body);

                resolve(response);
            }
            catch(error) {
                reject(error);
            }
        });
    }

    //!Possiveis campos: ticket, cupom, idrenova
    public async Fechar(IdPedido: any, IdRenova: any, IdCliente: number, EmailFat: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {                        
                let body = {
                    'idpedido': IdPedido,
                    'idrenova': !IdRenova ? null : IdRenova,
                    'idcliente': IdCliente,
                    'email_fat': EmailFat
                };

                let response = await this.unimakeApi.post(`${this.path}/Fechar`, body, {});

                resolve(response);
            }
            catch(error) {
                reject(error);
            }
        });
    }
}
