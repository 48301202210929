import UnimakeApiService from '../UnimakeApiService';
import ApiRequestService from './../ApiRequestService';

export default class CartService {
    private OrderID: any;
    private unimakeApi: UnimakeApiService;
    private path: string = '/free/Loja/Pedido';

    constructor() {
        this.OrderID = localStorage.getItem('OrderID');
        this.unimakeApi = new UnimakeApiService();
    }

    /**
     *  Recupera os itens do carrinho no servidor
     */
    public async GetCart(auth: boolean = false): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let renovaId = localStorage.getItem("RenovacaoID");
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }

                if (this.OrderID) {
                    let response = await this.unimakeApi.get(
                        auth
                            ? `/Loja/Pedido/GetCart?idpedido=${this.OrderID}&renovaid=${renovaId}`
                            : `${this.path}/GetCart?idpedido=${this.OrderID}&renovaid=${renovaId}`,
                        headers
                    );

                    resolve(response);
                }

            } catch (error) {
                reject(error);
            }
        });
    }


    public async SetQuantidade(quantidade: any, idproduto: any, auth: boolean = false): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }

                let response = await this.unimakeApi.post(
                    auth ? '/Loja/Pedido/RecalculaQuantidade' : `${this.path}/RecalculaQuantidade`,
                    {
                        idpedido: this.OrderID,
                        idproduto: idproduto,
                        quantidade: quantidade
                    },
                    headers
                );

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async SetAnuidade(anuidade: any, idproduto: any, auth: boolean = false): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                };

                let response = await this.unimakeApi.post(
                    auth ? `/Loja/Pedido/RecalculaAnuidade` : `${this.path}/RecalculaAnuidade`,
                    {
                        idpedido: this.OrderID,
                        idproduto: idproduto,
                        anuidade: anuidade
                    },
                    headers
                );

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async RemoveProduct(idproduto: any): Promise<any> {
        return new Promise(async (resolve, rejct) => {
            try {
                let response = await this.unimakeApi.post(`${this.path}/RemoveProduct`, {
                    idpedido: this.OrderID,
                    idproduto: idproduto,
                });

                resolve(response);
            } catch (error) {
                rejct(error);
            }
        });
    }

    public async FinalizeCompra(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.post(`${this.path}/Fechar`, {
                    idpedido: this.OrderID
                });

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

}
