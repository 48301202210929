import UnimakeApiService from "./UnimakeApiService";
import {ILicencaRenovacaoArgs} from "../contract/licenca/ILicencaRenovacaoArgs";

export default class LicencaService {
    private unimakeApi: UnimakeApiService;
    private path: string = '/Loja/Licenca';

    constructor() {
        this.unimakeApi = new UnimakeApiService();
    }

    public async validateLicenca(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.post(`${this.path}/ValidateLicencaByCliente`, body, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async validateDesativarLicenca(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.post(`${this.path}/ValidateDesativarLicenca`, body, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async desativarLicenca(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.post(`${this.path}/Desativar`, body, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async editarLicenca(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.post(`${this.path}/Editar`, body, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async validateRenovarLicenca(idpedido: any, renovacaoid: any, renovacaoArgs: ILicencaRenovacaoArgs): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                };

                let body = {
                    idpedido: !idpedido ? 0 : idpedido,
                    idrenova: renovacaoid,
                    renovacaoArgs: renovacaoArgs
                };

                let response = await this.unimakeApi.post(`${this.path}/ValidateRenovarLicenca`, body, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async getLicencas(idrenova, idpedido): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                };

                let response = await this.unimakeApi.get(`${this.path}/GetLicencasRenovar?idrenova=${idrenova}&idpedido=${idpedido}`, headers);
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async recalcular(idrenova, anuidades, idrenovai, salvaanuid, iditem): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                };

                let body = {
                    idrenova: idrenova,
                    anuidades: anuidades,
                    idrenovai: idrenovai,
                    salvaanuid: salvaanuid,
                    iditem: iditem
                };

                let response = await this.unimakeApi.post(`${this.path}/Renovar/Recalcular`, body, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async excluir(idrenova, idrenovai, idpedido): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                };

                let body = {
                    idrenova: idrenova,
                    idrenovai: idrenovai,
                    idpedido: idpedido,
                };

                let response = await this.unimakeApi.post(`${this.path}/Renovar/Excluir`, body, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async encerrar(idrenova): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                };

                let body = {
                    idrenova: idrenova
                };

                let response = await this.unimakeApi.post(`${this.path}/Renovar/Encerrar`, body, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
}
