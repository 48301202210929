import React from "react";
import { Card, Tabs } from "antd";
import CookieUtil from "./../../utils/CookieUtil";
import { Redirect } from "react-router-dom";

import UmkClienteLicencas from "./../../components/cliente/UmkClienteLicencas";
import UmkClienteBoletos from "./../../components/cliente/UmkClienteBoletos";
import UmkClienteNFe from "./../../components/cliente/UmkClienteNFe";
import UmkClienteCompras from "./../../components/cliente/UmkClienteCompras";
import IResponse from "../../contract/IResponse";
import NotificationUtil from "../../utils/NotificationUtil";
import ModalUltil from "../../utils/ModalUtil";
import AuthenticationService from '../../services/AuthenticationService';
import {Helmet} from "react-helmet";

const { TabPane } = Tabs;

class DashboardPage extends React.Component<any, any> {
	private CookieUtil: CookieUtil;
	private NotificationService: NotificationUtil;
    private ModalUltil: ModalUltil;
	private AuthenticationService: AuthenticationService;

	state = {
		redirect: false,
		redirectPage: "",
	};

	constructor(props: any) {
		super(props);

		this.CookieUtil = new CookieUtil();
		this.NotificationService = new NotificationUtil();
        this.ModalUltil = new ModalUltil(props);
		this.AuthenticationService = new AuthenticationService();

		this.checkLogin = this.checkLogin.bind(this);
		this.validateToken = this.validateToken.bind(this);
	}

	componentDidMount() {
		this.checkLogin();
	}

	async checkLogin() {
		let token = await this.CookieUtil.Get("TokenUsuario");

		if (!token || token === "undefined") {
			localStorage.setItem("redirect", "true");
			localStorage.setItem("redirectPath", window.location.pathname);

			this.setState({
				redirect: true,
				redirectPage: `${process.env.PUBLIC_URL}/cliente/login`,
			});
		}

		this.validateToken();
	}

	async validateToken() {
		try {
			this.setState({ loading: true });

			let result = await this.AuthenticationService.ValidateToken();
			
			this.setState({ loading: false });

            let response: IResponse = result.data;

            if (!response.success) {
                this.NotificationService.Error('Acesso Expirado');
                this.setState({
                    redirect: true,
					redirectPage: `${process.env.PUBLIC_URL}/cliente/login`,
                });
            } 
		} catch (error) {
			this.setState({ loading: false })
            this.ModalUltil.Exception(error);
		}
	}

	render() {
		if (this.state.redirect) {
			return <Redirect push to={this.state.redirectPage} />;
		}

		return (
			<div>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Unimake Software - Área do Cliente</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/cliente/dashboard`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="A Unimake Software é uma empresa especializada no ramo de soluções para ERP, DANFE, Nota Fiscal Eletrônica (NF-e), Nota Fiscal de Serviço Eletrônica (NFS-e), Conhecimento de Transporte Eletrônico (CT-e) com os produtos Unico ERP, UniDANFE, DANFE View, UniNF-e (NF-e), Consult-e (NF-e, CT-e), UniCT-e (CT-e), UniNFS-e (NFS-e), acesse e conheça nossos produtos." />
				</Helmet>

				<section className="unimake-container">
					<Card style={{ height: "auto" }} className="content-area">
						<Tabs tabPosition="top" defaultActiveKey="1">
							<TabPane tab="Licenças" key="1">
								<UmkClienteLicencas />
							</TabPane>
							<TabPane tab="Faturas" key="2">
								<UmkClienteBoletos />
							</TabPane>
							<TabPane tab="Notas Fiscais" key="3">
								<UmkClienteNFe />
							</TabPane>
							<TabPane tab="Minhas Compras" key="4">
								<UmkClienteCompras />
							</TabPane>
						</Tabs>
					</Card>
				</section>
			</div>
		);
	}
}

export default DashboardPage;