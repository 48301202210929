import {
	CheckCircleTwoTone,
	CloseCircleTwoTone,
	CloudDownloadOutlined,
	ClusterOutlined,
	CodeOutlined,
	MailOutlined,
	PrinterOutlined,
} from "@ant-design/icons";
import React from "react";
import { Row, Col, Table, Button } from "antd";
import ImageViewer from "react-simple-image-viewer";
import ContatoComponent from "../components/ContatoComponent";
import IResponse from "../../../contract/IResponse";
import { updateQuantity } from "../../../actions/CartActions";
import ProdutoService from "../../../services/Loja/ProdutoService";
import ClienteService from "../../../services/ClienteService";
import NotificationUtil from "../../../utils/NotificationUtil";
import PedidoService from "../../../services/Loja/PedidoService";
import ModalUltil from "../../../utils/ModalUtil";
import CookieUtil from "../../../utils/CookieUtil";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

const images = [require("../../../assets/img/products/unidanfe_image_full.webp")];
const dataSource = [
	{
		key: "1",
		carac: "Possibilita múltiplas configurações, permitindo maior flexibilidade na emissão do DANFE",
		free: true,
		plus: true,
	},
	{
		key: "2",
		carac: "Visualização do DANFE em tela antes de imprimir, com a opção de abandonar sem imprimir",
		free: true,
		plus: true,
	},
	{
		key: "3",
		carac: "Impressão do DANFE de forma manual - após a visualização - ou automática, sem a intervenção do operador do sistema ERP",
		free: true,
		plus: true,
	},
	{
		key: "4",
		carac: "Impressão do DANFE em múltiplas vias (cópias)",
		free: true,
		plus: true,
	},
	{
		key: "5",
		carac: "Impressão do DANFE em formato retrato e paisagem",
		free: true,
		plus: true,
	},
	{
		key: "6",
		carac: "Impressão do DANFE em formulário de segurança (contingência)",
		free: true,
		plus: true,
	},
	{
		key: "7",
		carac: "Envio do arquivo XML por e-mail, como anexo (manual e automático)",
		free: true,
		plus: true,
	},
	{
		key: "8",
		carac: "Envio do DANFE por e-mail, como anexo (manual e automático)",
		free: true,
		plus: true,
	},
	{
		key: "9",
		carac: "Formato do DANFE enviado por e-mail",
		free: "DNF(1)",
		plus: "PDF",
	},
	{
		key: "10",
		carac: "Personalização da linha de detalhe dos produtos da NF-e, com a inclusão de qualquer informação presente no XML (2)",
		free: false,
		plus: true,
	},
	{
		key: "11",
		carac: "Impressão das informações de rastreamento dos produtos da NF-e",
		free: false,
		plus: true,
	},
	{
		key: "12",
		carac: "Salvar o DANFE em formato PDF para uma pasta local ou da rede",
		free: false,
		plus: true,
	},
	{
		key: "13",
		carac: "Salvar o arquivo XML para uma pasta local ou da rede",
		free: false,
		plus: true,
	},
	{
		key: "14",
		carac: "Upload do DANFE em formato PDF para um servidor FTP",
		free: false,
		plus: true,
	},
	{
		key: "15",
		carac: "Upload do arquivo XML para um servidor FTP",
		free: false,
		plus: true,
	},
	{
		key: "16",
		carac: "Geração, impressão e distribuição do DACTE com todas as características do DANFE",
		free: true,
		plus: true,
	},
	{
		key: "17",
		carac: "Geração, impressão e distribuição do DAMDFE com todas as características do DANFE",
		free: true,
		plus: true,
	},
	{
		key: "18",
		carac: "Geração, impressão e distribuição do CF-e-SAT com todas as características do DANFE",
		free: true,
		plus: true,
	},
	{
		key: "19",
		carac: "Suporte via chat on line",
		free: true,
		plus: true,
	},
];
const columns = [
	{
		title: "Características Principais",
		dataIndex: "carac",
		key: "carac",
	},
	{
		title: "FREE",
		dataIndex: "free",
		key: "free",
		render: (value) => {
			if (typeof value === "string") {
				return <p className="text-center">{value}</p>;
			} else {
				return value == true ? (
					<CheckCircleTwoTone
						style={{ fontSize: 24, display: "block", marginLeft: "auto", marginRight: "auto" }}
						twoToneColor="#52c41a"
					/>
				) : (
					<CloseCircleTwoTone
						style={{ fontSize: 24, display: "block", marginLeft: "auto", marginRight: "auto" }}
						twoToneColor="#eb2f96"
					/>
				);
			}
		},
	},
	{
		title: "PLUS",
		dataIndex: "plus",
		key: "plus",
		render: (value) => {
			if (typeof value === "string") {
				return <p className="text-center">{value}</p>;
			} else {
				return value == true ? (
					<CheckCircleTwoTone
						style={{ fontSize: 24, display: "block", marginLeft: "auto", marginRight: "auto" }}
						twoToneColor="#52c41a"
					/>
				) : (
					<CloseCircleTwoTone
						style={{ fontSize: 24, display: "block", marginLeft: "auto", marginRight: "auto" }}
						twoToneColor="#eb2f96"
					/>
				);
			}
		},
	},
];
const unidanfeId = 3;

const jsonLdData = {
	'@context': 'http://schema.org',
	'@type': 'Product',
	name: "UniDANFE",
	image: "https://www.unimake.com.br/static/media/unidanfe.33be8e95.png",
	description: "Imprima e dispare DANFE's e XML's de NF-e, CT-e, CC-e e outros documentos de forma automática para seus clientes.",
	brand: {
		'@type': 'Brand',
		name: "Unimake",
	},
	"review": {
		"@type": "Review",
		"reviewRating": {
		  "@type": "Rating",
		  "ratingValue": "5"
		},
		"author": {
		  "@type": "Person",
		  "name": "Unimake"
		}
	}
};

class UniDANFEPage extends React.Component<any, any> {
	private ProdutoService: ProdutoService;
	private ClienteService: ClienteService;
	private PedidoService: PedidoService;

	private NotificationUtil: NotificationUtil;
	private ModalUltil: ModalUltil;
	private CookieUtil: CookieUtil;

	state = {
		UniDanfeLogo: require("../../../assets/img/products/danfe_icon.png"),
		loading: false,
		redirect: false,
		redirectPage: "",
		openedViewer: false,
		currentImage: 0,
		logoUnidanfe: require("../../../assets/img/products/topo/unidanfe.png"),
	};

	constructor(props: any) {
		super(props);

		this.ProdutoService = new ProdutoService();
		this.ClienteService = new ClienteService();
		this.PedidoService = new PedidoService();

		this.NotificationUtil = new NotificationUtil();
		this.ModalUltil = new ModalUltil(props);
		this.CookieUtil = new CookieUtil();

		this.comprar = this.comprar.bind(this);
		this.openImageViewer = this.openImageViewer.bind(this);
		this.closeImageViewer = this.closeImageViewer.bind(this);
	}


	openImageViewer(index) {
		this.setState({
			openedViewer: true,
			currentImage: index,
		});
	}

	closeImageViewer() {
		this.setState({
			openedViewer: false,
			currentImage: 0,
		});
	}

	async comprar() {
		try {
			let cliente = this.ClienteService.GetClienteLogado();
			this.setState({ loading: true });

			this.PedidoService.Comprar(unidanfeId, 1, cliente ? cliente.idcliente : null)
				.then((result) => {
					let response: IResponse = result.data;

					//TODO: Verificar sobre o segundo pedido
					if (response.success) {
						let id = response.data.IDPedido;

						this.CookieUtil.Set("OrderID", id);
						localStorage.setItem("OrderID", id);

						updateQuantity(1);

						this.ModalUltil.Success("Produto adicionado ao carrinho!");

						this.setState({
							redirect: true,
							redirectPage: `${process.env.PUBLIC_URL}/loja/carrinho`,
						});
					} else {
						this.ModalUltil.Exception(
							response.message ? response.message : "Houve um erro ao adicionar o produto"
						);
					}

					this.setState({ loading: false });
				})
				.catch((err) => {
					this.setState({ loading: false });
					this.ModalUltil.Exception(err);
				});
		} catch (err) {
			this.setState({ loading: false });
			this.ModalUltil.Exception(err);
		}
	}

	render() {
		if (this.state.redirect) {
			return <Redirect push to={this.state.redirectPage} />;
		}

		// @ts-ignore
		return (
			<>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Conheça o UniDANFE – Software de Geração | Unimake Software</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/produtos/unidanfe`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="Selecionamos as principais informações sobre o software UniDANFE, um software de geração, impressão e distribuição dos documentos auxiliares. Veja!" />
				</Helmet>

				<div className="topo-border-rounded color-unidanfe">
					<Row align="middle" justify="space-around">
						<Col
							xs={{ order: 2, span: 20 }}
							lg={{ order: 1, span: 10, offset: 2 }}
							xxl={{ order: 1, span: 7, offset: 5 }}>
							<h1 className="hidden-xs hidden-sm hidden-md">UniDANFE</h1>

							<p>Software para impressão e envio de XML's de Documentos Fiscais Eletrônicos</p>

							<p className="subtitulo">
								Imprima e dispare DANFE's e XML's de NF-e, <br />
								CT-e, CC-e e outros documentos de forma <br />
								automática para seus clientes.
							</p>
						</Col>
						<Col lg={{ order: 2, span: 10 }} xs={{ order: 1 }}>
							<img className="topo-produto" src={this.state.logoUnidanfe} alt="Unimake Logo UniDANFE"></img>
						</Col>
					</Row>
				</div>

				<section>
					<div className="unimake-container">
						<div className="content-area-product">
							<div className="pre-description-center">
								<p style={{ textAlign: "center", fontSize: 25, fontWeight: 550 }}>
									A inovação tecnológica caminha conjuntamente com o mercado de consumo, seja para os clientes ou para os fornecedores de produtos e serviços.
									Não há tempo a perder, deste modo, investir em ferramentas de <b>automação de processos	</b>e <a href="http://www.unimake.com.br/gemini/" target="_blank" style={{ color: "blue" }}>
										armazenamento de dados em nuvem</a> é essencial. Já conhece nosso software de geração, impressão e distribuição de documentos fiscais auxiliares? Confira!
								</p>
								<br />
							</div>


							<div className="featuresBox-unidanfe">
								<h2>UniDANFE: software de geração de documentos auxiliares fiscais!</h2>
								<p>Nos dias atuais as empresas não podem mais desperdiçar tempo produtivo com processos repetitivos que podem e devem ser otimizados e automatizados.
									A gestão eletrônica de dados presentes em documentos fiscais (<a href="http://www.unimake.com.br/produtos_uninfe.php" target="_blank" style={{ color: "blue" }}>Nota Fiscal Eletrônica - NF-e</a>)
									deve ser simplificada, por isso a Unimake Software oferece um sistema de geração, impressão e distribuição de alguns documentos ficais, sendo estes:
								</p>
								<ul>
									<li>Documento auxiliar da Nota Fiscal Eletrônica (NF-e);</li>
									<li>Documento auxiliar da Nota Fiscal de Consumidor Eletrônica (NFC-e);</li>
									<li>Documento auxiliar da Nota Fiscal de Consumidor Eletrônica (NFC-e);</li>
									<li>Documento auxiliar do Conhecimento Eletrônico de Transporte (CT-e);</li>
									<li>Documento auxiliar do Conhecimento Eletrônico de Documentos Fiscais Eletrônicos (MDF-e);</li>
									<li>Cupom Fiscal Eletrônico - SAT;</li>
									<li>Carta de Correção Eletrônica.</li>
								</ul>
								<p>O DANFE é a versão impressa da nota fiscal eletrônica e deve, obrigatoriamente, acompanhar todos os produtos e serviços comercializados.</p>

								<h2 style={{ fontSize: 25, fontWeight: 600 }}>Requisitos legais de emissão de documentos fiscais eletrônicos</h2>
								<p>Os documentos fiscais são onipresentes na rotina empresarial por fazerem parte das obrigações necessárias de sua atividade sob pena de cometimento de
									<a href="http://www.planalto.gov.br/ccivil_03/leis/l8137.htm" target="_blank" style={{ color: "blue" }}> crime fiscal</a>. A <b>legislação para essa matéria é estadual</b>,
									então, as informações sobre documentos fiscais eletrônicos podem ser encontradas na Secretaria da Fazenda de seu Estado.
								</p>
								<p>Justamente por serem criteriosamente regulados pelo governo, os documentos fiscais eletrônicos (desde 2008) devem seguir todas as exigências do
									fisco em todas as suas fases: <b>emissão, escrituração, impressão e armazenamento</b>. Estas são as informações que devem obrigatoriamente constar nela:
								</p>
								<ul>
									<li>Emitente da NF-e e DANFE;</li>
									<li>Série e numeração da nota fiscal;</li>
									<li>Data de emissão;</li>
									<li>A discriminação da mercadoria: quantidade, marca, tipo, modelo, espécie, qualidade e demais elementos que permitam sua perfeita identificação;</li>
									<li>Nome do comprador com seus dados pessoais;</li><li>valores, unitário e total, das mercadorias, outros valores cobrados a qualquer título e o total da operação;</li>
									<li>O nome, o endereço e os números de inscrição, estadual e no CNPJ, do impressor do documento, a data e a quantidade da impressão, o número de ordem
										do primeiro e do último documento impresso, a série e subsérie, e o número da Autorização de Impressão de Documentos Fiscais.</li>
									<li>O software da Unimake já é programado para preencher estas informações, então não se preocupe: invista em nosso sistema e esteja de acordo com a lei!</li>
								</ul>

								<h2 style={{ fontSize: 25, fontWeight: 600 }}>Integração de softwares</h2>
								<p>O software de gestão de documentos auxiliares à documentos eletrônicos (DANFE) podem ser <b>integrados a qualquer sistema </b> de faturamento ou gestão negocial do mercado,
									de modo que todos os setores da empresa poderão se comunicar. Temos dois softwares integrados de gestão,
									então faça sua escolha conforme sua atuação comercial:
								</p>
								<ul>
									<li><a href="http://www.unimake.com.br/produtos_unico_varejo.php" target="_blank" style={{ fontSize: 20, fontWeight: 500, color: "blue" }}>Único ERP para varejo e atacado</a>;</li>
									<li><a href="http://www.unimake.com.br/produtos_unico_fecularia.php" target="_blank" style={{ fontSize: 20, fontWeight: 500, color: "blue" }}>Único ERP para fecularias</a>.</li>
								</ul>

								<br />

								<p style={{ fontSize: 19, color: "#034563" }}>
									<b>Outras vantagens do UniDANFE</b>
								</p>
								<ul>
									<li><PrinterOutlined style={{ color: "#ff5c5c" }} /> Impressão automática do documentos fiscais, após a geração do documento.</li>
									<li><MailOutlined style={{ color: "#ff5c5c" }} /> Disparo automático do XML para o e-mail do cliente.</li>
									<li><ClusterOutlined style={{ color: "#ff5c5c" }} /> Customiza colunas do DANFE conforme a necessidade do emitente.</li>
									<li><CloudDownloadOutlined style={{ color: "#ff5c5c" }} /> Baixe e use a versão FREE, sem cadastro, sem contrato! Obtenha recursos poderosos com a versão PLUS.</li>
								</ul>

								<p>A <b>Unimake Software</b> facilita sua atuação negocial e ainda te deixa em consonância com a legislação vigente no país.
									Invista conosco em sistemas online de manuseio de informações empresariais!
								</p>
							</div>

							<div className="imageMiddle-unidanfe">
								<p style={{ textAlign: "center", fontSize: 22, fontWeight: 600 }}>Confira as diferenças entre as versões FREE e PLUS, no quadro abaixo:</p>
								<Table bordered={true} dataSource={dataSource} columns={columns} pagination={false} />
							</div>

							<div className="comoFunciona-unidanfe">

								<Row>
									<span style={{ fontSize: 18, fontWeight: 600 }}>
										No infográfico abaixo é possível verificar como funciona o fluxograma de processamento do UniDANFE:
									</span>
								</Row>
								<br />
								<Row>
									<span style={{ display: "block", marginLeft: "auto", marginRight: "auto", fontSize: 18 }}>Clique na imagem para expandir</span>
									<Col md={24} sm={24}>
										{images.map((src, index) => (
											<img
												src={src}
												onClick={() => this.openImageViewer(index)}
												width="50%"
												key={index}
												style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
												alt="Unimake UniDANFE"
											/>
										))}
									</Col>
								</Row>
							</div>
						</div>
					</div >

					<Row align="middle" justify="space-around" className="color-black-transparent">
						<Col xs={22} lg={16}>
							<br />

							<p style={{ color: "white", fontSize: 24, padding: 0 }}>
								Faça o download do Unidanfe e revolucione a emissão de Documentos Fiscais Eletrônicos na
								sua empresa
							</p>

							<Row justify="start">
								<Col xs={12} md={12} lg={8}>
									<Button
										style={{ background: "white", color: "black", border: "none" }}
										shape="round"
										size="large"
										type="primary">
										<a href="https://unidanfe.unimakesoftware.com.br/" target="_blank" rel="noopener noreferrer">Instalar edição FREE</a>
									</Button>
								</Col>
								<Col xs={12} md={12} lg={8}>
									<Button
										style={{ background: "#006600", color: "white", border: "none" }}
										shape="round"
										size="large"
										loading={this.state.loading}
										onClick={() => {
											this.comprar();
										}}
										type="primary">
										Comprar a edição PLUS
									</Button>
								</Col>
							</Row>

							<br />
						</Col>

						{/* <Col sm={8} md={8} lg={8}>
									TODO: Precisa de uma imagem de um computador aqui com o unidanfe rodando
								</Col> */}
					</Row>

					<ContatoComponent />
				</section >

				{
					this.state.openedViewer && (
						//@ts-ignore
						<ImageViewer src={images} currentIndex={this.state.currentImage} onClose={this.closeImageViewer} />
					)
				}

				<script type="application/ld+json">
					{JSON.stringify(jsonLdData, null, 2)}
				</script>
			</>
		);
	}
}

export default UniDANFEPage;