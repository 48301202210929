import React from "react";
import { Row, Col } from "antd";
import LazyLoad from "react-lazyload";
import { Helmet } from "react-helmet";
import ContatoComponent from "../components/ContatoComponent";

const jsonLdData = {
	'@context': 'http://schema.org',
	'@type': 'Product',
	name: "Unico Materiais Elétricos",
	image: "https://www.unimake.com.br/static/media/unico-materiais-eletricos.ccffe545.webp",
	description: "Controle de seu estoque até seu faturamento com o software integrado de gestão empresarial focado em seu negócio.",
	brand: {
		'@type': 'Brand',
		name: "Unimake",
	},
	"review": {
		"@type": "Review",
		"reviewRating": {
		  "@type": "Rating",
		  "ratingValue": "5"
		},
		"author": {
		  "@type": "Person",
		  "name": "Unimake"
		}
	}
};

class UnicoMaterialEletricoPage extends React.Component<any, any> {
	state = {
		logoUnicoEletricos: require("../../../assets/img/products/webp/topo/unico-materiais-eletricos.webp"),
	};

	constructor(props: any) {
		super(props);
	}


	render() {
		return (
			<>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Conheça o Único ERP – Materiais Elétricos | Unimake Software</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/produtos/unico-material-eletrico`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="Clique e conheça mais informações sobre o Único ERP, com foco em materiais elétricos e diferenciais que fazem diferença no dia a dia de sua empresa." />
				</Helmet>

				<div className="topo-border-rounded color-materiais-eletricos">
					<Row align="middle" justify="space-around">
						<Col
							lg={{ order: 1, span: 10, offset: 2 }}
							xs={{ order: 2, span: 20 }}
							xxl={{ order: 1, span: 7, offset: 5 }}>
							<h1 className="hidden-xs hidden-sm hidden-md">Único ERP </h1>
							<h2 className="hidden-xs hidden-sm hidden-md">Materiais Elétricos</h2>

							<p className="subtitulo">A organização que fará sua empresa crescer sem limites!</p>
						</Col>
						<Col lg={{ order: 2, span: 10 }} xs={{ order: 1 }}>
							<img className="topo-produto" src={this.state.logoUnicoEletricos} alt="Unimake Unico Materiais Elétricos"></img>
						</Col>
					</Row>
				</div>

				<section>
					<div className="unimake-container">
						<div className="content-area-product">
							<div className="pre-description-center">
								<h1 style={{ fontSize: 32, fontWeight: 700 }}>
									ERP para Materiais Elétricos é com a Unimake Software
								</h1>

								<p style={{ fontSize: 20, fontWeight: 350 }}>Não é novidade que o mercado de materiais elétricos está cada vez mais aquecido e, é claro, super receptivo às novidades e inovações que possam ajudá-lo a ser mais seguro, otimizado e com minimização real de gastos.</p>
								<p style={{ fontSize: 20, fontWeight: 350 }}>Sua empresa trabalha com a fabricação e/ou a distribuição de materiais elétricos? Então vale a pena conhecer nosso Único ERP, software integrado de gestão para Materiais Elétricos. Tenha todos os processos do seu negócio sob controle!</p>

								<h2 style={{ fontSize: 28, fontWeight: 700 }}>Rastreabilidade e rentabilidade que as soluções da Unimake oferecem!</h2>
								<p style={{ fontSize: 20, fontWeight: 350 }}>O número de consumidores cresce em ritmo desenfreado, e com isso – e a globalização –, o fomento do consumo e da produção também é acelerado. No segmento de materiais elétricos isso se intensifica, afinal as inovações tecnológicas estão cada vez mais latentes, é necessário contar com o braço forte da eletricidade e seus materiais e acessórios.</p>
								<p style={{ fontSize: 20, fontWeight: 350 }}>Com isso, a produção, estoque e distribuição devem ser muito bem gerenciados para maior rastreabilidade do estoque, por exemplo, e aumento exponencial da rentabilidade da empresa de Materiais Elétricos. </p>
								<p style={{ fontSize: 20, fontWeight: 350 }}>A Unimake Software pode fazer diferença no dia a dia de sua empresa,<a href="https://inside.unimake.com.br/unicoerp-material-eletrico" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}> saiba mais sobre o Único ERP! </a></p>
							</div>

							<br />

							<div className="featuresBox">
								<Row>
									<Col sm={24}>
										<h2 style={{ fontSize: 32, fontWeight: 500 }}>
											Funcionalidades do ERP para Materiais Elétricos
										</h2>
									</Col>
								</Row>

								<p>Você pode estar se perguntando a diferença que um sistema de gestão integrada fará em seu negócio de atacado e varejo de materiais elétricos, nós da Unimake Software mostramos as especificidades que irão te conquistar:</p>
								<ul>
									<li><b>cadastro de códigos alternativos</b>: possibilita vincular vários códigos de fabricantes e fornecedores a um mesmo produto a fim de facilitar a localização;</li>
									<li><b>simulação do preço de venda</b> (Alt+7): é possível, com o ERP para Materiais Elétricos, simular em tempo real a lucratividade dos itens do pedido e verificar qual faixa de preço pode praticar;
									</li><li><b>venda rápida</b>: o vendedor lança os itens de acordo com as requisições do cliente e informa as condições de pagamento no fechamento;
									</li><li><b>controle de comissões</b>:
										<ul>
											<li><b>a)</b> por vendedor (comissão por funcionário); </li>
											<li><b>b)</b> por produto (condições especiais para um determinado produto); </li>
											<li><b>c)</b> por faixa de descontos (comissão de acordo com o desconto concedido); </li>
										</ul>
									</li><li><b>manuseio de produtos por código de barras</b>: o software integra o leitor de código de barras (fixo ou pistola) e armazena no sistema;
									</li><li><b>endereçamento de produtos no estoque</b>: por meio de consulta no software será possível saber exatamente o corredor e prateleira onde o produto se encontra dentro do depósito;
									</li><li><b>controle de estoque mínimo/máximo</b>:
										<ul>
											<li><b>a)</b> controla o estoque mínimo de um produto para pedido enviando aviso (CI) para o gestor de compras; </li>
											<li><b>b)</b> avisa em caso de excesso de estoque de um determinado produto; </li>
										</ul>
									</li><li><b>análise de crédito e bloqueio automático</b>: o histórico de crédito dos clientes é analisado automaticamente, o que faz com o software, por si só, libere ou bloqueie o crédito conforme os parâmetros determinados pelo financeiro;
									</li><li><b>controle de cheques pré-datados</b>: controla origem e destino dos cheques recebidos, cheques de terceiros ao cliente pagador e prazos de depósito;
									</li><li>controle de acesso de usuário: permite parametrizar as permissões que cada usuário terá dentro do software;
									</li><li><b>controle de acesso de grupos usuários</b>: o acesso ao software é permitido a alguns usuários pré-determinados;
									</li><li><b>controle total de caixa e contas-corrente</b>: geração de ficha diária para conferência de caixa, conciliação de contas bancária e controle de adiantamentos e pagamento a colaboradores;
									</li><li><b>emissão automática de boletos bancários</b>;
									</li><li><b>baixa automáticas dos boletos gerados e recebidos pelo banco</b>: integração entre o software e bancos via troca de arquivos digitais de remessa e retorno;
									</li><li><b>agrupamento de documentos</b>: agrupa várias vendas num só documento onde podem ser definidas as condições de pagamento com o cliente;
									</li><li><b>controle de venda/entrega futura</b>: permite fechar uma venda com vários itens e liberá-los em várias entregas controlando o saldo disponível;
									</li><li><b>relatórios de análise e lucratividade</b>: análise das operações tais como: estoques, contas a pagar e receber, lucratividade, comissões, extrato de movimentação por cliente e/ou vendedor, curva ABC, entre outros.</li>
								</ul>
								<p>A Unimake Software é conceituada no mercado de softwares de gestão de varejo, lojas de
									<a href="https://www.unimake.com.br/produtos/unico-auto" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}> Auto-Peças</a>,
									<a href="https://www.unimake.com.br/produtos/unico-ferragens" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}> Ferragens, </a>
									<a href="https://www.unimake.com.br/produtos/unico-material-construcao" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}> Materiais de Construção</a> e até no setor
									<a href="https://www.unimake.com.br/produtos/unico-fecularia" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}> Agroindustrial</a>.
									Vem ser nosso parceiro!</p><p>
								</p>
							</div>

							<br />

							<div className="imageMiddle-unmatele">
								<LazyLoad>
									<img
										style={{
											maxWidth: "70%",
											marginRight: "auto",
											marginLeft: "auto",
											display: "block",
										}}
										src={require("../../../assets/img/products/auto/notebook-danfe.png")}
										alt="Unimake Unico Materiais Elétricos"
									/>
								</LazyLoad>
							</div>
						</div>
					</div>
				</section>

				<div className="autoPecasBanner">
					<div className="autoPecasBanner-container">
						<Row align="middle">
							<Col xs={24} sm={24} md={{ span: 10, offset: 2 }}>
								<LazyLoad>
									<img
										className="imageLeft-fec"
										src={require("../../../assets/img/products/eletrico/middle.png")}
										alt="Unimake Unico Materiais Elétricos"
									/>
								</LazyLoad>
							</Col>

							<Col xs={24} sm={24} md={10} lg={10}>
								<p className="imageLeft-Text-fec" style={{ color: "#238883" }}>
									Exerça a gestão total da sua autopeças com o Único ERP.
								</p>
								<p className="imageLeft-Text-fec" style={{ color: "#238883" }}>
									Sua equipe, seus clientes e seus lucros vão agradecer.
								</p>
							</Col>
							<Col className="hidden-sm hidden-xs" md={2} lg={2}></Col>
						</Row>
					</div>
				</div>

				{/* <div>
					<Row>
						<Col sm={24} md={24}>
							<h1 style={{ fontSize: 32, fontWeight: 300, textAlign: "center" }}>
								Alguns casos de sucesso
							</h1>

							<div style={{ height: 200 }}>
								<UmkSliderContainer />
							</div>
						</Col>
					</Row>
				</div> */}

				<ContatoComponent />

				<script type="application/ld+json">
					{JSON.stringify(jsonLdData, null, 2)}
				</script>
			</>
		);
	}
}
export default UnicoMaterialEletricoPage;
