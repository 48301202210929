import React from "react";
import { Button, Select } from "antd";
import IResponse from "../../contract/IResponse";
import NotificationUtil from "./../../utils/NotificationUtil";
import ModalUltil from "./../../utils/ModalUtil";
import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import CommonUtil from "../../utils/CommonUtil";
import {AnuidadeIten, ItenLicenca} from "../../contract/licenca/ILicencaRenovacaoList";
import LicencaService from "../../services/LicencaService";
import { Redirect } from "react-router-dom";

const { Option } = Select;

class UmkRenovarLicencaRow extends React.Component<any, any> {
    private NotificationService: NotificationUtil;
    private ModalUltil: ModalUltil;
    private Common: CommonUtil;

    private LicencaService: LicencaService;

    defaulProps = {
        updateCart: undefined,
        setLoading: undefined,
        unsetLoading: undefined,
        Item: undefined,
    };

    state = {
        id: 0,
        loading: false,
        redirect: false,
        redirectPage: '',
    };

    constructor(props: any) {
        super(props);

        this.NotificationService = new NotificationUtil();
        this.ModalUltil = new ModalUltil(props);
        this.Common = new CommonUtil();

        this.LicencaService = new LicencaService();

        this.validadeChange = this.validadeChange.bind(this);
        this.remove = this.remove.bind(this);
    }

    componentDidMount() {
        let item: ItenLicenca = this.props.Item;

        this.setState({
            id: item.idlicenca
        });
    }

    async validadeChange(value) {
        try {
            this.props.setLoading();

            let renovaId = localStorage.getItem("RenovacaoID");
            let item: ItenLicenca = this.props.Item;
            let validade = parseInt(value);

            let result = await this.LicencaService.recalcular(renovaId, validade, item.renovai, item.anuidades, item.id);
            let response: IResponse = result.data;

            if (response.success) {
                this.ModalUltil.Success("Validade alterada!");
                await this.props.updateCart();
            } else {
                throw new Error(response.message);
            }

            this.props.unsetLoading();
        } catch (error) {
            this.props.unsetLoading();
            this.ModalUltil.Exception(error);
        }
    }

    async remove() {
        try {
            this.props.setLoading();

            let renovaId = localStorage.getItem("RenovacaoID");
            let pedidoId = localStorage.getItem("OrderID");
            let item: ItenLicenca = this.props.Item;

            let result = await this.LicencaService.excluir(renovaId, item.renovai, pedidoId);
            let response: IResponse = result.data;

            if (response.success) {
                this.ModalUltil.Success("Renovação removida!");

                localStorage.removeItem("RenovacaoID");

                if (response.data == true) {
                    localStorage.removeItem("OrderID");

                    this.setState({
                        redirect: true,
                        redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`
                    });
                }

                await this.props.updateCart();
            } else {
                throw new Error(response.message);
            }

            this.props.unsetLoading();
        } catch (error) {
            this.props.unsetLoading();
            this.ModalUltil.Exception(error);
        }
    }


    render() {
        const { props, state } = this;

        let item: ItenLicenca = this.props.Item;
        let firstAnuidade = item?.anuidade > 0 ? item.anuidade : item?.anuidades_itens[0].value;

        if (this.state.redirect) {
            return <Redirect push to={this.state.redirectPage} />;
        }

        return (
            <>
                <tr>
                    <td>
                        <div>
                            {item?.idlicenca}
                        </div>
                    </td>

                    <td style={{width: '30%'}} className="td-name">
						<span>
                            <span dangerouslySetInnerHTML={{ __html: item?.produto_html }}></span>
						</span>
                    </td>

                    <td style={{width: '70%'}} className="td-name">
						<span>
                            {item?.razao_social} <br/>
                            <span dangerouslySetInnerHTML={{ __html: item?.cnpj_html }}></span>
						</span>
                    </td>

                    <td style={{width: '20%'}}>
                        <Select
                            value={firstAnuidade}
                            onChange={this.validadeChange}
                            placeholder="Selecione uma opção">
                            {item?.anuidades_itens.map((item) => (
                                <Option value={item.value}>{item.text}</Option>
                            ))}
                        </Select>
                    </td>

                    {/* TODO: Verificar sobre a renovação neste ponto faturamento.php ln: 347 */}
                    <td className="td-actions">
                        <Button
                            onClick={() => {
                                this.remove();
                            }}
                            className="unimake-red-button"
                            icon={<DeleteOutlined />}></Button>
                    </td>
                </tr>
            </>
        );
    }
}
export default UmkRenovarLicencaRow;
