import React from "react";
import UmkClienteCadastro from "../../components/forms/unimake-cliente-cadastro/UmkClienteCadastro";
import {Helmet} from "react-helmet";

class CadastroClientePage extends React.Component<any, any> {
	render() {
		return (
			<div>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Unimake Software - Cadastro</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/cliente/cadastro`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="A Unimake Software é uma empresa especializada no ramo de soluções para ERP, DANFE, Nota Fiscal Eletrônica (NF-e), Nota Fiscal de Serviço Eletrônica (NFS-e), Conhecimento de Transporte Eletrônico (CT-e) com os produtos Unico ERP, UniDANFE, DANFE View, UniNF-e (NF-e), Consult-e (NF-e, CT-e), UniCT-e (CT-e), UniNFS-e (NFS-e), acesse e conheça nossos produtos." />
				</Helmet>

				<section className="unimake-container">
					<UmkClienteCadastro />
				</section>
			</div>
		);
	}
}
export default CadastroClientePage;
