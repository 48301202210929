import React from "react";
import NotificationUtil from "../../../utils/NotificationUtil";
import LicencaService from '../../../services/LicencaService';
import ModalUltil from "../../../utils/ModalUtil";
import { Redirect } from "react-router-dom";
import CookieUtil from "../../../utils/CookieUtil";
import IResponse from "../../../contract/IResponse";
import {Button, Card, Divider, Spin, Typography} from "antd";
import {ILicencaRenovacaoArgs} from "../../../contract/licenca/ILicencaRenovacaoArgs";
import {ILicencaRenovacaoList, ItenLicenca} from "../../../contract/licenca/ILicencaRenovacaoList";
import {SyncOutlined} from "@ant-design/icons";
import UmkRenovarLicencaRow from "../../unimake-renovar-licenca/UmkRenovarLicencaRow";
import "./UmkLicencaRenovar.scss";
import CommonUtil from "../../../utils/CommonUtil";

const {Title, Paragraph, Text} = Typography;

class UmkLicencaRenovar extends React.Component<any, any> {
    private NotificationUtil: NotificationUtil;
    private ModalUltil: ModalUltil;
    private CookieUtil: CookieUtil;
    private Common: CommonUtil;

    private LicencaService: LicencaService;

	state = {
		loading: false,
        redirect: false,
        redirectPage: '',
        licencaid: undefined,
        renovacaoArgs: undefined,
        renovaid: undefined,
        licencasResult: undefined
	};

	constructor(props: any) {
		super(props);

        this.NotificationUtil = new NotificationUtil();
        this.ModalUltil = new ModalUltil(props);
        this.CookieUtil = new CookieUtil();
        this.Common = new CommonUtil();

        this.LicencaService = new LicencaService();

		this.load = this.load.bind(this);
        this.getParameters = this.getParameters.bind(this);
        this.validateRenovacao = this.validateRenovacao.bind(this);
        this.loadLicencas = this.loadLicencas.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
        this.handleLoading = this.handleLoading.bind(this);
        this.handleUnLoading = this.handleUnLoading.bind(this);
	}

	componentDidMount() {
		this.load();
	}

    /**
     * Carrega os dados da URL
     */
	async load(): Promise<void> {
		this.setState({ loading: true });

        this.getParameters();

		this.setState({ loading: false });
	}

    /**
     * Captura o id da licença
     */
    getParameters(): void {
        try {
            let url_string = window.location.href;
            let url = new URL(url_string);
    
            let idlicenca = url.searchParams.get("id");
    
            if (!idlicenca || idlicenca == '0')
                throw new Error("Licença inválida");

            let licencaArgs: ILicencaRenovacaoArgs = {
                id: idlicenca,
                ren: url.searchParams.get("ren"),
                rendnl: url.searchParams.get("rendnl"),
                p: url.searchParams.get("p"),
                recalcular: url.searchParams.get("recalcular"),
                excluir: url.searchParams.get("excluir"),
                fechar_compra: url.searchParams.get("fechar_compra"),
            };

            this.setState({
                licencaid: idlicenca,
                renovacaoArgs: licencaArgs
            }, () => this.validateRenovacao());
        } catch (error)  {
            this.ModalUltil.Error(error.message);            
            this.setState({
                redirect: true,
                redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`,
            });
        }        
    }

    /**
     * Valida a possibilidade de renovar e inicia o processo de renovação
     */
    async validateRenovacao(): Promise<void> {
        try {
            this.setState({loading: true});

            let orderId = localStorage.getItem("OrderID");
            let renovacaoId = localStorage.getItem("RenovacaoID");
            let renovacaoArgs: ILicencaRenovacaoArgs = this.state.renovacaoArgs;

            // faz todas as validacoes necessarias
            let result = await this.LicencaService.validateRenovarLicenca(orderId, renovacaoId, renovacaoArgs);
            this.setState({loading: false});

            let response: IResponse = result.data;

            if (response.success) {
                let resultValidate = response.data;

                if (resultValidate.erros && resultValidate.erros.length > 0) {
                    this.NotificationUtil.Warning(resultValidate.erros);
                }

                if (resultValidate.listar) {
                    let renovaid = resultValidate.renovaid;

                    localStorage.setItem('RenovacaoID', renovaid);

                    this.setState({
                        renovaid: renovaid,
                        loading: true,
                    }, () => this.loadLicencas());
                }

            } else {
                let messageCode = response.message;

                switch (messageCode) {
                    // Já tem um pedido que não é uma renovação em aberto
                    case "0":
                        this.setState({
                            redirect: true,
                            redirectPage: `${process.env.PUBLIC_URL}/loja/carrinho`
                        });
                        throw new Error("Você já tem um pedido em aberto nessa loja!\nPor questões operacionais, não é possível misturar pedidos de renovação de licenças com outros tipos de pedido.\nPor favor, feche a compra atual e só depois inicie a renovação das licenças.\n\Para concluir a compra acesse a opção \"Fechar a compra\" abaixo da lista de produtos.\nSe você não deseja concluir a compra em aberto, exclua os itens do carrinho.")
                        break;

                    default:
                        throw new Error(response.message);
                }
            }
        } catch (error) {
            this.setState({
                loading: false,
                redirect: true,
                redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`
            });
            this.ModalUltil.Exception(error);
        }
    }

    async loadLicencas(): Promise<void> {
        try {
            let pedidoId = localStorage.getItem("OrderID");
            let renovaId = localStorage.getItem("RenovacaoID");

            if (renovaId) {
                this.setState({ loading: true });

                let result = await this.LicencaService.getLicencas(renovaId, pedidoId);
                let response: IResponse = result.data;

                this.setState({ loading: false });

                if (response.success) {
                    let result: ILicencaRenovacaoList = response.data;

                    this.setState({
                        licencasResult: result
                    });
                } else {
                    throw new Error(response.message);
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            this.ModalUltil.Exception(error);
        }
    }

    handleLoading() {
        this.setState({
            loading: true,
        });
    }

    handleUnLoading() {
        this.setState({
            loading: false,
        });
    }

    async handleFinish() {
        try {
            let renovaId = localStorage.getItem("RenovacaoID");

            this.setState({ loading: true });

            let result = await this.LicencaService.encerrar(renovaId);
            let response: IResponse = result.data;

            this.setState({ loading: false });

            if (response.success) {

                this.CookieUtil.Set("OrderID", response.data);
                localStorage.setItem("OrderID", response.data);

                setTimeout(() => {
                    this.setState({
                        loading: false,
                        redirect: true,
                        redirectPage: `${process.env.PUBLIC_URL}/loja/carrinho`
                    });
                }, 700);

            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            this.setState({
                loading: false,
            });
            this.ModalUltil.Exception(error);
        }
    }

	render() {
        if (this.state.redirect) {
            return <Redirect push to={this.state.redirectPage} />;
        }

        let result: ILicencaRenovacaoList = this.state.licencasResult;

        return (
			<div style={{minHeight: 600}}>
                    <Title level={4}>Renovação de Licença</Title>
                    <Divider/>

                    <Card>
                        <div className="UmkLicencaRenovar">
                            <Title level={3}>Licenças</Title>
                            <Divider />

                            <Button
                                style={{ marginBottom: 10 }}
                                onClick={this.loadLicencas}
                                shape="round"
                                type="primary"
                                icon={<SyncOutlined />}>
                                Atualizar
                            </Button>

                            <div className="card-body">
                                <Spin tip="Aguarde..." spinning={this.state.loading}>
                                    <div className="table-responsive">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>Licenca</th>
                                                <th>Produto</th>
                                                <th>Razão Social e CNPJ</th>
                                                <th>Renovar para:</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {result?.itens.map((item) => (
                                                <UmkRenovarLicencaRow
                                                    unsetLoading={this.handleUnLoading}
                                                    setLoading={this.handleLoading}
                                                    updateCart={this.loadLicencas}
                                                    Item={item}
                                                />
                                            ))}
                                            <tr>
                                                <td colSpan={3}></td>
                                                <td></td>
                                                <td></td>
                                                <td className="td-total">Total</td>
                                                <td className="td-price">{this.Common.FormatBrl(result?.total)}</td>

                                                <td colSpan={2} className="text-right">
                                                    <Button
                                                        onClick={this.handleFinish}
                                                        shape="round"
                                                        className="unimake-green-button"
                                                        block>
                                                        Salvar
                                                    </Button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Spin>
                            </div>
                        </div>
                    </Card>

                    <Divider/>

                    <Paragraph>
                        <span>- Para concluir a renovação da(s) licença(s) clique no botão "Salvar". Ao fazê-lo, suas licenças serão automaticamente incluídas no carrinho de compras e você poderá então fechar a compra, naturalmente.</span> <br/><br/>
                        <span>- As licenças renovadas não necessitam de gerenciamento. Cada uma delas será gerada para o mesmo CNPJ e Razão Social para as quais foram geradas originalmente, e serão enviadas para seus endereços de e-mail informados no cadastro tão logo o pagamento seja confirmado por nosso departamento financeiro.</span><br/><br/>
                        <span>- As licenças renovadas terão sua vigência inicial exatamente no dia seguinte ao vencimento da licença anterior, ou à partir da data em que forem geradas, caso a licença original já estiver vencida.</span><br/><br/>
                        <span>- Após salvar, se desejar retornar à essa tela acesse a opção "Renovação de licenças" no menu lateral esquerdo.</span><br/>
                    </Paragraph>
			</div>
		);
	}
}
export default UmkLicencaRenovar;
