import React from "react";
import ClienteService from "../../services/ClienteService";
import IResponse from "../../contract/IResponse";
import StorageService from "../../services/StorageService";
import {format} from "date-fns";
import LoadingOverlay from "react-loading-overlay";
import {Button, Card, Col, Empty, Row, Table, Typography} from "antd";
import ModalUltil from "./../../utils/ModalUtil";
import CommonUtil from '../../utils/CommonUtil';
import NFeService from "../../services/NFeService";
import Modal from "antd/lib/modal/Modal";
import {ICancelamentoPedidoResult} from "../../contract/cliente/minhas-compras/ICancelamentoPedidoResult";

const {Title, Paragraph, Text} = Typography;

class UmkClienteCompras extends React.Component<any, any> {
    private ModalUltil: ModalUltil;
    private ClienteService: ClienteService;
    private NFeService: NFeService;
    private storage: StorageService;
    private CommonUtil: CommonUtil;

    ProdutosFormatter = (value) => {
        return (
            <p dangerouslySetInnerHTML={{__html: value}}>
            </p>
        );
    };

    SituacaoFormattr = (value) => {
        return (
            <p>
                {value.situacao}
                <p hidden={value.situacao != "Aguardando pagamento"}>
                    <br/>
                    <a href={value.link_boleto} target="_blank">Gerar boleto</a> |
                    <a href={value.link_pagseguro} target="_blank">Pagar c/ cartão</a> |
                    <a onClick={() => {
                        this.cancelarLicenca(value.idpedido)
                    }}>Cancelar</a>
                </p>
                <p hidden={!value.data_baixa}>
                    {value.data_baixa}
                </p>
            </p>
        );
    }

    OptionsFormatter = (value) => {
        return (
            <div>
                {value.numero != undefined ? (
                    <div>
                        {value.numero} de {format(new Date(value.dataemissao), "dd/MM/yyyy")}
                        <br/>
                    </div>
                ) : (
                    <div></div>
                )}
                {value.arquivo_pdf && value.arquivo_xml ? (
                    <Row>
                        <Col sm={11} md={11} xs={11}>
                            <Button block type="primary" onClick={() => {
                                this.downloadFile(value, "pdf")
                            }}>
                                PDF
                            </Button>
                        </Col>

                        <Col sm={1} md={1} xs={1}/>

                        <Col sm={11} md={11} xs={11}>
                            <Button block type="primary" onClick={() => {
                                this.downloadFile(value, "xml")
                            }}>
                                XML
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <div></div>
                )}
            </div>
        );
    };

    state = {
        datasource: [],
        loading: false,
        total: 0,
        modalConfirmar: false,
        dadosPedido: undefined
    };

    private columns = [
        {
            title: "Pedido",
            key: "idpedido",
            dataIndex: "idpedido",
            width: 100,
        },
        {
            title: "Data",
            key: "data",
            dataIndex: "data",
            width: 100,
        },
        {
            title: "Tipo",
            key: "tipo",
            dataIndex: "tipo",
            width: 80,
        },
        {
            title: "Total",
            key: "total",
            dataIndex: "total",
            width: 100,
        },
        {
            title: "Situação",
            key: "situacao",
            dataIndex: "situacaoObject",
            render: this.SituacaoFormattr,
            width: 270,
        },
        {
            title: "Produtos",
            key: "produtos",
            render: this.ProdutosFormatter,
            dataIndex: "produtos",
            width: 200,
        },
        {
            title: "NFe",
            key: "nfe",
            dataIndex: "nfe",
            render: this.OptionsFormatter,
            width: 160,
        },
    ];


    constructor(props: any) {
        super(props);

        this.ModalUltil = new ModalUltil(props);
        this.ClienteService = new ClienteService();
        this.NFeService = new NFeService();
        this.storage = new StorageService();
        this.CommonUtil = new CommonUtil();

        this.Load = this.Load.bind(this);
        this.cancelarLicenca = this.cancelarLicenca.bind(this);
        this.fechar = this.fechar.bind(this);
    }

    componentDidMount() {
        this.Load();
    }

    private async Load() {
        try {
            this.setState({loading: true});

            let usuario: any = JSON.parse(this.storage.Get("Usuario"));

            let result = await this.ClienteService.GetCompras(usuario.idcliente);

            this.setState({loading: false});

            let response: IResponse = result.data;
            let valid = this.ClienteService.ValidateLoginFromResponse(response);

            if (valid) {
                let result = this.resolveEntities(response.data);

                this.setState({
                    datasource: result,
                });
            }

            this.setState({loading: false});
        } catch (error) {
            this.setState({loading: false});
            this.ModalUltil.Exception(error);
        }
    }

    resolveEntities(entities) {
        let total = 0;

        for (let item of entities) {
            if (item.data) item.data = format(new Date(item.data), "dd/MM/yyyy");
            if (item.data_baixa) item.data_baixa = format(new Date(item.data_baixa), "dd/MM/yyyy");

            if (item.produtos) {
                let produtoString = "";

                let index = 1;
                for (let produto of item.produtos) {
                    let quantidade = parseInt(produto.quantidade);

                    produtoString += `${quantidade}x - ${produto.nome}`;

                    if (produto.anuidades > 1) {
                        produtoString += ` ${produto.anuidades} anos`;
                    }

                    if (item.produtos.length > 1 && index < item.produtos.length) {
                        produtoString += '<br>';
                    }

                    index++;
                }

                item.situacaoObject = {
                    idpedido: item.idpedido,
                    situacao: item.situacao,
                    data_baixa: item.data_baixa,
                    link_boleto: item.link_boleto,
                    link_pagseguro: item.link_pagseguro
                };

                item.produtos = produtoString;
            }
            total += parseFloat(item.total);

            item.total = this.CommonUtil.FormatBrl(item.total);

            item.nfe = {};
            if (item.nfe_numero) {
                item.nfe.numero = item.nfe_numero;
                item.nfe.dataemissao = item.nfe_emissao;
                item.nfe.chaveacesso = item.nfe_chaveacesso;
                item.nfe.arquivo_pdf = item.nfe_pdf;
                item.nfe.arquivo_xml = item.nfe_xml;
            }
        }

        this.setState({
            total: total,
        })

        return entities;
    }

    async downloadFile(file: any, extensao: string): Promise<void> {
        try {
            this.setState({loading: true});

            let result = this.NFeService.downloadLink(file.chaveacesso, extensao);

            this.setState({loading: false});
            let asXml: boolean = extensao == "xml";

            this.download(asXml ? file.arquivo_xml : file.arquivo_pdf, result);
        } catch (error) {
            this.setState({loading: false});
            this.ModalUltil.Error(error.message);
        }
    }

    download(filename, link) {
        let element = document.createElement('a');
        element.setAttribute('href', link);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    async cancelarLicenca(idpedido: any): Promise<void> {
        try {
            this.setState({loading: true});

            let result = await this.ClienteService.CancelarPedido(idpedido);

            this.setState({loading: false});

            let response: IResponse = result.data;

            if (response.success) {
                this.setState({modalConfirmar: true, dadosPedido: response.data});
                await this.Load();
            } else {
                this.ModalUltil.Error(response.message);
            }

            this.setState({loading: false});
        } catch (error) {
            this.setState({loading: false});
            this.ModalUltil.Error(error.message);
        }
    }

    fechar(): void {
        this.setState({modalConfirmar: false});
    }

    render() {
        let dadosPedido: ICancelamentoPedidoResult = this.state.dadosPedido;

        return (
            <div>
                <Modal
                    className="modal-faturamento"
                    visible={this.state.modalConfirmar}
                    title="Cancelamento de Pedido"
                    onOk={this.fechar}
                    onCancel={this.fechar}
                    centered
                    width="40rem"
                    footer={[
                        <Button key="back" onClick={this.fechar}>
                            Ok
                        </Button>,
                    ]}>
                    <Card>
                        <Paragraph style={{fontSize: "medium"}}>
                            <span>Comprador: <span style={{fontWeight: 'bold'}}>{dadosPedido?.razao_social}</span></span><br/>
                            <span>Nº da Compra: <span style={{fontWeight: 'bold'}}>{dadosPedido?.idpedido}</span></span><br/>
                            <span>Data da Compra: <span style={{fontWeight: 'bold'}}>{dadosPedido?.data}</span></span><br/>
                            <span>Valor total: <span style={{fontWeight: 'bold'}}>{this.CommonUtil.FormatBrl(dadosPedido?.total)}</span></span><br/>
                            <span>Situação: <span style={{fontWeight: 'normal'}}>{dadosPedido?.situacao}</span></span><br/>
                            <span>Produtos:
                                <ul>
                                    {dadosPedido?.produtos.map((nome) => <li><span style={{fontWeight: 'bold'}}>{nome}</span> </li>)}
                                </ul>
                            </span><br/>

                            <br/>

                            <Paragraph style={{textAlign: "center"}}>
                                <span style={{color: 'red'}}>A compra foi cancelada com sucesso!</span> <br/>
                                Se houver necessidade, basta efetuar a compra novamente. <br/>
                                <span style={{fontWeight: 'bold'}}>Unimake Software</span>
                            </Paragraph>

                        </Paragraph>
                    </Card>
                </Modal>
                <LoadingOverlay active={this.state.loading} spinner text="Carregando dados...">
                    {this.state.datasource.length > 0 ? (
                        <>
                            {/*<Row>*/}
                            {/*    <Col md={6} sm={4} lg={6}>*/}
                            {/*        <Card title="Total">*/}
                            {/*            <h1>{this.CommonUtil.FormatBrl(this.state.total)}</h1>*/}
                            {/*        </Card>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}

                            {/*<br/>*/}

                            <Table
                                bordered={true}
                                dataSource={this.state.datasource}
                                columns={this.columns}
                                pagination={{ pageSize: 20 }}
                            />
                        </>
                    ) : (
                        <Empty description="Nenhum registro encontrado"/>
                    )}
                </LoadingOverlay>
            </div>
        );
    }
}

export default UmkClienteCompras;
