import React from "react";
import UmkDownload from "../../components/unimake-download/UmkDownload";
import {Helmet} from "react-helmet";

class DownloadsPage extends React.Component<any, any> {
	state = {
		loading: true,
	};

	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<div>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Downloads em Gestão Integrada | Unimake Software</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/downloads`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="Nesta página de downloads você encontra arquivos de UniNFe, UniDANFE, DANFE View, Gemini e outros. Clique e confira todas as possibilidades!" />
				</Helmet>

				<section className="unimake-container">
					<h1>Downloads</h1>

					<UmkDownload Titulo="UniNFe" Categoria="uninfe" Key="1" ActiveKey="1" documentacao="http://wiki.unimake.com.br/index.php/Manuais:UniNFe"  historicoVersao="http://redmine.unimake.com.br/projects/uninfe/wiki/BuildsUniNFe"/>
					<UmkDownload Titulo="UniDANFE" Categoria="unidanfe" Key="2" ActiveKey="2" documentacao="http://wiki.unimake.com.br/index.php/Manuais:UniDANFE"  historicoVersao="http://wiki.unimake.com.br/index.php/Manuais:UniDANFE/Builds" />
					<UmkDownload Titulo="DANFE View" Categoria="danfeview" Key="3" ActiveKey="3" historicoVersao="http://wiki.unimake.com.br/index.php/Manuais:DANFEView/Builds"/>
					<UmkDownload Titulo="GEMINI - Cloud Backup" Categoria="gemini" Key="4" ActiveKey="4" />
					<UmkDownload Titulo="Único" Categoria="unico" Key="5" ActiveKey="5" />
					<UmkDownload Titulo="Outros" Categoria="outros" Key="6" ActiveKey="6" />
				</section>
			</div>
		);
	}
}
export default DownloadsPage;
