import React from "react";
import { Row, Col, Button } from "antd";
import ContatoComponent from "../components/ContatoComponent";
import {Helmet} from "react-helmet";

const jsonLdData = {
	'@context': 'http://schema.org',
	'@type': 'Product',
	name: "BI",
	image: "https://www.unimake.com.br/static/media/bi.28d9f42e.webp",
	description: "Simplifique a análise de dados em tempo real e revolucione a gestão da sua empresa.",
	brand: {
		'@type': 'Brand',
		name: "Unimake",
	},
	"review": {
		"@type": "Review",
		"reviewRating": {
		  "@type": "Rating",
		  "ratingValue": "5"
		},
		"author": {
		  "@type": "Person",
		  "name": "Unimake"
		}
	}
};

class BIPage extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
	}

	state = {
		logoBI: require("../../../assets/img/products/bi/bi.webp"),
	};

	componentDidMount() {
		document.title = "Unimake Software - BI";
	}

	render() {
		return (
			<>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Unimake Software - BI</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/produtos/bi`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="A Unimake Software é uma empresa especializada no ramo de soluções para ERP, DANFE, Nota Fiscal Eletrônica (NF-e), Nota Fiscal de Serviço Eletrônica (NFS-e), Conhecimento de Transporte Eletrônico (CT-e) com os produtos Unico ERP, UniDANFE, DANFE View, UniNF-e (NF-e), Consult-e (NF-e, CT-e), UniCT-e (CT-e), UniNFS-e (NFS-e), acesse e conheça nossos produtos." />
				</Helmet>

				<div className="topo-border-rounded color-bi">
					<Row align="middle" justify="space-around">
						<Col
							lg={{ order: 1, span: 10, offset: 2 }}
							xs={{ order: 2, span: 20 }}
							xxl={{ order: 1, span: 7, offset: 5 }}>
							<h1 className="hidden-xs hidden-sm hidden-md">Business Intelligence (BI)</h1>

							<p className="subtitulo">
								A ferramenta de análise de dados que vai revolucionar a gestão da sua empresa.
							</p>
						</Col>
						<Col lg={{ order: 2, span: 10 }} xs={{ order: 1 }}>
							<img className="topo-produto" src={this.state.logoBI} alt="Unimake BI"></img>
						</Col>
					</Row>
				</div>

				<section>
					<div className="unimake-container">
						<div className="content-area-product">
							<div className="pre-description-center">
								<p>Parece complicado, mas não é!</p>

								<p style={{ fontSize: 22 }}>
									A BI é uma ferramenta de tecnologia que apresenta as informações do seu negócio da
									forma mais visual possível
								</p>

								<p style={{ fontWeight: 500, fontSize: 23 }}>
									Com a BI Unimake você pode visualizar todas as informações estratégicas da sua
									empresa como{" "}
									<span style={{ color: "blue" }}>
										vendas, estoques, contas a receber e indaimplência, volumes de produção e muito
										mais
									</span>{" "}
									de forma extremamente ágil atráves de modernos gráficos.
								</p>

								<br />
							</div>

							<div className="featuresBox">
								<Row justify="space-between">
									<Col sm={11} md={7}>
										<p style={{ fontWeight: 400, fontSize: 20 }}>
											Algumas razões pelas quais você deve implantar hoje mesmo a BI Unimake na
											sua empresa:
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Facilita a análise das informações</b>

										<p style={{ marginTop: 5 }}>
											Visualize todas as informações essenciais da empresa em gráficos, ganhe
											tempo e precisão em realação à análise de longos relatórios em papel.
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Uma nova visão sobre a empresa</b>

										<p style={{ marginTop: 5 }}>
											Através das análise que os gráficos da BI proporcionam você pode descobrir
											informações que não desocbriria apenas consultando relatórios.
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Informações em tempo real</b>

										<p style={{ marginTop: 5 }}>
											No seu table ou celular, consulte o desempenho da sua empresa onde estiver.
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Mais agilidade, mais lucro!</b>

										<p style={{ marginTop: 5 }}>
											Agilidade é uma das chaves do sucesso no mundo dos negócios, com a BI
											Unimake quanto mais rápido você toma decisões mais rápido sua empresa
											avança.
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Aproveita os dados do seu ERP</b>

										<p style={{ marginTop: 5 }}>
											Isso mesmo! A ferramenta de BI Unimake aproveita os dados gerados pelo seu
											sistema, tomando a implantação e o uso muito mais simples e ágeis no dia a
											dia.
										</p>
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</section>

				<div className="autoPecasBanner color-black-transparent">
					<div className="autoPecasBanner-container">
						<Row align="middle" justify="space-between">
							<Col md={10} sm={10}>
								<img
									style={{ maxWidth: "99%" }}
									src={require("../../../assets/img/products/bi/bi_novo.webp")}
									alt="Unimake BI Banner"
								/>
							</Col>

							<Col md={12} sm={12}>
								<span style={{ fontSize: 27, fontWeight: 600 }}>
									Esqueça relatórios em papel, a BI é a ferramenta certa para você analisar e
									alavancar a o desepenho da sua empresa.
								</span>

								<br />
								<br />

								<p style={{ fontSize: 23 }}>
									O seu tempo é escasso para perde horas com análises de relatórios em papel.
									<br />
									<br />A agilidade da ferramenta de BI Unimake facilita sua rotina e economiza suas
									energias para focar na grandes decisões de sucesso.
								</p>
							</Col>
							<Col md={1} sm={1}></Col>
						</Row>
					</div>
				</div>

				<br />

				{/* TODO: Criar componente */}
				{/* <div className="successCases-bi">
                    <Row>
                        <Col sm={24} md={24}>
                            <h1 style={{ fontSize: 32, fontWeight: 300, textAlign: 'center' }}>
                                Alguns casos de sucesso da BI Unimake
                            </h1>
                        </Col>
                    </Row>
                </div> */}

				<div className="footerImageShape">
					<Row align="middle">
						<Col md={24} sm={24} lg={10}>
							<img
								style={{ maxWidth: "20vw", marginLeft: "15vw", marginTop: "5vh" }}
								src={require("../../../assets/img/products/bi/bi_nopc.webp")}
								alt="Unimake BI"
							/>
						</Col>
						<Col sm={24} lg={10}>
							<ContatoComponent allWidth={false} />
						</Col>
					</Row>
				</div>

				<script type="application/ld+json">
					{JSON.stringify(jsonLdData, null, 2)}
				</script>
			</>
		);
	}
}
export default BIPage;
