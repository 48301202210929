import IResponse from '../contract/IResponse';
import StorageService from "./StorageService";
import ApiRequestService from './ApiRequestService';
import NotificationUtil from './../utils/NotificationUtil';
import ICliente from './../contract/cliente/ICliente';
import {ILicencaFilter} from '../contract/cliente/ILicencaFilter';
import UnimakeApiService from './UnimakeApiService';

export default class ClienteService {
    private unimakeApi: UnimakeApiService;
    private path: string = '/Loja';

    private NotificationService: NotificationUtil;
    private storage: StorageService;

    constructor() {
        this.unimakeApi = new UnimakeApiService();
        this.NotificationService = new NotificationUtil();
        this.storage = new StorageService();
    }

    public ValidateLoginFromResponse(response: IResponse) {
        if (!response.success) {
            if (response.message == 'Token Expirado.') {
                let storage = new StorageService();

                storage.Set('Usuario', '');
                storage.Set('Token', '');

                this.NotificationService.Error('Sessão expirada, faça login novamente');
                window.location.href = '/cliente/login';

                return false;
            } else {
                this.NotificationService.Error(response.message);
                return false;
            }

        } else {
            return true;
        }
    }

    /**
     * Retorna o cliente logado, se não houver retorna false
     */
    public GetClienteLogado(): ICliente {
        let cliente = this.storage.Get('Usuario');

        if (!cliente)
            return null;

        return JSON.parse(cliente);
    }


    public async Authenticate(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.post(`${this.path}/Auth/Login`, body);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async GetCliente(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.get(`/${this.path}/Cliente/Get`, headers);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async Create(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.post(`/free/${this.path}/Cliente/Create`, body);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async Update(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.put(`${this.path}/Cliente/Update`, body, headers);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async GetCompras(ClienteID: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.get(`${this.path}/Pedido/MinhasCompras?idcliente=${ClienteID}`, headers);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async GetBoletos(ClienteID: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.get(`${this.path}/Boleto/GetBoletos?idcliente=${ClienteID}`, headers);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async GetNFe(ClienteID: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.get(`${this.path}/NFe/GetNFe?idcliente=${ClienteID}`, headers);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async GetLicencas(filter: ILicencaFilter): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.post(`${this.path}/Licenca/GetLicencasFilter`, filter, headers);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async RecoverPassword(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.post(`/free/${this.path}/Cliente/RecoverPassword`, body);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }


    public async ChangePassword(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.post(`/free/${this.path}/Cliente/ChangePassword`, body);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async ChangePasswordAuth(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.post(`${this.path}/Cliente/ChangePasswordAuth`, body, headers);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    public async CancelarPedido(idpedido: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.post(`${this.path}/Pedido/Cancelar`, {idpedido: idpedido}, headers);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }
}
