import React from "react";
import { Button, Collapse, Divider, Typography } from "antd";

const Panel = Collapse.Panel;
const { Title } = Typography;

class UmkVagasList extends React.Component<any, any> {
	static defaultProps = {
		Vagas: [],
		Title: "Vagas Disponíveis",
	};

	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<div>
				<h2>{this.props.Title}</h2>
				<Divider />
				<Collapse accordion>
					{this.props.Vagas.map((item: any) => (
						<Panel header={item.titulo} key={item.idvaga}>
							{" "}
							<p className="vagas-desc" dangerouslySetInnerHTML={{ __html: item.descricao }}></p>{" "}
						</Panel>
					))}
				</Collapse>
			</div>
		);
	}
}
export default UmkVagasList;
