import React from "react";
import { Col, Divider, Row, Typography } from "antd";
import UmkEsqueciSenha from "./../../components/unimake-esquecisenha/UmkEsqueciSenha";
import {Helmet} from "react-helmet";

const { Title } = Typography;

class EsqueciMinhaSenhaPage extends React.Component<any, any> {
	state = {
		loading: false,
	};

	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<div>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Unimake Software - Trabalhe Conosco - Esqueci Minha Senha</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/trabalhe-conosco/esqueci-minha-senha`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="A Unimake Software é uma empresa especializada no ramo de soluções para ERP, DANFE, Nota Fiscal Eletrônica (NF-e), Nota Fiscal de Serviço Eletrônica (NFS-e), Conhecimento de Transporte Eletrônico (CT-e) com os produtos Unico ERP, UniDANFE, DANFE View, UniNF-e (NF-e), Consult-e (NF-e, CT-e), UniCT-e (CT-e), UniNFS-e (NFS-e), acesse e conheça nossos produtos." />
				</Helmet>

				<section className="unimake-container unimake-padding">
					<div className="content-area">
						<Row>
							<Col sm={24} md={24} xs={24}>
								<div className="content-area">
									<Title level={4}>Esqueci minha senha</Title>
									<Title level={5}>Informe seu E-mail e enviaremos uma nova senha para você.</Title>
									<Divider />
									<UmkEsqueciSenha tipo="trabalhe" />
								</div>
							</Col>
						</Row>
					</div>
				</section>
			</div>
		);
	}
}
export default EsqueciMinhaSenhaPage;
