import React from "react";
import { Form, Input, Button, Checkbox, Spin, Row, Col } from "antd";
import IResponse from "../../contract/IResponse";

import { Redirect } from "react-router-dom";

import NotificationUtil from "../../utils/NotificationUtil";
import StorageService from "../../services/StorageService";
import CookieUtil from "../../utils/CookieUtil";
import CommonUtil from "../../utils/CommonUtil";
import { UserOutlined } from "@ant-design/icons";
import ModalUltil from "../../utils/ModalUtil";
import TrabalheConoscoService from "../../services/TrabalheConoscoService";
import ClienteService from '../../services/ClienteService';

class UmkEsqueciSenha extends React.Component<any, any> {
	static defaultProps = {
		tipo: "cliente",
	};

	state = {
		loading: false,
		redirect: false,
		redirectPage: "",
	};

	private Common: CommonUtil;
	private NotificationService: NotificationUtil;
	private ModalUltil: ModalUltil;
	private TrabalheConoscoService: TrabalheConoscoService;
	private ClienteService: ClienteService;
	private storage: StorageService;
	private CookieService: CookieUtil;

	constructor(props: any) {
		super(props);

		this.Common = new CommonUtil();
		this.TrabalheConoscoService = new TrabalheConoscoService();
		this.ClienteService = new ClienteService();
		this.NotificationService = new NotificationUtil();
		this.ModalUltil = new ModalUltil(props);
		this.storage = new StorageService();
		this.CookieService = new CookieUtil();

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async handleSubmit(values: any) {
		try {
			let result = undefined;
			

			this.setState({ loading: true });

			if (this.props.tipo == "trabalhe") {
				let body = {
					email: values.email,
				};
				result = await this.TrabalheConoscoService.RecoverPassword(body);
			} else {
				let body = {
					cpfcnpj: values.cpfcnpj,
				};
				result = await this.ClienteService.RecoverPassword(body);
			}

			this.setState({ loading: false });

			let response: IResponse = result.data;

			if (response.success) {
				this.NotificationService.Success(
					(response.message = response.message ? response.message : "Recuperação Realizada com Sucesso!")
				);
			} else {
				this.ModalUltil.Exception(response.message);
			}
		} catch (error) {
			this.setState({ loading: false });
			this.ModalUltil.Exception(error);
		}
	}

	render() {
		if (this.state.redirect) {
			return <Redirect push to={this.state.redirectPage} />;
		}

		return (
			<div>
				<Spin tip="Aguarde..." spinning={this.state.loading}>
					<Form name="control-ref" onFinish={this.handleSubmit} className="login-form" layout="vertical">
						<Row>
							<Col
								style={{
									display: "block",
									marginBottom: 10,
								}}
								sm={16}
								md={16}
								xs={24}>
								<Form.Item
									className="form-item-umk"
									label={this.props.tipo == "cliente" ? "CPF/CNPJ" : "E-mail"}
									name={this.props.tipo == "cliente" ? "cpfcnpj" : "email" }
									rules={[{ required: true, message: `Informe o  ${this.props.tipo == "cliente" ? "CPF/CNPJ" : "E-mail"}!` }]}>
									<Input prefix={<UserOutlined />} type={this.props.tipo == "cliente" ? "text" : "email"} placeholder={`Informe o seu ${this.props.tipo == "cliente" ? "CPF/CNPJ" : "E-mail"}`} />
								</Form.Item>
								<Button type="primary" htmlType="submit" shape="round" className="login-form-button">
									Enviar
								</Button>
							</Col>
						</Row>
					</Form>
				</Spin>
			</div>
		);
	}
}

export default UmkEsqueciSenha;
