import React from "react";
import { Card, Tabs } from "antd";
import CookieUtil from "./../../utils/CookieUtil";
import { Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";

import ModalUltil from "../../utils/ModalUtil";
import ClienteService from "../../services/ClienteService";
import StorageService from "../../services/StorageService";
import CommonUtil from "../../utils/CommonUtil";
import NotificationUtil from '../../utils/NotificationUtil';
import UmkLicencaEditar from '../../components/cliente/Licenca/UmkLicencaEditar';
import UmkLicencaAtivarDesativar from '../../components/cliente/Licenca/UmkLicencaAtivarDesativar';
import UmkLicencaRenovar from '../../components/cliente/Licenca/UmkLicencaRenovar';

class LicencaPage extends React.Component<any, any> {
    private CookieUtil: CookieUtil;
    private ModalUltil: ModalUltil;
    private ClienteService: ClienteService;
    private storage: StorageService;
    private CommonUtil: CommonUtil;
    private NotificationUtil: NotificationUtil;

    state = {
        redirect: false,
        redirectPage: '',
        action: ''
    };

    constructor(props: any) {
        super(props);

        this.CookieUtil = new CookieUtil();
        this.ModalUltil = new ModalUltil(props);
        this.NotificationUtil = new NotificationUtil();
        this.ClienteService = new ClienteService();
        this.storage = new StorageService();
        this.CommonUtil = new CommonUtil();

        this.checkLogin = this.checkLogin.bind(this);
        this.checkPage = this.checkPage.bind(this);
        this.validateAction = this.validateAction.bind(this);
    }

    componentDidMount() {
        this.checkLogin();
        this.checkPage();
    }

    async checkLogin() {
        let token = await this.CookieUtil.Get("TokenUsuario");

        if (!token || token === "undefined") {
            localStorage.setItem("redirect", "true");
            localStorage.setItem("redirectPath", window.location.pathname);

            this.setState({
                redirect: true,
                redirectPage: `${process.env.PUBLIC_URL}/cliente/login`,
            });
        }
    }

    checkPage() {
        try {
            let url = window.location.href;
            let parts = url.split('/');

            let expected = parts[5];

            let action = expected.substring(
                0,
                expected.lastIndexOf("?")
            );

            this.validateAction(action);
        } catch (error) {
            this.NotificationUtil.Error('Ação para está pagina não identificada');
            this.setState({
                redirect: true,
                redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`,
            });
        }
    }

    validateAction(action: string) {
        try {
            switch (action) {
                case 'editar':
                case 'desativar':
                case 'reativar':
                case 'renovar':
                    this.setState({
                        action: action,
                    })
                    break;

                default:
                    throw Error("Ação não identificada");
                    break;
            }
        } catch (error) {
            this.NotificationUtil.Error('Ação para está pagina não identificada');
            this.setState({
                redirect: true,
                redirectPage: `${process.env.PUBLIC_URL}/cliente/login`,
            });
        }
    }

    renderAction() {
        let action: string = this.state.action;

        switch (action) {
            case 'editar':
                return (
                    <UmkLicencaEditar />
                );

            case 'reativar':
                return <UmkLicencaAtivarDesativar ativar={true} />;

            case 'desativar':
                return <UmkLicencaAtivarDesativar ativar={false} />;

            case 'renovar':
                return <UmkLicencaRenovar />;

            default:
                return null;
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={this.state.redirectPage} />;
        }

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Unimake Software - Licença</title>
                    <link rel="canonical" href={`${window.location.href}`} />
                    <meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
                    <meta name="description" content="A Unimake Software é uma empresa especializada no ramo de soluções para ERP, DANFE, Nota Fiscal Eletrônica (NF-e), Nota Fiscal de Serviço Eletrônica (NFS-e), Conhecimento de Transporte Eletrônico (CT-e) com os produtos Unico ERP, UniDANFE, DANFE View, UniNF-e (NF-e), Consult-e (NF-e, CT-e), UniCT-e (CT-e), UniNFS-e (NFS-e), acesse e conheça nossos produtos." />
                </Helmet>


                <section className="unimake-container">
                    <Card style={{ height: "auto" }} className="content-area">
                        {this.renderAction()}
                    </Card>
                </section>
            </div>
        );
    }
}
export default LicencaPage;
