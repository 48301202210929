import React from "react";

import { Button, Spin, Typography, Divider, Card, Radio } from "antd";
import CartService from "./../../services/Loja/CartService";
import IResponse from "../../contract/IResponse";
import { changeQuantity } from "../../actions/CartActions";
import ClienteService from "./../../services/ClienteService";
import { Redirect } from "react-router-dom";

import "./UmkCarrinho.scss";
import NotificationUtil from "./../../utils/NotificationUtil";
import CommonUtil from "./../../utils/CommonUtil";
import ModalUltil from "./../../utils/ModalUtil";
import UmkCarrinhoRow from "./UmkCarrinhoRow";
import { SyncOutlined } from "@ant-design/icons";
import ICliente from "./../../contract/cliente/ICliente";
import Modal from "antd/lib/modal/Modal";
import Link from "antd/lib/typography/Link";
import PedidoService from "./../../services/Loja/PedidoService";
import {ICarrinhoResponse} from "../../contract/loja/carrinho/ICarrinhoResponse";
import CookieUtil from "../../utils/CookieUtil";

const { Title, Paragraph, Text } = Typography;

class UmkCarrinho extends React.Component<any, any> {
	private NotificationService: NotificationUtil;
	private ModalUltil: ModalUltil;
	private CartService: CartService;
	private ClienteService: ClienteService;
	private PedidoService: PedidoService;
	private Common: CommonUtil;
	private CookieUtil: CookieUtil;

	state = {
		loading: false,
		DataSource: [],
		total: 0,
		redirect: false,
		redirectLink: "",
		modalFinalizar: false,
		cliente: undefined,
		emailsFat: [],
		emailSelecionado: undefined,
	};

	constructor(props: any) {
		super(props);

		this.ModalUltil = new ModalUltil(props);
		this.CartService = new CartService();
		this.ClienteService = new ClienteService();
		this.PedidoService = new PedidoService();
		this.NotificationService = new NotificationUtil();
		this.Common = new CommonUtil();
		this.CookieUtil = new CookieUtil();

		this.LoadCart = this.LoadCart.bind(this);
		this.handleFinish = this.handleFinish.bind(this);
		this.handleLoading = this.handleLoading.bind(this);
		this.handleUnLoading = this.handleUnLoading.bind(this);
		this.cancelar = this.cancelar.bind(this);
		this.finalizar = this.finalizar.bind(this);
		this.setEmailsFat = this.setEmailsFat.bind(this);
		this.changeEmailFaturamento = this.changeEmailFaturamento.bind(this);
	}

	componentDidMount() {
		this.LoadCart();
	}

	handleLoading() {
		this.setState({
			loading: true,
		});
	}

	handleUnLoading() {
		this.setState({
			loading: false,
		});
	}

	/**
	 * Carrega o carrinho
	 */
	async LoadCart() {
		try {
			let orderId = localStorage.getItem("OrderID");
			let token = await this.CookieUtil.Get("TokenUsuario");

			if (orderId) {
				this.setState({ loading: true });

				let result = token ? await this.CartService.GetCart(true) : await this.CartService.GetCart(false);
				let response: IResponse = result.data;

				if (response.success) {
					let carrinho: ICarrinhoResponse = response.data;

					this.setState({
						DataSource: carrinho.itens,
						total: carrinho.total
					});

					changeQuantity(carrinho.quantidade);
				} else {
					this.ModalUltil.Error(response.message);
				}

				this.setState({ loading: false });
			}
		} catch (error) {
			this.setState({ loading: false });
			this.ModalUltil.Exception(error);
		}
	}

	async handleFinish() {
		try {
			let orderId = localStorage.getItem("OrderID");

			if (!orderId) throw "Você não tem nenhuma solução no carrinho de compra.";

			let cliente: ICliente = this.ClienteService.GetClienteLogado();

			if (!cliente) {
				this.ModalUltil.Error("É Necessário estar logado para finalizar o pedido");
				this.setState({
					redirect: true,
					redirectLink: `${process.env.PUBLIC_URL}/cliente/login`,
				});
				return;
			}

			let validateResult = this.Common.ValidateCliente(cliente);

			if (validateResult.length > 0) {
				this.ModalUltil.Error(
					"Houveram erros de validação dos seus dados. Atualize seus dados cadastrais: \n \n" + validateResult
				);
				this.setState({
					redirect: true,
					redirectLink: `${process.env.PUBLIC_URL}/cliente/perfil`,
				});
				return;
			}

			this.setState({ modalFinalizar: true, cliente: cliente });
			this.setEmailsFat(cliente);
		} catch (error) {
			this.ModalUltil.Exception(error);
		}
	}

	setEmailsFat(cliente: ICliente) {
		let emails = [];

		if (!emails.includes(cliente.email) && cliente.email) {
			emails.push(cliente.email);
		}

		if (!emails.includes(cliente.email2) && cliente.email2) {
			emails.push(cliente.email2);
		}

		if (!emails.includes(cliente.email_fat) && cliente.email_fat) {
			emails.push(cliente.email_fat);
		}

		this.setState({
			emailsFat: emails,
		});
	}

	async finalizar() {
		try {
			if (!this.state.emailSelecionado) {
				throw "Selecione um email para o faturamento!";
			}

			let orderId = localStorage.getItem("OrderID");
			let renovaId = localStorage.getItem("RenovacaoID");

			if (!orderId) throw "Você não tem nenhuma solução no carrinho de compra.";

			this.setState({ loading: true });

			let result = await this.PedidoService.Fechar(
				orderId,
				renovaId,
				this.state.cliente.idcliente,
				this.state.emailSelecionado
			);
			let response: IResponse = result.data;

			if (response.success) {
				this.setState({
					DataSource: [],
					modalFinalizar: false,
				});

				await this.LoadCart();
				changeQuantity(0);
				await this.CookieUtil.Delete("OrderID");
				localStorage.removeItem("OrderID");
				localStorage.removeItem("RenovacaoID");
				localStorage.setItem("orderfinale", orderId);

				this.ModalUltil.SuccessCustomTitle("Pedido finalizado com sucesso! ", "Parabéns você acabou de adquirir uma solução de sucesso!");

				//TODO: Fechar o modal, redirecionar para pagina de pagamentos
				//TODO: Verificar questao da pagina: fechar_compra_editar
				this.setState({
					redirect: true,
					redirectLink: `${process.env.PUBLIC_URL}/loja/fechar`,
				});
			} else {
				this.ModalUltil.Error(response.message);
			}

			this.setState({ loading: false });
		} catch (error) {
			this.setState({ loading: false });
			this.ModalUltil.Exception(error);
		}
	}

	cancelar() {
		this.setState({ modalFinalizar: false });
	}

	changeEmailFaturamento(e: any) {
		this.setState({
			emailSelecionado: e.target.value,
		});
	}

	render() {
		let cliente: ICliente = this.state.cliente;

		if (this.state.redirect) {
			return <Redirect push to={this.state.redirectLink} />;
		}

		return (
			<div className="UmkCartSimple">
				<Modal
					className="modal-faturamento"
					visible={this.state.modalFinalizar}
					title="Dados para faturamento"
					onOk={this.finalizar}
					onCancel={this.cancelar}
					centered
					width="55vw"
					footer={[
						<Button key="back" onClick={this.cancelar}>
							Retornar
						</Button>,
						<Button
							key="submit"
							className="unimake-green-button"
							type="primary"
							loading={this.state.loading}
							onClick={this.finalizar}>
							Finalizar
						</Button>,
					]}>
					<Card title="Dados do cliente" className="card">
						<span>{cliente?.razao_social}</span>
						<p>
							{cliente?.endereco}, {cliente?.numero} <br />
							{cliente?.bairro} <br />
							{cliente?.cep} - {cliente?.cidade} - {cliente?.estado}
						</p>
						<span>
							{this.Common.IsJuridica(cliente?.cnpj) ? "CNPJ" : "CPF"}: {cliente?.cnpj}
						</span>
						<br />
						<Link href={`${process.env.PUBLIC_URL}/cliente/perfil`}> Alterar meus dados cadastrais </Link>
					</Card>

					<Divider />

					<Card title="Para qual e-mail devemos enviar a nota fiscal dessa compra?">
						<Link href={`${process.env.PUBLIC_URL}/cliente/perfil`}>Informar outro e-mail</Link>
						<br />

						{/* onChange={onChange} value={value} */}
						<Radio.Group onChange={this.changeEmailFaturamento} value={this.state.emailSelecionado}>
							{this.state.emailsFat.map((email) => (
								<Radio value={email}>{email}</Radio>
							))}
						</Radio.Group>
					</Card>
				</Modal>

				<Title level={3}>Carrinho de Compras</Title>
				<Divider />

				<Button
					style={{ marginBottom: 10 }}
					onClick={this.LoadCart}
					shape="round"
					type="primary"
					icon={<SyncOutlined />}>
					Atualizar
				</Button>

				<div className="card-body">
					<Spin tip="Aguarde..." spinning={this.state.loading}>
						<div className="table-responsive">
							<table>
								<thead>
									<tr>
										<th className="text-center"></th>
										<th>Produto</th>
										<th className="text-center">Quantidade</th>
										<th>Validade</th>
										<th className="text-center">Preço</th>
										<th className="text-center">Sub Total</th>
										<th className="text-center">Desconto</th>
										<th className="text-center">Total</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{this.state.DataSource.map((item) => (
										<UmkCarrinhoRow
											unsetLoading={this.handleUnLoading}
											setLoading={this.handleLoading}
											updateCart={this.LoadCart}
											Item={item}
										/>
									))}
									<tr>
										<td colSpan={3}></td>
										<td></td>
										<td></td>
										<td className="td-total">Total</td>
										<td className="td-price">{this.Common.FormatBrl(this.state.total)}</td>

										<td colSpan={2} className="text-right">
											<Button
												onClick={this.handleFinish}
												shape="round"
												className="unimake-green-button"
												block>
												Finalizar Compra
											</Button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Spin>
				</div>
			</div>
		);
	}
}
export default UmkCarrinho;
