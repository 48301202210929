import React from "react";
import { Row, Col, Button } from "antd";
import LazyLoad from "react-lazyload";
import { Helmet } from "react-helmet";
import ContatoComponent from "../components/ContatoComponent";

const jsonLdData = {
	'@context': 'http://schema.org',
	'@type': 'Product',
	name: "Unico Auto Peças",
	image: "https://www.unimake.com.br/static/media/unico-auto-pecas.ccc9bd32.webp",
	description: "Controle de seu estoque até seu faturamento com o software integrado de gestão empresarial focado em seu negócio.",
	brand: {
		'@type': 'Brand',
		name: "Unimake",
	},
	"review": {
		"@type": "Review",
		"reviewRating": {
		  "@type": "Rating",
		  "ratingValue": "5"
		},
		"author": {
		  "@type": "Person",
		  "name": "Unimake"
		}
	}
};

class UnicoAutoPage extends React.Component<any, any> {
	state = {
		unicoAutoTopo: require("../../../assets/img/products/webp/topo/unico-auto-pecas.webp"),
	};

	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Conheça o Único ERP – Auto Peças | Unimake Software</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/produtos/unico-auto`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="Confira as principais informações sobre o Único ERP, Auto Peças. Com funcionalidades especiais para o segmento de varejo e atacado de auto peças." />
				</Helmet>

				<div className="topo-border-rounded color-auto-pecas">
					<Row align="middle" justify="space-around">
						<Col
							lg={{ order: 1, span: 10, offset: 2 }}
							xs={{ order: 2, span: 20 }}
							xxl={{ order: 1, span: 7, offset: 5 }}>
							<h1 className="hidden-xs hidden-sm hidden-md">Único ERP</h1>
							<h2 className="hidden-xs hidden-sm hidden-md">Auto-peças</h2>

							<p className="subtitulo">Leve o desempenho da sua empresa para o próximo nível</p>
						</Col>
						<Col lg={{ order: 2, span: 10 }} xs={{ order: 1 }}>
							<img className="topo-produto" src={this.state.unicoAutoTopo} alt="Unimake Unico Auto"></img>
						</Col>
					</Row>
				</div>

				<section>
					<div className="unimake-container">
						<div className="content-area-product">
							<div className="pre-description-center2">
								<p style={{ fontSize: 22, fontWeight: 100 }}>Você precisa investir hoje em um <b>sistema de gestão que integre os dados essenciais ao funcionamento de sua empresa</b>. 
									Para te ajudar nessa escolha, a Unimake Software oferece opções de sistemas para os setores de
									<a href="http://www.unimake.com.br/produtos/unico-fecularia" target="_blank" style={{ color: "blue" }}> fecularia</a>, 
									<a href="http://www.unimake.com.br/produtos/unico-material-construcao" target="_blank" style={{ color: "blue" }}> materiais de construções </a>
									e <a href="http://www.unimake.com.br/produtos/unico-material-eletrico" target="_blank" style={{ color: "blue" }}>materiais elétricos</a>, 
									bem como para o segmento de varejo e atacado de auto peças. Saiba mais sobre o último nessa página:
								</p>

								<h3>Alie a rentabilidade da sua empresa a soluções tecnológicas</h3>
								<p>Otimizar rotinas empresariais faz toda diferença na evolução e rentabilidade do seu negócio, e quando falamos de gerir um varejo e atacado de Auto Peças esse benefício é mais do que bem vindo!</p>
								<p>O Único ERP para Auto Peças é mais do que tão somente um software de gestão de dados, auxiliando também na gestão de capital humano, controle financeiro, vendas e até de marketing, bem como ajuda o gestor a medir, de forma descomplicada, a capacidade produtiva do negócio.</p>

								<h3>ERP e a segurança de dados</h3>
								<p>A Unimake Software se preocupa em fornecer a você, cliente e empresário, as <b> melhores ferramentas tecnológicas do mercado</b>. Mas o faz de forma
									segura e inteligente, por meio de sistemas ERP e <a	href="http://www.unimake.com.br/gemini/" target="_blank" style={{ color: "blue" }}>serviços de
									backup de dados na nuvem</a>, seguindo sempre todas as recomendações legais, fiscais e de Proteção Geral de Dados.
								</p>
								
								<p>Mas você pode estar se perguntando o que um ERP (Enterprise Resource Planning) ou sistema de gestão tem a ver com a<b> segurança organizacional de dados internos e externos</b>, e nós te respondemos: tudo!
								</p>
									
								<p>A inteligência artificial e o machine learning são capazes de codificar informações e torná-las acessíveis apenas a quem possui acesso autorizado (e que normalmente 
									gerencia o negócio), o que, por sua vez, passa confiança ao seu cliente por conta da precaução tomada.</p><p>Um crescimento exponencial e sólido de um negócio depende do
									bom gerenciamento de informações e do bom relacionamento e experiência do usuário. É o que você procura, não é mesmo?
								</p>
							</div>

							<br />

							<div className="featuresBox">
								<Row>
									<Col sm={24}>
										<h3 style={{ fontSize: 32, fontWeight: 400 }}>Funcionalidades do Único ERP - Programa de Gestão para Auto Peças</h3>
										<p style={{ fontSize: 20, fontWeight: 100 }}>Esssas são algumas das funções do Único ERP para Auto Peças da Unimake Software</p>
									</Col>
								</Row>

								<ul>
									<li><b>Cadastro de códigos alternativos </b>: possibilita vincular vários códigos de fabricantes e fornecedores a um mesmo produto, facilitando sua localização;</li>
									<li><b>Simulação do preço de venda</b>(Alt+7): o vendedor pode simular em tempo real a lucratividade dos itens do pedido e verificar qual faixa de preço pode praticar;</li>
									<li><b>Venda rápida</b>: o vendedor lança os itens de acordo com as requisições do cliente e informa as condições de pagamento no fechamento;</li>
									<li><b>Controle de comissões</b>:
										<ul>
											<li><b>a)</b> Por vendedor (parametriza as condições de comissão por funcionário);</li>
											<li><b>b)</b> Por produto (parametriza condições especiais para um determinado produto);</li>
											<li><b>c)</b> Por faixa de descontos (parametriza comissão de acordo com o desconto concedido);</li>
										</ul>
									</li>
									<li><b>Manuseio de produtos por código de barras</b>: integra o software a leitor de código de barras (fixo ou pistola) que lê os códigos de barras do produto no ato de recepção e saída de produtos;</li>
									<li><b>Endereçamento de produtos no estoque</b>: ao fazer a consulta de um produto no software o usuário é informado do corredor e prateleira onde o produto se encontra dentro do depósito;</li>
									<li><b>Controle de estoque mínimo/máximo</b>:
										<ul>
											<li><b>a)</b> Controla o estoque mínimo de um produto para pedido enviando aviso (CI) para o gestor de compras e;</li>
											<li><b>b)</b> O sistema avisa em caso de excesso de estoque de um determinado produto;</li>
										</ul>
									</li>
									<li><b>Análise de crédito e bloqueio automático</b>: o software faz análise constante do histórico de crédito dos clientes liberando ou bloqueando crédito conforme parâmetros determinados pelo financeiro;</li>
									<li><b>Controle de cheques pré-datados</b>: controla origem e destino dos cheques recebidos permitindo vincular cheques de terceiros ao cliente pagador e prazos de depósito;</li>
									<li><b>Controle de acesso de usuário</b>: permite parametrizar as permissões que cada usuário terá dentro do software;</li>
									<li><b>Controle de acesso de grupos usuários</b>: permite parametrizar as permissões gerais para um grupo de usuários dentro do software;</li>
									<li><b>Controle total de caixa e contas-corrente</b>: geração de ficha diária para conferência de caixa, conciliação de contas bancária e controle de adiantamentos e pagamento a colaboradores;</li>
									<li><b>Emissão automática de boletos bancários</b>;</li>
									<li><b>Baixa automáticas dos boletos gerados e recebidos pelo banco</b>: integração entre o software e bancos via troca de arquivos digitais de remessa e retorno;</li>
									<li><b>Agrupamento de documentos</b>: agrupa várias vendas num só documento onde podem ser definidas as condições de pagamento com o cliente;</li>
									<li><b>Controle de venda/entrega futura</b>: permite fechar uma venda com vários itens e liberá-los em várias entregas controlando o saldo disponível;</li>
									<li><b>Relatórios de análise e lucratividade</b>: o Único ERP para Auto Peças conta com vários relatórios que permitem uma ampla análise das operações tais como: estoques, contas a pagar e receber, lucratividade, comissões, extrato de movimentação por cliente e/ou vendedor, curva ABC, entre outros.</li>
								</ul>
								<p>Fica muito mais fácil reduzir custos, diminuir prejuízos e fazer o acompanhamento de perto da sua rotina empresarial com o
									<a href="https://inside.unimake.com.br/unicoerp-auto-pecas" target="_blank" style={{ color: "blue" }}> Único ERP para o segmento de Auto Peças</a>.  Cuide do seu negócio com a nossa ajuda!
								</p>


							</div>

							<br />

							<div className="imageMiddle-unauto">
								<LazyLoad>
									<img
										style={{
											maxWidth: "70%",
											marginRight: "auto",
											marginLeft: "auto",
											display: "block",
										}}
										src={require("../../../assets/img/products/auto/notebook-danfe.png")}
										alt="Unimake Unico Auto"
									/>
								</LazyLoad>
							</div>

							<br />
						</div>
					</div>
				</section>

				<div className="autoPecasBanner">
					<div className="autoPecasBanner-container">
						<Row align="middle">
							<Col xs={24} sm={24} md={{ span: 10, offset: 2 }}>
								<LazyLoad>
									<img
										className="imageLeft-fec"
										src={require("../../../assets/img/products/auto/middle.png")}
										alt="Unimake Unico Auto"
									/>
								</LazyLoad>
							</Col>

							<Col xs={24} sm={24} md={10} lg={10}>
								<p className="imageLeft-Text-fec" style={{ color: "#238883" }}>
									Exerça a gestão total da sua autopeças com o Único ERP.
								</p>
								<p className="imageLeft-Text-fec" style={{ color: "#238883" }}>
									Sua equipe, seus clientes e seus lucros vão agradecer.
								</p>
							</Col>
							<Col className="hidden-sm hidden-xs" md={2} lg={2}></Col>
						</Row>
					</div>
				</div>

				{/* <div>
					<Row>
						<Col sm={24} md={24}>
							<h1 style={{ fontSize: 32, fontWeight: 300, textAlign: "center" }}>
								Alguns casos de sucesso
							</h1>

							<div style={{ height: 200 }}>
								<UmkSliderContainer />
							</div>
						</Col>
					</Row>
				</div> */}

				<ContatoComponent />

				<script type="application/ld+json">
					{JSON.stringify(jsonLdData, null, 2)}
				</script>
			</>
		);
	}
}

export default UnicoAutoPage;