export default class StorageService {   
    /**
     * Retorna um objeto pela chave
     * @param key Chave do obeto
     */
    public Get(key: string): any {
        let result = localStorage.getItem(key);

        return result;
    }

    /**
     * Salva um valor no storage
     * @param key Chave do objeto
     * @param valor Valor do objeto
     */
    public Set(key: string, valor: any): void {
        localStorage.setItem(key, valor);
    }

    /**
     * Limpa o storage
     */
    public Clear(): void {
      localStorage.clear();
    }
}