import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faLinkedin, faFacebook, faWordpressSimple } from "@fortawesome/free-brands-svg-icons";
import React from 'react';
import { flipInX } from 'react-animations';
import Radium from 'radium';

const styles = {
    flip: {
        animation: 'x 1s',
        animationName: Radium.keyframes(flipInX, 'bounce')
    }
}

class UmkSocialBar extends React.Component<any, any> {
    render() {
        return (
            <div className="social-bar">
                <a style={styles.flip} href="https://twitter.com/Unimake" target="_blank"><FontAwesomeIcon color="white" style={{ fontSize: 24 }} icon={faTwitter} /></a>
                <a style={styles.flip} href="https://www.youtube.com/channel/UCOSPmRhLgIFJU-pAy8Sogdw" target="_blank"><FontAwesomeIcon color="white" style={{ fontSize: 24 }} icon={faYoutube} /></a>
                <a style={styles.flip} href="https://www.linkedin.com/company/unimake-software/" target="_blank"><FontAwesomeIcon color="white" style={{ fontSize: 24 }} icon={faLinkedin} /></a>
                <a style={styles.flip} href="https://www.facebook.com/unimake/" target="_blank"><FontAwesomeIcon color="white" style={{ fontSize: 24 }} icon={faFacebook} /></a>
                <a style={styles.flip} href="https://blog.unimake.com.br/" target="_blank"><FontAwesomeIcon color="white" style={{ fontSize: 24 }} icon={faWordpressSimple} /></a>
            </div>
        );
    }
}
export default UmkSocialBar;