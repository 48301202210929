import React from "react";
import "./Sobre.scss";

class Sobre extends React.Component<any, any> {
    render() {
        return (
            <div>
                <div id="Sobre" className="sobre-container">
                    <div className="descricao-container">
                        <div className="descricao">
                            <div className="sobre-title">
                                <h1>
                                    Desenvolver Softwares é Mais Que Um Trabalho,
                                    <span>
                                        <strong> É Uma Missão!</strong>
                                    </span>
                                </h1>
                            </div>
                            <br />

                            <div className="sobre-desc">
                                <p>
                                    Nós não acreditamos que desenvolver software seja apenas sobre escrever linhas de código.
                                    É sobre fornecer soluções que transformam negócios e impactam a vida das pessoas.
                                    Seja no agronegócio, no varejo ou na gestão de documentos fiscais,
                                    nossos produtos são projetados para simplificar processos e otimizar resultados.
                                </p>
                                <p style={{ fontSize: 22, color: "#04234C", textAlign: "center" }}><strong> Valores Que Conduzem Inovação </strong></p>
                                <p>
                                    Nossos valores estão enraizados em fornecer não apenas produtos, mas soluções que fazem a diferença.
                                    É por isso que nos dedicamos a criar ferramentas que liberam os desenvolvedores das tarefas repetitivas e cansativas,
                                    permitindo que você se concentre em inovar e crescer.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="image-container">
                        <div className="sobre-image">
                            <img
                                src={require("../../assets/img/sobre/webp/uniao.webp")}
                                alt="logo unimake"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sobre;