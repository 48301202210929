import dispatcher from '../dispatcher/Dispatcher';
import { EventEmitter } from 'events';
import * as CartActions from '../actions/CartActions';

class Store extends EventEmitter {
    cartQuantity;
    clientName;

    constructor() {
        super();
        this.cartQuantity = 0;
        this.clientName = 'Visitante';
    }

    handleActions(action) {           
        switch (action.type) {
            case CartActions.CART_ACTIONS.CHANGE_QUANTITY: 
                this.cartQuantity = action.value;
                this.emit("cartUpdated")
            break;

            case CartActions.CART_ACTIONS.UPDATE_QUANTITY: 
                this.cartQuantity += action.value;
                this.emit("cartUpdated")
            break

            case CartActions.CART_ACTIONS.UPDATE_NAME:                
                this.clientName = action.value;
                this.emit("clientNameUpdated")
            break
        }
    }

    getCartQuantity() {
        return this.cartQuantity;
    }

    getClientName() {        
        return this.clientName;
    }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;