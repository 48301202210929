import React from 'react';
import { Result, Button } from 'antd';
import {Helmet} from "react-helmet";

class NotFoundPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Unimake Software - Não Encontrado</title>
                    <meta name='errorpage' content='true' />
                    <meta name='errortype' content='404 - Not Found' />
                    <meta name='prerender-status-code' content='404' />
                </Helmet>

                <section className="unimake-area-v3">
                    <div className="unimake-container">
                        <Result
                            status="404"
                            title="404"
                            subTitle="Desculpe, a página que está tentando acessar não existe."
                            extra={<Button href={`${process.env.PUBLIC_URL}/`} type="primary">Voltar ao ínicio</Button>}
                        />
                    </div>
                </section>
            </div>
        );
    }
}
export default NotFoundPage;
