import { Button, Col, Row } from "antd";
import React from "react";

class ContatoComponent extends React.Component<any, any> {
	state = {
		allWidth: true,
	};

	constructor(props: any) {
		super(props);

		if (typeof props.allWidth !== "undefined" && props.allWidth !== null) {
			this.setState({
				allWidth: props.allWidth
			});
		}
	}

	renderButton() {
		//return this.props.link != undefined ? <a href={this.props.link} target="_blank" rel="noopener noreferrer">Contato</a> : <Link to="/contato">Contato</Link>
	}

	render() {
		let divContato;
		if (this.state.allWidth) {
			divContato = (
				<>
					<br />
					<Row align="middle" justify="space-around">
						<Col xs={22} sm={11} md={12} lg={12}>
							<span style={{ fontSize: 28 }}>Dúvidas? Entre em contato: </span>
						</Col>

						<Col xs={22} sm={11} md={8} lg={6}>
							<Button shape="round" size="large" type="primary" block target="_blank" href="https://unimake.movidesk.com/kb/pt-br">
								Contato
							</Button>
						</Col>
					</Row>
					<br />
				</>
			);
		} else {
			divContato = (
				<>
					<br />
					<Row align="middle" justify="space-around">
						<Col xs={22} sm={22} md={12} lg={15}>
							<span style={{ fontSize: 28 }}>Dúvidas? Entre em contato: </span>
						</Col>

						<Col xs={22} sm={22} md={10} lg={7}>
							<Button shape="round" size="large" type="primary" block target="_blank" href="https://unimake.movidesk.com/kb/pt-br">
								Contato
							</Button>
						</Col>
					</Row>
					<br />
				</>
			);
		}

		return <div>{divContato}</div>;
	}
}

export default ContatoComponent;
