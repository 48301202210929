import React from "react";
import { Form, Input, Button, Checkbox, Spin } from "antd";
import ClienteService from "../../../services/ClienteService";
import IResponse from "../../../contract/IResponse";
import IClienteLogin from "./../../../contract/cliente/IClienteLogin";
import MaskedInput from "antd-mask-input";

import { Redirect, Link } from "react-router-dom";
import { updateNameClient } from "./../../../actions/CartActions";
import NotificationUtil from "./../../../utils/NotificationUtil";
import StorageService from "./../../../services/StorageService";
import CookieUtil from "./../../../utils/CookieUtil";
import CommonUtil from "./../../../utils/CommonUtil";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import ModalUltil from "./../../../utils/ModalUtil";

import { cpf, cnpj } from "cpf-cnpj-validator";
import {FormInstance} from "antd/lib/form";

class UmkClienteLogin extends React.Component<any, any> {
	formRef = React.createRef<FormInstance>();

	state = {
		loading: false,
		redirect: false,
		redirectPage: "",
	};

	private Common: CommonUtil;
	private NotificationService: NotificationUtil;
	private ModalUltil: ModalUltil;
	private ClienteService: ClienteService;
	private storage: StorageService;
	private CookieService: CookieUtil;

	constructor(props: any) {
		super(props);

		this.Common = new CommonUtil();
		this.ClienteService = new ClienteService();
		this.NotificationService = new NotificationUtil();
		this.ModalUltil = new ModalUltil(props);
		this.storage = new StorageService();
		this.CookieService = new CookieUtil();

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.initialize();
	}

	initialize(): void {
		this.getParameters();
	}

	getParameters(): void {
		try {
			let url_string = window.location.href;
			let url = new URL(url_string);

			let cnpj = url.searchParams.get("__cnpj");
			let password = url.searchParams.get("__password");

			if (cnpj) {
				this.formRef.current.setFieldsValue({
					cnpj: cnpj
				});
			}

			if (password) {
				this.formRef.current.setFieldsValue({
					senha: password
				})
			}
		} catch (error) {
			this.ModalUltil.Error(error.message);
		}
	}

	async handleSubmit(values: IClienteLogin) {
		try {
			if (values.cnpj.length > 11) values.cnpj = this.Common.FormatCNPJ(values.cnpj);
			else values.cnpj = this.Common.FormatCPF(values.cnpj);

			let body = {
				cnpj: values.cnpj,
				senha: values.senha,
			};

			this.setState({ loading: true });

			let result = await this.ClienteService.Authenticate(body);

			this.setState({ loading: false });

			let response: IResponse = result.data;

			if (response.success) {
				this.storage.Set("Usuario", JSON.stringify(response.data.usuario));
				this.storage.Set("TokenUsuario", response.data.token);

				updateNameClient(response.data.usuario.nome_fantasia);

				this.CookieService.Set("TokenUsuario", response.data.token);

				if (values.Lembrar) {
					this.CookieService.Set("UsuarioLembrar", response.data.token);
				}

				this.NotificationService.Success(
					(response.message = response.message ? response.message : "Acesso permitido!")
				);

				let redirectExternal = localStorage.getItem("redirect");

				if (redirectExternal === "true") {
					let path = localStorage.getItem("redirectPath");

					localStorage.removeItem("redirect");
					localStorage.removeItem("redirectPath");

					this.setState({
						redirect: true,
						redirectPage: path,
					});
				} else {
					this.setState({
						redirect: true,
						redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`,
					});
				}
			} else {
				this.ModalUltil.Exception(response.message);
			}
		} catch (error) {
			this.setState({ loading: false });
			this.ModalUltil.Exception(error);
		}
	}

	render() {
		if (this.state.redirect) {
			return <Redirect push to={this.state.redirectPage} />;
		}

		return (
			<div>
				<div>
					<Spin tip="Aguarde..." spinning={this.state.loading}>
						<Form ref={this.formRef} name="control-ref" onFinish={this.handleSubmit} className="login-form">
							<Form.Item
								className="form-item-umk"
								name="cnpj"
								rules={[
									{ required: true, message: "Informe o CPF/CNPJ!" },
									({ getFieldValue }) => ({
										validator(_, value) {
											if (value === "") {
												return Promise.resolve();
											}

											let cpfValido: boolean = cpf.isValid(value);
											let cnpjValido: boolean = cnpj.isValid(value);

											if (cpfValido || cnpjValido) {
												return Promise.resolve();
											} else {
												return Promise.reject(new Error("Informe um CPF/CNPJ válido"));
											}
										},
									}),
								]}>
								<Input prefix={<UserOutlined />} type="text" placeholder="CPF/CNPJ" />
							</Form.Item>

							<Form.Item
								className="form-item-umk"
								name="senha"
								rules={[
									{ required: true, message: "Informe a Senha!" },
									{ max: 50, message: "Informe a senha com até 50 caracteres!" },
								]}>
								<Input prefix={<LockOutlined />} type="password" placeholder="Senha" />
							</Form.Item>

							<Form.Item>
								<Checkbox name="Lembrar">Lembrar</Checkbox>
							</Form.Item>

							<Form.Item>
								<Button
									block
									type="primary"
									shape="round"
									htmlType="submit"
									className="login-form-button">
									Entrar
								</Button>
							</Form.Item>
							<Form.Item>
								<Link to={`${process.env.PUBLIC_URL}/cliente/esqueci-minha-senha`}>
									<span className="login-form-forgot">Esqueci minha senha</span>
								</Link>
							</Form.Item>
						</Form>
					</Spin>
				</div>
			</div>
		);
	}
}



export default UmkClienteLogin;
